type StyleMixinConstant = Readonly<Record<string, string>>

export const COLOR_DICT: StyleMixinConstant = {
  danger: '#dc3545',
  danger_low: '#dc354566',
  primary: '#2C5263',
  primary_low: '#2C526663',
  secondary: '#267C6E',
  secondary_low: '#35ABB066',
  warning: '#ffc107',
  warning_low: '#ffc10766',
  success: '#28a745',
  success_low: '#28a74566',
  disabled: '#aaaaaa',
  disabled_low: '#aaaaaa66',
  gray: '#656565',
  gray_low: '#65656500',
  light: 'white',
}

export const ICON_DICT: StyleMixinConstant = {
  todo_item: 'tasks',
  cockpit: 'home-alt',
  contribution: 'comment-alt-plus',
  evaluation: 'check-square',
  action: 'stream',
  action_build: 'traffic-cone',
  action_upgrade: 'chevron-double-up',
  action_task: 'tasks',
  runtask: 'clipboard-check',
  gap: 'exclamation-square',
  exemption: 'unlock',
  incident: 'fire-alt',
  project: 'hard-hat',
  project_test: 'check-square',
  perimeter: 'sitemap',
  single_perimeter: 'cube',
  application: 'angular',
  provider: 'people-arrows',
  grouping: 'cubes',
  policy: 'books',
  control: 'table',
  risk: 'radiation-alt',
  dashboard: 'tachometer-fast',
  user: 'user',
  group: 'users',
  measure: 'shield-check',
  solution: 'lightbulb',
  indicator: 'chart-line',
  metric: 'chart-scatter',
  keyword: 'text',
  synonym: 'align-justify',
  catalog: 'medkit',
  conf_auth: 'fingerprint',
  tree_viz: 'chart-network',
  log: 'shoe-prints',
  licence: 'badge-check',
}
