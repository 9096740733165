import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import * as Sentry from '@sentry/vue'
import { sentry_init_param } from './plugins/sentry'
import store from './store'
import Axios from 'axios'
import VueLogger from 'vuejs-logger'
import GetTextPlugin from 'vue-gettext'
import translations from './locales/fr_FR/translations.json'
import VeeValidate, { Validator } from 'vee-validate'
import FlagIcon from 'vue-flag-icon'
import PrettyCheckbox from 'pretty-checkbox-vue'
import vSelect from 'vue-select'
import BootstrapVue from 'bootstrap-vue'
import { config } from './config.settings'
import 'bootstrap'
import LiquorTree from 'liquor-tree'
import { fields_en, fields_fr } from './plugins/fields_tr'
import domtoimage from 'dom-to-image-more'
import jsZip from 'jszip'
import FileSaver from 'file-saver'
import VueToastify from 'vue-toastify'
import Nl2br from 'vue-nl2br'
import VueWaypoint from 'vue-waypoint'
import './plugins/filters'
import './plugins/validators'
import './plugins/text_utils'
import VueClipboard from 'vue-clipboard2'
import 'overlayscrollbars/css/OverlayScrollbars.css'
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue'
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import apiMethod from './api_call'
import { isArray, mergeWith } from 'lodash'
import VueMoment from 'vue-moment'
import moment from 'moment'
import { datetimePicker } from '../vendors/bootstrap-datetimepicker/bootstrap-datetimepicker'
import 'moment/dist/locale/fr'
import jQuery from 'jquery'
import './scss/new-design-system/index.scss'
import { FALLBACK_LOCALE } from '@/constants/language'
import { getNavigatorLocale, localeToLang } from '@/helpers/Language.helpers'
import posthog from 'posthog-js'
import { i18n, setI18nLocale } from '@/I18n.ts'

/*global OverlayScrollbars*/

Vue.prototype.$posthog = posthog

if (config.enable_survicate && config.survicate_key && config.survicate_key.length > 0) {
  const s = document.createElement('script')
  s.src = `https://survey.survicate.com/workspaces/${config.survicate_key}/web_surveys.js`
  s.async = true
  const e = document.getElementsByTagName('script')[0]
  e.parentNode.insertBefore(s, e)
}

if (config.enable_matomo) {
  window._mtm = window._mtm || []
  window._mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' })
  const s = window.document.createElement('script')
  s.src = config.matomo_host + '/js/container_' + config.matomo_container_id + '.js'
  s.async = true
  s.type = 'text/javascript'
  window.document.getElementsByTagName('head')[0].appendChild(s)
}

Vue.prototype.$language = {
  current: getNavigatorLocale().replace('-', '_'),
}

Vue.prototype.getCurrentLang = () => localeToLang(Vue.prototype.$language.current)

Vue.prototype._ = function (text) {
  // TODO: delete or refact when introducing i18n
  return Vue.prototype.$gettext(text, Vue.prototype.$language.current)
}

setI18nLocale(Vue.prototype.$language.current)

Vue.prototype.user_initial = function (name) {
  if (name) {
    const split = name.split(' ')
    if (split.length > 1) {
      return split[0][0] + split[1][0]
    } else if (split.length > 0) {
      return split[0][0]
    } else {
      return '?'
    }
  } else {
    return '?'
  }
}

const copyNestedObjects = function (source, fields) {
  let target
  if (source === null) {
    return null
  } else if (Array.isArray(source)) {
    target = Array.from(source)
    for (let i = 0; i < target.length; i++) {
      target[i] = copyNestedObjects(target[i])
    }
  } else if (typeof source === 'object') {
    target = Object.assign({}, source)
    Object.keys(source).forEach((e) => {
      if (fields) {
        if (!fields.includes(e)) {
          delete target[e]
        } else {
          target[e] = copyNestedObjects(target[e])
        }
      } else {
        target[e] = copyNestedObjects(target[e])
      }
    })
  } else {
    return source
  }
  return target
}

window.$ = jQuery
window.jQuery = jQuery

window.moment = moment
Vue.use(VueMoment, { moment })
datetimePicker(jQuery, moment)

Vue.use(VueWaypoint)

Vue.use(LiquorTree)
Vue.use(BootstrapVue)

Vue.component('nl2br', Nl2br)
Vue.component('v-select', vSelect)
Vue.use(PrettyCheckbox)
Vue.use(OverlayScrollbarsPlugin)

// Your custom options
Vue.prototype.$http = Axios
Vue.prototype.$httpController = new AbortController()
Vue.prototype.$http.defaults.withCredentials = true
Vue.prototype.aciso_config = config
Vue.config.productionTip = false

Vue.use(FlagIcon)
Vue.use(VueLogger, {
  logLevel: config.log_level ? config.log_level : 'error',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
})

Vue.prototype.$domToImg = domtoimage
Vue.prototype.$jszip = jsZip
Vue.prototype.$FileSaver = FileSaver

Vue.use(GetTextPlugin, {
  availableLanguages: {
    en_US: 'English',
    fr_FR: 'Français',
  },
  defaultLanguage: FALLBACK_LOCALE,
  languageVmMixin: {
    computed: {
      currentKebabCase: function () {
        return this.current.toLowerCase().replace('_', '-')
      },
    },
  },
  translations: translations,
  silent: true,
})

Vue.use(VeeValidate, {
  classes: true,
  events: 'blur',
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid',
  },
  dictionary: {
    fr: {
      attributes: fields_fr,
    },
    en: {
      attributes: fields_en,
    },
  },
})

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err, vm, info)
}
if (config.enable_sentry) {
  Sentry.init(sentry_init_param)
}

Vue.prototype.copyNestedObjects = function (obj, fields) {
  return copyNestedObjects(obj, fields)
}

Vue.prototype.isUntranslated = function (str) {
  if (str && str.includes(' ')) {
    return 'untranslated-en'
  } else if (str && str.includes(' ')) {
    return 'untranslated-fr'
  }
  return ''
}

Vue.prototype.isUntranslatedIt = function (str) {
  if (str && str.includes(' ')) {
    return 'font-italic'
  } else if (str && str.includes(' ')) {
    return 'font-italic'
  }
  return ''
}

Vue.prototype.confirm = function (
  message,
  ok = null,
  ko = null,
  force = false,
  additional_delete = null,
  okv = 'outline-primary',
  title,
  hide_header = true,
  header_class = null
) {
  const self = this
  if (typeof message == 'undefined') {
    message = this._('Are you sure?')
  }

  if (force) {
    return new Promise((resolve) => resolve())
  }
  if (ok === null) {
    ok = this._('Yes')
  }
  if (ko === null) {
    ko = this._('No')
  }
  if (title === null) {
    title = ''
  }
  if (!additional_delete) {
    return new Promise((resolve, reject) => {
      this.$bvModal
        .msgBoxConfirm(message, {
          noCloseOnEsc: true,
          noCloseOnBackdrop: true,
          okTitle: ok,
          cancelTitle: ko,
          cancelVariant: 'light',
          okVariant: okv,
          title: title,
          hideHeaderClose: hide_header,
          headerClass: header_class,
        })
        .then((ok) => {
          if (ok) {
            resolve()
          } else {
            reject(ok)
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  } else {
    return new Promise((resolve, reject) => {
      const h = this.$createElement
      const titleVNode = h('div', { domProps: { innerHTML: message } })
      const content = []
      content.push(h('p', { class: ['text-center'] }, [self._('Delete associated objects ?')]))

      additional_delete.forEach((opt) => {
        const check = h(
          'b-form-checkbox',
          {
            props: {
              value: opt,
              'unchecked-value': 'false',
            },
            domProps: {
              value: self.value,
            },
            on: {
              change: function (event) {
                self.$set(self.additional_delete_values, 'obj_parent_deleted', null)
                self.$set(self.additional_delete_values, 'to_del', {})
                self.$set(self.additional_delete_values.to_del, opt, { objs: [], delete: event })
                self.$emit('input:adds_del', event)
              },
            },
          },
          [opt]
        )
        content.push(check)
      })

      const messageVNode = h('div', { class: ['foobar'] }, content)
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: [titleVNode],
          noCloseOnEsc: true,
          noCloseOnBackdrop: true,
          okTitle: ok,
          cancelTitle: ko,
          cancelVariant: 'light',
          okVariant: 'outline-primary',
          hideHeaderClose: hide_header,
        })
        .then((ok) => {
          if (ok) {
            resolve()
          } else {
            reject()
          }
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}

Vue.prototype.isEllipsisActive = function (elref, txt) {
  let element = this.$refs[elref]
  if (element) {
    if (Array.isArray(element)) {
      element = element[0]
    }
    if (element && element.offsetWidth < element.scrollWidth) {
      if (txt) {
        return txt
      }
      return element.textContent
    }
  }
}

Vue.prototype.msg_confirm = function (message, ok = null, force = false) {
  if (typeof message == 'undefined') {
    return
  }
  if (force) {
    return new Promise((resolve) => resolve())
  }
  if (ok === null) {
    ok = this._('Yes')
  }
  return new Promise((resolve, reject) => {
    this.$bvModal
      .msgBoxOk(message, {
        noCloseOnEsc: true,
        noCloseOnBackdrop: true,
        okTitle: ok,
        cancelVariant: 'light',
        okVariant: 'outline-primary',
      })
      .then((ok) => {
        if (ok) {
          resolve()
        } else {
          reject()
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

Vue.prototype.textColorFromBg = function (color) {
  let txt_color = '#ffffff'
  let lightness
  if (color) {
    const hex = color.substring(1, 7)
    const coefs = [0.21, 0.72, 0.07]
    let l = 0
    for (let i = 0; i < 3; i++) {
      const from = i * 2
      const c = parseInt(hex.substring(from, from + 2), 16) * coefs[i]
      l += c
    }
    lightness = l / 255
    if (lightness > 0.5) {
      txt_color = '#000'
    }
    return txt_color
  }
}

Vue.prototype.addAlpha = function (color, opacity = 0.5) {
  // coerce values so ti is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}

Vue.prototype.rgb2Hex = function (rgb_str) {
  let a = rgb_str.split('(')[1].split(')')[0]
  a = a.split(',')
  const b = a.map(function (x) {
    //For each array element
    x = parseInt(x).toString(16) //Convert to a base16 string
    return x.length == 1 ? '0' + x : x //Add zero if we get only one character
  })
  return '#' + b.join('')
}

Vue.prototype.closestToZero = function (numbers) {
  if (!numbers.length) {
    return 0
  }
  let closest = 0
  for (let i = 0; i < numbers.length; i++) {
    if (closest === 0) {
      closest = numbers[i]
    } else if (numbers[i] > 0 && numbers[i] <= Math.abs(closest)) {
      closest = numbers[i]
    } else if (numbers[i] < 0 && -numbers[i] < Math.abs(closest)) {
      closest = numbers[i]
    }
  }
  return closest
}

Vue.prototype.date_format = function (date) {
  return this.$moment.utc(date).local().format('L LT')
}

Vue.prototype.datetime_format = function (date) {
  if (date) {
    return this.$moment.utc(date, 'DD/MM/YYYY HH:mm:ss').local().format('L LT')
  } else {
    return '-'
  }
}

Vue.prototype.datetime_format_utc = function (date) {
  if (date) {
    return this.$moment.utc(date, 'DD/MM/YYYY HH:mm:ss').format('L LT')
  } else {
    return '-'
  }
}

Vue.prototype.datetime_format_no_hour = function (date) {
  if (date) {
    return this.$moment.utc(date, 'DD/MM/YYYY').local().format('L')
  } else {
    return '-'
  }
}

Vue.use(VueToastify, {
  theme: 'light',
  position: 'bottom-center',
  duration: 5000,
  errorDuration: 6000,
  successDuration: 2000,
  warningInfoDuration: 3000,
  customNotifications: {
    neutral: {
      type: 'neutral',
    },
  },
})

Vue.use(VueClipboard)

const dict = {
  custom: {
    link: {
      url: Vue.prototype._('Invalid URL'),
    },
  },
}
Validator.localize('en', dict)

Vue.prototype.$toast = {
  alert: (msg) => {
    Vue.prototype.$vToastify.error(msg, Vue.prototype._('Error') || 'Error')
  },
  warning: (msg) => {
    Vue.prototype.$vToastify.warning(msg, Vue.prototype._('Warning') || 'Warning')
  },
  warn: (msg) => {
    Vue.prototype.$vToastify.warning(msg, Vue.prototype._('Warning') || 'Warning')
  },
  info: (msg) => {
    Vue.prototype.$vToastify.info(msg, Vue.prototype._('Info') || 'Info')
  },
  success: (msg) => {
    Vue.prototype.$vToastify.success(msg, Vue.prototype._('Success') || 'Success')
  },
}

Vue.directive('click-outside', {
  bind: function (el, binding) {
    // Provided expression must evaluate to a function.
    if (typeof binding.value !== 'function') {
      //
    }
    // Define Handler and cache it on the element
    const bubble = binding.modifiers.bubble
    const handler = (e) => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = handler

    // add Event Listeners
    document.addEventListener('click', handler)
  },

  unbind: function (el) {
    // Remove Event Listeners
    document.removeEventListener('click', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  },
})

Vue.prototype.apiError = function (error, set_unloading = true, msg = null, warn = false) {
  this.$log.error(error)
  if (set_unloading) {
    store.commit('unloading')
  }
  if (error && error.response && error.response.status === 403) {
    this.$toast.alert(this._('Your session has expired.'))
    return
  }
  if (msg) {
    if (warn) {
      this.$toast.warn(msg)
    } else {
      this.$toast.alert(msg)
    }
  } else {
    this.$toast.alert(this._('Data cannot be loaded at the moment.'))
  }
}

Vue.prototype.$api = apiMethod

/**
 * Cancel ongoing requests by creating a new AbortController instance
 * and assigning it to the Vue instance.
 */
Vue.prototype.cancelRequests = function () {
  Vue.prototype.$httpController.abort()
  Vue.prototype.$httpController = new AbortController()
}

Vue.prototype.tr_field = function (obj, attr) {
  if (store.getters.lang !== '') {
    if (
      Object.prototype.hasOwnProperty.call(obj, attr + '_' + store.getters.lang) &&
      obj[attr + '_' + store.getters.lang]
    ) {
      return obj[attr + '_' + store.getters.lang]
    } else {
      return obj[attr]
    }
  } else {
    if (Object.prototype.hasOwnProperty.call(obj, attr + '_fr') && obj[attr + '_fr']) {
      return obj[attr + '_fr']
    } else if (Object.prototype.hasOwnProperty.call(obj, attr + '_en') && obj[attr + '_en']) {
      return obj[attr + '_en']
    } else {
      return obj[attr]
    }
  }
}

Vue.prototype._tr = function (name_en, name_fr) {
  if (store.getters.lang === 'fr') {
    return name_fr
  } else {
    return name_en
  }
}

Vue.prototype.tr_field_name = function (obj) {
  if (
    Object.prototype.hasOwnProperty.call(obj, 'name_' + store.getters.lang) &&
    obj['name_' + store.getters.lang] &&
    obj['name_' + store.getters.lang].length > 0
  ) {
    return obj['name_' + store.getters.lang]
  } else {
    if (store.getters.lang === 'en') {
      return '⚠️ ' + obj['name_fr']
    } else {
      return '⚠️ ' + obj['name_en']
    }
  }
}

Vue.prototype.tr_field_lang = function (o, f, lang) {
  if (Object.prototype.hasOwnProperty.call(o, f + '_' + lang) && o[f + '_' + lang] && o[f + '_' + lang].length > 0) {
    return o[f + '_' + lang]
  } else {
    let lang_ = 'fr'
    if (lang === 'fr') {
      lang_ = 'en'
    }
    if (
      Object.prototype.hasOwnProperty.call(o, f + '_' + lang_) &&
      o[f + '_' + lang_] &&
      o[f + '_' + lang_].length > 0
    ) {
      return '⚠️ ' + o[f + '_' + lang_]
    }
    return ''
  }
}

Vue.prototype.scaleColorIndicator = function (v, scale = null) {
  let scale_ = scale
  if (!scale_) {
    scale_ = this.current_scale
  }
  if (!scale_ || !scale_.indicator_levels || v === null) {
    return '#eeeeee'
  }
  for (let i = 0; i < scale_.indicator_levels.length; i++) {
    if (i < scale_.indicator_levels.length - 1) {
      if (v >= scale_.indicator_levels[i].value && v < scale_.indicator_levels[i + 1].value) {
        return scale_.indicator_levels[i].color
      }
    } else {
      if (v >= 100 && scale_ && scale_.type !== 'PRIORITY') {
        return scale_.color
      } else if (v >= scale_.indicator_levels[i].value && v <= 100) {
        return scale_.indicator_levels[i].color
      }
    }
  }
  if (v >= 100) {
    return scale_.color
  } else {
    return '#eeeeee'
  }
}

Vue.prototype.scaleIconIndicator = function (v, scale = null) {
  let scale_ = scale
  if (!scale_) {
    scale_ = this.scale
  }
  if (!scale_ || !scale_.indicator_levels || v === null) {
    return null
  }
  for (let i = 0; i < scale_.indicator_levels.length; i++) {
    if (i < scale_.indicator_levels.length - 1) {
      if (v >= scale_.indicator_levels[i].value && v < scale_.indicator_levels[i + 1].value) {
        return scale_.indicator_levels[i].icon
      }
    } else {
      if (v >= scale_.indicator_levels[i].value && v <= 100) {
        return scale_.indicator_levels[i].icon
      }
    }
  }
  return null
}

Vue.prototype.scaleLevelIndicator = function (v, scale = null) {
  let scale_ = scale
  if (!scale_) {
    scale_ = this.scale
  }
  if (!scale_ || !scale_.indicator_levels || v === null) {
    return null
  }
  if (scale_.type === 'PRIORITY') {
    for (let i = 0; i < scale_.indicator_levels.length; i++) {
      if (v === scale_.indicator_levels[i].value) {
        return scale_.indicator_levels[i]
      }
    }
    // If no match is found, return the same level but with no color
    return null
  } else {
    for (let i = 0; i < scale_.indicator_levels.length; i++) {
      if (i < scale_.indicator_levels.length - 1) {
        if (v >= scale_.indicator_levels[i].value && v < scale_.indicator_levels[i + 1].value) {
          return scale_.indicator_levels[i]
        }
      } else {
        if (v >= scale_.indicator_levels[i].value && v <= 100) {
          return scale_.indicator_levels[i]
        }
      }
    }
  }

  return null
}

Vue.prototype.evalScaleColor = function (v, scale) {
  if (!scale || !scale.eval_levels) {
    return '#eee'
  }
  for (let i = 0; i < scale.eval_levels.length; i++) {
    if (v >= scale.eval_levels[i].low_value && v <= scale.eval_levels[i].high_value) {
      return scale.eval_levels[i].color
    }
  }
  return '#eee'
}

Vue.prototype.evalScaleName = function (v, scale) {
  if (!scale || !scale.eval_levels) {
    return ''
  }
  for (let i = 0; i < scale.eval_levels.length; i++) {
    if (v >= scale.eval_levels[i].low_value && v <= scale.eval_levels[i].high_value) {
      return scale.eval_levels[i].name
    }
  }
  return ''
}

Vue.prototype.escape_html_chars = function (text) {
  if (!text) {
    return text
  }
  return text.replace(/[<>\&\"\']/g, function (c) {
    return '&#' + c.charCodeAt(0) + ';'
  })
}

Vue.prototype.download_file = function (url) {
  const _this = this
  this.$http({
    url: url,
    method: 'GET',
    responseType: 'blob', // important
  })
    .then((response) => {
      if (response.data && response.data.ok && response.data.ok === false) {
        throw new Error()
      }
      let filename = response.headers['content-disposition']
      if (!filename || filename === '') {
        filename = 'no_filename'
      } else {
        filename = filename.replace('attachment; filename=', '')
      }
      if (filename[0] === '"') {
        const from = 1
        filename = filename.substring(from, from + filename.length - 2)
      }
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    })
    .catch((err) => {
      _this.$log.debug(err)
      this.$toast.alert(this._('You cannot download this file'))
    })
}

Vue.prototype.wrap_words = function (label, limit = 30, truncate_limit = 60) {
  const words = label.split(' ')
  const aux = []
  let concat = []
  for (let i = 0; i < words.length; i++) {
    concat.push(words[i])
    const join = concat.join(' ')
    if (join.length > truncate_limit) {
      aux.push(join + '...')
      concat = []
      break
    }
    if (join.length > limit) {
      aux.push(join)
      concat = []
    }
  }

  if (concat.length) {
    aux.push(concat.join(' ').trim())
  }
  return aux
}

Vue.prototype.roundedScore = function (obj) {
  if (!obj) {
    return '-'
  }
  const score = obj.score
  if (score !== null && typeof score !== 'undefined' && !isNaN(score)) {
    return Math.round(score).toFixed(0)
  } else {
    return '-'
  }
}

Vue.prototype.highlight_md = function (md, kws) {
  let in_txt = String(md)
  if (kws && Array.isArray(kws) && kws.length > 0) {
    const regex = new RegExp(kws.join('|'), 'gi')
    const matches = in_txt.match(regex) || []
    if (matches.length) {
      for (let i = 0; i < matches.length; i++) {
        in_txt = in_txt.replace(new RegExp(matches[i], 'g'), '<span class="md-hl">' + matches[i] + '</span>')
      }
    }
  }
  return in_txt
}
Vue.prototype.getWidth = function () {
  if ($('#main_content').width() && !isNaN($('#main_content').width())) {
    this.content_width = $('#main_content').width()
  } else {
    console.warn('NOT READY, DELAY')
    setTimeout(this.getWidth, 500)
  }
}

Vue.prototype.formatWorkload = function (v) {
  if (v && +v > 0) {
    const d = store.getters.lang === 'fr' ? 'j' : 'd'
    if (v) {
      return parseFloat((+v).toFixed(2)) + ' ' + d
    }
  } else {
    return ''
  }
}

Vue.prototype.formatCost = function (v) {
  if (v && +v > 0) {
    return parseFloat((+v).toFixed(2)) + ' k€'
  }
}

Vue.prototype.htmlEntities = function (txt) {
  return $('<div />').text(txt).html()
}

Vue.prototype.between = function (x, min, max) {
  return x >= min && x <= max
}

Vue.prototype.checkLabel = function (label_en, label_fr, type, questionnaire) {
  if (type === 'ITEM') {
    if ((label_en !== null && label_en.trim().length !== 0) || (label_fr !== null && label_fr.trim().length !== 0)) {
      return true
    } else {
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (let i = 0; i < questionnaire.sections.length; i++) {
          if (questionnaire.sections[i].questions && questionnaire.sections[i].questions.length > 0) {
            for (let y = 0; y < questionnaire.sections[i].questions.length; y++) {
              if (
                questionnaire.sections[i].questions[y].items &&
                questionnaire.sections[i].questions[y].items.length > 0
              ) {
                questionnaire.sections[i].questions[y].items.forEach((val, index) => {
                  if (
                    !(val.label_en !== null && val.label_en.trim().length !== 0) &&
                    !(val.label_fr !== null && val.label_fr.trim().length !== 0)
                  ) {
                    questionnaire.sections[i].questions[y].items.splice(index, 1)
                    console.warn('GONNA SPLICE', index)
                  }
                })
              }
            }
          }
        }
      }
    }
  } else {
    if ((label_en !== null && label_en.trim().length !== 0) || (label_fr !== null && label_fr.trim().length !== 0)) {
      return true
    }
  }
}

Vue.prototype.checkIdentifier = function (identifier, type, questionnaire, item_id) {
  const reg = new RegExp(/^[a-zA-Z0-9_.\-]{1,10}$/g)
  if (reg.test(identifier) === false) {
    return false
  }

  if (identifier && type && questionnaire) {
    if (type === 'SECTION') {
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (let i = 0; i < questionnaire.sections.length; i++) {
          if (questionnaire.sections[i].id === item_id) {
            continue
          }
          if (questionnaire.sections[i].identifier.toString() === identifier.toString()) {
            return false
          }
        }
      }
      return true
    } else if (type === 'QUESTION') {
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (let i = 0; i < questionnaire.sections.length; i++) {
          if (questionnaire.sections[i].questions && questionnaire.sections[i].questions.length > 0) {
            for (let y = 0; y < questionnaire.sections[i].questions.length; y++) {
              if (questionnaire.sections[i].questions[y].id === item_id) {
                continue
              }
              if (questionnaire.sections[i].questions[y].identifier.toString() === identifier.toString()) {
                return false
              }
            }
          }
        }
      }
      return true
    } else if (type === 'ITEM') {
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (let i = 0; i < questionnaire.sections.length; i++) {
          if (questionnaire.sections[i].questions && questionnaire.sections[i].questions.length > 0) {
            for (let y = 0; y < questionnaire.sections[i].questions.length; y++) {
              if (
                questionnaire.sections[i].questions[y].items &&
                questionnaire.sections[i].questions[y].items.length > 0
              ) {
                for (let z = 0; z < questionnaire.sections[i].questions[y].items.length; z++) {
                  if (questionnaire.sections[i].questions[y].items[z].identifier.toString() === identifier.toString()) {
                    return false
                  }
                  return true
                }
              }
              return true
            }
          }
          return true
        }
      } else {
        return true
      }
    }
  }
  return false
}

Vue.prototype.generateIdentifier = function (type, questionnaire) {
  if (type && questionnaire) {
    if (type === 'SECTION') {
      const identifiers_array = [{ id: -1, val: 0, prefix: 'S' }]
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (let i = 0; i < questionnaire.sections.length; i++) {
          let numbers = []
          let prefix = []
          const identifier = questionnaire.sections[i].identifier
          if (identifier.match(/[\d\.]+/g)) {
            numbers = identifier.match(/[\d\.]+/g).map((i) => parseInt(i))
          }
          if (identifier.match(/[\D\.]+/g)) {
            prefix = identifier.match(/[\D\.]+/g)
          }
          const new_identifier = {
            id: questionnaire.sections[i].id,
            val: numbers && numbers.some((e) => e.val !== null) ? Math.max(...numbers) : 0,
            prefix: prefix[0] ? prefix[0] : '',
          }
          identifiers_array.push(new_identifier)
        }
      }
      const max_identifier = Math.max(...identifiers_array.map((id) => id.val)) + 1
      const max_prefix = identifiers_array.reduce((a, obj) => (a.val > obj.val ? a : obj)).prefix
      return max_prefix + max_identifier
    } else if (type === 'QUESTION') {
      const identifiers_array = [{ id: -1, val: 0, prefix: 'Q' }]
      if (questionnaire.sections && questionnaire.sections.length > 0) {
        for (let i = 0; i < questionnaire.sections.length; i++) {
          if (questionnaire.sections[i].questions && questionnaire.sections[i].questions.length > 0) {
            for (let y = 0; y < questionnaire.sections[i].questions.length; y++) {
              const identifier = questionnaire.sections[i].questions[y].identifier
              let numbers = []
              let prefix = []
              if (identifier.match(/[\d\.]+/g)) {
                numbers = identifier.match(/[\d\.]+/g).map((i) => parseInt(i))
              }
              if (identifier.match(/[\D\.]+/g)) {
                prefix = identifier.match(/[\D\.]+/g)
              }

              const new_identifier = {
                id: questionnaire.sections[i].questions[y].id,
                val: numbers && numbers.some((e) => e.val !== null) ? Math.max(...numbers) : 0,
                prefix: prefix[0] ? prefix[0] : '',
              }
              identifiers_array.push(new_identifier)
            }
          }
        }
      }
      const max_identifier = Math.max(...identifiers_array.map((id) => id.val)) + 1
      const max_prefix = identifiers_array.reduce((a, obj) => (a.val > obj.val ? a : obj)).prefix
      return max_prefix + max_identifier
    }
  }
}

function __custom_merge(objValue, srcValue) {
  if (isArray(objValue) && isArray(srcValue)) {
    return srcValue
  }
}

Vue.prototype.__merge = function (obj1, obj2) {
  return mergeWith(obj1, obj2, __custom_merge)
}

void store.dispatch('init')

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'far fa-arrow-up',
    down: 'far fa-arrow-down',
    right: 'far fa-arrow-right',
    previous: 'far fa-chevron-left',
    next: 'far fa-chevron-right',
    today: 'far fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
})

if (config.apm_enabled) {
  Vue.use(ApmVuePlugin, {
    router,
    config: {
      serviceName: config.apm_service_name,
      serviceVersion: config.apm_service_version,
      serverUrl: config.apm_url,
      environment: config.apm_environment,
      logLevel: config.apm_log_level,
      breakdownMetrics: config.apm_breakdown_metrics,
      distributedTracingOrigins: [
        new RegExp('(https?://)?' + config.url),
        new RegExp('(https?://)?' + config.api_v2_url),
      ],
    },
  })
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
document.addEventListener('DOMContentLoaded', function () {
  //The first argument are the elements to which the plugin shall be initialized
  //The second argument has to be at least a empty object or a object with your desired options
  Vue.prototype.$os_body = OverlayScrollbars(document.querySelectorAll('body'), {
    scrollbars: { autoHide: 'scroll', autoHideDelay: 400 },
  })
  $('body').append($('.d3-tooltip'))
  $('body').append($('.tui-tooltip'))
})
