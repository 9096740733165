import Vue from 'vue'
import OptionsMixin from './options-mixin'
import throttle from 'lodash/throttle'

/**
 * This is a very important mixin in the project.
 * It provide all necessary function to deal with a list of object coming from backend.
 * It handles API request to get the data, add some attributes to each row (to display action),
 * deals with object modifications, add and delete.
 *
 * The list data is organized in tabs.
 *
 * There two main kind of behavior for lists
 *
 * ** Frontend-only list (for small lists) ** : All objects are fetched from the backend.
 * Filter and sorting are performed in the frontend by the table component.
 *
 * ** Backend list (for large lists) ** : only visible objects are shows, list is paginated.
 * Filter and sorting are performed by the backend.
 *
 * It also provides many `data` and `computed` attributes, shared by all list views.
 *
 * Here follows a list of important attributes:
 *
 * - `id_init`: id of the object that should be displayed when the list loads
 * - `delete_warning_message`: custom warning message in object delete confirmation box
 * - `delete_warning_variant`: custom variant (color) for delete button in confirmation box
 * - `override_mounted`: flag that will bypass mounted code if true
 * - `current_row_over`: id of row that is mouse overred
 * - `highlighted_row`: id of row that is mouse overred
 * - `modal_id`: id of modal for form
 * - `api_endpoint`: endpoint that will be used to get the data from the backend
 * - `rowData`: This is where this list of objects from the backend in stored
 * - `row_data_f`: This will hold a filterred version of rowData,
 * - `queryFilters`: This is used for backend filter/sorting/pagination, it stores the current query object
 * - `filters_selected`: this attribute is used with `aciso-filter` component, it will store the selected filters
 * - `delete_endpoint`: backend endpoint for delete objects
 * - `add_right`: flag indicating that the user can add objects (store from backend response)
 * - `disable_dirty_check`: flag disabled dirty form field checks (to prevent annoying confirmation modal)
 * - `current_operation`: current form operation (add/edit/view)
 * - `idDev`: flag to add development data for debugging and automatic testing,
 * - `current_obj`: object that store the currently edited/added object
 * - `api_endpoint_get_params`: additional parameters that will be send to backend when getting data
 * - `selected_items`: not used anymore, was used to store selected items when items could be selected by checkbox
 * - `import_report`: This is used to store the import report (when importing object from Excel)
 * - `post_delete_message`: Override message when object is deleted
 * - `immediate_delete`: Set to true when objets are deleted synchronously
 * @public
 */
export default {
  mixins: [OptionsMixin],
  data: function () {
    return {
      display_loader: true,
      id_init: null,
      delete_warning_message: null,
      delete_warning_title: null,
      delete_button_label: null,
      delete_warning_variant: null,
      override_mounted: false,
      current_row_over: -1,
      modal_id: 'add_edit',
      api_endpoint: '',
      rowData: [],
      to_add: [],
      quick_filters: null,
      queryFilters: [],
      filters_selected: false,
      delete_endpoint: null,
      add_right: false,
      disable_dirty_check: false,
      current_operation: '',
      idDev: false,
      current_obj: {},
      api_endpoint_get_params: {},
      selected_items: [],
      exclude_edit_fields: [],
      highlighted_row: -1,
      row_data_f: [],
      import_report: '',
      resp_fetchdata: null,
      current_request_number: 0,
      current_response_number: 0,
      fork_confirm_message: null,
      fork_confirm_button: null,
      fork_cancel_button: null,
      fork_ok_message: null,
      post_delete_message: null,
      immediate_delete: true,
      // unshift or push in list after addRow
      post_add_first: false,
    }
  },
  computed: {
    /**
     * This is the default configuration object of the table component
     * @public
     */
    config: function () {
      return {
        card_mode: false,
        checkbox_rows: false,
        rows_selectable: false,
        show_actions: true,
        show_refresh_button: true,
        show_reset_button: false,
        global_search: {
          visibility: true,
          placeholder: this._('Search'),
        },
        pagination: false,
        pagination_info: false,
        per_page: 20,
        per_page_options: this.$store.getters.per_page_options,
      }
    },
  },
  methods: {
    /**
     * This handles the mouseOver event when user place their mouse over a particular row.
     * Two `data` attributes, `highlighted_row` and `current_row_over` are set with row id.
     * We need 2 attributes to prevent flickering (the second one is updated after a delay)
     *
     * @public
     */
    rowOver(id) {
      this.highlighted_row = id
      this.current_row_over = id
    },
    /**
     * This handles the mouseOut event.
     * It clears `highlighted_row` and `current_row_over`, the latter after a delay
     * @public
     */
    rowOut() {
      this.highlighted_row = -1
      setTimeout(() => {
        if (this.highlighted_row < 0) {
          this.current_row_over = -1
        }
      }, 1000)
    },
    /**
     * This function is used by aciso-filter.vue component, it updated the {row_data_f} with filtered rows
     * @param newRowData contains selected filter and filtered rows
     * @public
     */
    filterRowData: function (newRowData) {
      const _this = this
      _this.row_data_f = newRowData[0]
      _this.filters_selected = newRowData[1]
    },
    /**
     * Helper function that find a row in the data by id
     * @public
     * @returns {object} with `row` and `index`
     */
    getRow: function (id, list_attr = 'rowData') {
      if (!this[list_attr]) {
        return
      }
      const index = this[list_attr].findIndex((e) => e.id === id)
      if (index >= 0) {
        return { row: this[list_attr][index], index: index }
      } else {
        return { row: null, index: -1 }
      }
    },
    /**
     * This hook is used when an object is added to the list.
     * It inserts the new data in the `rowData` attribute.
     * Two hooks will be called (if defined on the component):
     * - `preAdded` : executed before row is added in the list
     * - `postAdd` : executed after row is added in the list
     * @param obj the response from backend when posting the new object
     * @param list_attr
     * @param obj_attr
     * @public
     */
    addedRow: function (obj, list_attr = 'rowData', obj_attr = 'current_obj') {
      const _this = this
      if (obj) {
        if (this.preAdded) {
          this.preAdded(obj)
        }
        obj.object.tactions_ = _this.getActions(obj.object)
        if (!this[list_attr]) {
          return
        }
        if (this.post_add_first) {
          this[list_attr].unshift(obj.object)
        } else {
          this[list_attr].push(obj.object)
        }
        if (this.postAdd) {
          this.postAdd(obj)
        }
        if (this.total_rows !== null) {
          this.total_rows += 1
        }
      }
      setTimeout(() => {
        _this[obj_attr] = null
        _this.$validator.reset()
      }, 500)
    },
    /**
     * This hook is used when an object is edited.
     * It updates the object in the list.
     * * Two hooks will be called (if defined on the component):
     * - `preEdite` : executed before row in the list is edited
     * - `postEdit` : executed after row is edited
     * @param obj the response from backend when posting the new object
     * @param ref indicate that only references to other objects have been updated
     * @param list_attr
     * @param obj_attr
     * @public
     */
    editedRow: function (obj, ref = false, list_attr = 'rowData', obj_attr = 'current_obj') {
      const _this = this
      if (this.preEdited) {
        this.preEdited(obj)
      }

      if (!this[list_attr]) {
        return
      }
      const index = this.getRow(obj.object.id, list_attr).index
      if (index >= 0) {
        for (const p in obj.object) {
          if (!this.exclude_edit_fields.includes(p)) {
            Vue.set(this[list_attr][index], p, obj.object[p])
          }
        }
        this[list_attr][index].tactions_ = _this.getActions(obj.object)
        this.$bvModal.hide(this.modal_id)
        if (this.postEdit) {
          this.postEdit(obj)
        }
      }

      if (!ref) {
        // on ne vide pas le current obj si il sagit d'un update d'un ref e.g => association indicators
        setTimeout(() => {
          _this[obj_attr] = null
          _this.$validator.reset()
        }, 500)
      }
    },
    /**
     * This hook is used when an object is selected (i.e. clicked).
     * @param id id of the selected row
     * @public
     */
    selectRow: function (id) {
      if (this.clickedRow) {
        this.clickedRow(id)
      } else {
        this.editRow(id)
      }
    },
    updateSelection(items) {
      this.selected_items = items
    },
    /**
     * Deleted selected elements, send DELETE HTTP requests to the backend
     * Not used anymore since objects cannot be selected in lists
     * @public
     */
    deleteSelected: function () {
      const _this = this
      if (this.selected_items.length > 0) {
        this.confirm(this._('Are you sure you want to delete these objects'), this._('Delete'), this._('Cancel'))
          .then(() => {
            _this.$store.commit('loading')
            const promises = []
            _this.selected_items.forEach((s) => {
              let endpoint
              if (_this.delete_endpoint) {
                endpoint = _this.delete_endpoint
              } else {
                endpoint = _this.api_endpoint
              }
              if (typeof s.id === 'string' || s.id instanceof String) {
                const type_id = _this.getIdType(s.id)
                if (type_id[0] === 'r') {
                  endpoint = 'remediation'
                } else if (type_id[0] === 'm') {
                  endpoint = 'measure'
                }
                promises.push(_this.doDeleteRow(type_id[1], endpoint, type_id[0]))
              } else {
                promises.push(_this.doDeleteRow(s.id, endpoint))
              }
            })
            Promise.all(promises)
              .then(() => {
                _this.$store.commit('unloading')
                this.selected_items = []
                if (this.postDelete) {
                  this.postDelete()
                }
                if (this.total_rows !== null) {
                  this.total_rows -= promises.length
                }
              })
              .catch(() => {
                _this.$toast.alert(_this._('An error occured.'))
                _this.$store.commit('unloading')
                this.selected_items = []
              })
          })
          .catch(() => {})
      }
    },
    doDeleteRow: function (id, endpoint_ = null, prefix = '') {
      const _this = this
      let endpoint = _this.api_endpoint
      if (endpoint_ !== null) {
        endpoint = endpoint_
      }
      if (endpoint.indexOf('?') > 0) {
        endpoint = endpoint.substring(0, endpoint.indexOf('?'))
      }
      return new Promise((resolve, reject) => {
        _this.$http
          .delete(`${endpoint}/${id}`)
          .then((res) => {
            if (res.data.ok) {
              let id_obj = id
              if (prefix !== '') {
                id_obj = prefix + id.toString()
              }
              if (this.total_rows !== null) {
                this.total_rows -= 1
              }
              _this.$root.$emit('row:delete', {
                id: id_obj,
                endpoint: _this.api_endpoint,
              })
              const to_delete = _this.getRow(id_obj).index
              if (to_delete !== undefined && _this.immediate_delete && to_delete !== -1) {
                _this.rowData.splice(to_delete, 1)
                if (_this.postDelete) {
                  _this.postDelete(id_obj)
                }
              }
              resolve()
            } else {
              throw new Error(res.data.message)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * Deleted the selected row, send DELETE HTTP request to the backend
     * Show confirmation box to make sure the action is requested
     * @public
     * @param id: id of the row to delete
     * @param additional_delete: used to show linked object that should be deleted alongside
     */
    deleteRow: throttle(
      function (id, additional_delete = null, list_attr = 'rowData') {
        const _this = this
        let endpoint = ''
        if (_this.delete_endpoint) {
          endpoint = _this.delete_endpoint
        } else {
          endpoint = _this.api_endpoint
        }
        this.confirm(
          this.delete_warning_message || this._('Delete this object?'),
          this.delete_button_label || this._('Delete'),
          this._('Cancel'),
          false,
          additional_delete,
          this.delete_warning_variant || 'outline-primary ',
          this.delete_warning_title
        )
          .then(() => {
            _this.$store.commit('loading')
            const s = _this.getRow(id, list_attr).row
            if (s === null) {
              _this.$store.commit('unloading')
              return
            }
            let prefix = ''
            let obj_id = id
            if (typeof s.id === 'string' || s.id instanceof String) {
              const type_id = _this.getIdType(s.id)
              if (type_id[0] === 'r') {
                endpoint = 'remediation'
              } else if (type_id[0] === 'm') {
                endpoint = 'measure'
              }
              obj_id = type_id[1]
              prefix = type_id[0]
            }
            if (additional_delete) {
              if (
                _this.additional_delete_values &&
                Object.prototype.hasOwnProperty.call(_this.additional_delete_values, 'obj_parent_deleted')
              ) {
                _this.$set(_this.additional_delete_values, 'obj_parent_deleted', s)
              }
            }
            _this
              .doDeleteRow(obj_id, endpoint, prefix)
              .then(() => {
                _this.$store.commit('unloading')
                if (_this.post_delete_message) {
                  _this.$toast.info(_this.post_delete_message)
                }
              })
              .catch(() => {
                _this.$toast.alert(_this._('An error occured.'))
                _this.$store.commit('unloading')
              })
          })
          .catch(() => {})
      },
      1000,
      { leading: true, trailing: false }
    ),
    /**
     * Helper function that return the `dit_right` property of a row
     * @public
     */
    hasEditRight: function (p) {
      return p.row.edit_right
    },
    /**
     * Helper function that return the `delete_right` property of a row
     * @public
     */
    hasDeleteRight: function (p) {
      return p.row.delete_right
    },
    /**
     * Helper function that return the `edit_right_regular` property of a row
     * @public
     */
    hasRegularEditRight: function (p) {
      return p.row.edit_right_regular
    },
    /**
     * Helper function that return the `view_right` property of a row
     * @public
     */
    hasViewRight: function (p) {
      return p.row.view_right
    },
    /**
     * Helper function usesd in lists combining multiple types of objects.
     * In this case, the id is no longer unique, and a string prefix (one letter) is used
     * This function extracts the type (the letter) and the database id from the id
     * @param id the id to process
     * @returns a 2-element array, first is the type, second is the database id
     * @public
     */
    getIdType(id) {
      if (id.length > 1) {
        return [id[0], parseInt(id.substring(1))]
      }
      return [null, null]
    },
    /**
     * This function trigger the edition of an object, showing the form in edit mode
     * It performs several important operations
     *  - if a form is already openned, it checks if it is dirty and ask confirmation to save current modification
     *  - it copied the row to the `attr` attribute on the component
     * @param id the id of the row to edit
     * @param attr the attribute that will store edited object
     * @param attr_op
     * @param list_attr
     * @public
     */
    editRow: function (id, attr = 'current_obj', attr_op = 'current_operation', list_attr = 'rowData') {
      if (attr === null) {
        attr = 'current_obj'
      }
      let dirty = false
      if (this.$refs.edit_form) {
        dirty = this.$refs.edit_form.isDirty()
      }
      if (this.$refs.edit_form2) {
        dirty = dirty || this.$refs.edit_form2.isDirty()
      }
      if (dirty && !this.disable_dirty_check && this[attr]) {
        this.confirm(
          this._('You have unsaved modification, are you sure you want to discard them?'),
          this._('Discard changes'),
          this._('Cancel')
        )
          .then(() => {
            if (typeof id === 'string' || id instanceof String) {
              this[attr] = this.copyNestedObjects(this.getRow(id).row)
              this[attr].id = this.getIdType(id)[1]
            } else {
              this[attr] = this.getRow(id, list_attr).row
            }
            if (this[attr].edit_right) {
              this[attr_op] = 'edit'
            } else {
              this[attr_op] = 'view'
            }
          })
          .catch(() => {})
      } else {
        if (typeof id === 'string' || id instanceof String) {
          this[attr] = this.copyNestedObjects(this.getRow(id).row)
          this[attr].id = this.getIdType(id)[1]
        } else {
          this[attr] = this.getRow(id, list_attr).row
        }
        if (this[attr].edit_right) {
          this[attr_op] = 'edit'
        } else {
          this[attr_op] = 'view'
        }
      }
    },
    /**
     * This function shows the read-only form of an object, showing the form in edit mode
     * @param id the id of the row to edit
     * @public
     */
    viewRow: function (id) {
      this.getRow(id)
      this.current_operation = 'view'
    },
    /**
     * This function shows the form in add mode (empty object)
     * It should be overridden to have a custom initial object.
     * @public
     */
    addRow: function () {
      if (this.add_right) {
        this.current_obj = {}
        this.current_operation = 'add'
      } else {
        this.$toast.warning(this._("You don't have the permission to add an object"))
      }
    },
    preForkSubmit(obj) {
      return obj
    },
    doForkRow: function (id, info, do_reload, reset_page = false) {
      this.$store.commit('loading')
      let endpoint = this.api_endpoint
      if (this.fork_endpoint) {
        endpoint = this.fork_endpoint
      }
      let obj = { id: id }
      const msg = this.fork_ok_message || this._('Object copied successfully in your catalog')
      obj = this.preForkSubmit(obj)
      this.$http
        .post(`/${endpoint}/fork`, obj)
        .then((resp) => {
          if (resp.data.ok) {
            this.$store.commit('unloading')
            if (info) {
              this.$toast.info(msg)
            }
            this.id_init = null
            this.$emit('forked')
            if (reset_page) {
              this.queryParams.page = 1
            }
            if (do_reload) {
              if (resp.data.object && resp.data.object.id) {
                this.id_init = resp.data.object.id
              }
              this.fetchData()
            } else {
              resp.data.object.tactions_ = this.getActions(resp.data.object)
              this.rowData.push(resp.data.object)
              this.editRow(resp.data.object.id)
            }
          } else {
            throw new Error(resp.data.message)
          }
        })
        .catch((err) => {
          this.apiError(err, true, err.message)
        })
    },
    /**
     * Triggers a fork (duplication) of the selected object
     * @param id: the id of the row to fork
     * @public
     */
    forkRow: throttle(
      function (id, info = false, confirm = true, do_reload = true, reset_page = false) {
        if (confirm) {
          const msg = this.fork_confirm_message || this._('Please confirm to copy this object')
          const cancel_button = this.fork_cancel_button || this._('No')
          const ok_button = this.fork_confirm_button || this._('Yes')
          this.confirm(msg, ok_button, cancel_button)
            .then(() => {
              this.doForkRow(id, info, do_reload, reset_page)
            })
            .catch(() => {})
        } else {
          this.doForkRow(id, info)
        }
      },
      1000,
      { leading: true, trailing: false }
    ),
    /**
     * This is the default implementation of the function that will fill the `tactions_` attribute on a row
     * It is used to show actions on mouse over.
     * Default actions is only the delete action.
     * Actions have to be objects with the following attributes:
     *  - icon: the icon of the action (font awesome)
     *  - label: the text that will be display when user mouse overs the icon
     *  - cb: the callback executed when user click on the icon
     *  - show: a flag indicating whether the icon should be shown or not
     * @public
     * @param r
     */
    getActions(r) {
      return [
        {
          icon: 'trash-alt',
          label: this._('Delete'),
          cb: this.deleteRow,
          show: r.delete_right,
        },
      ]
    },
    /**
     * This is the handler executed when backend response completes
     * It will call the `postFetchData` hook after data has been stored in {rowDAta}
     * It will set `tactions_` attribute on rows based on `getActions` method
     * It will also trigger form display if an initial object is requested.
     * @param resp the response from the backend
     * @public
     */
    fetchDataOk: function (resp) {
      const _this = this
      if (this.display_loader) {
        _this.$store.commit('unloading')
      }
      this.current_response_number += 1
      if (this.current_response_number !== this.current_request_number && this.current_request_number > 0) {
        return
      }
      if (resp.data.ok) {
        _this.resp_fetchdata = resp.data
        _this.rowData = resp.data.objects
        _this.total_rows = resp.data.total
        _this.add_right = resp.data.add_right || false
        if (_this.postFetchData) {
          _this.postFetchData(resp.data)
        }
        _this.rowData.forEach((r) => {
          _this.$set(r, 'tactions_', _this.getActions(r))
        })
        if (_this.$route && _this.$route.params && _this.$route.params.id_init) {
          if (_this.rowData.some((e) => e.id === _this.id_init)) {
            _this.editRow(_this.$route.params.id_init)
          }
        }
        if (_this.id_init !== null) {
          if (_this.rowData.some((e) => e.id === _this.id_init)) {
            _this.editRow(_this.id_init)
          }
          _this.$nextTick(() => {
            _this.id_init = null
          })
        }
        if (_this.$route && _this.$route.params && _this.$route.params.id_remediation && _this.editRemediation_) {
          _this.editRemediation_(_this.$route.params.id_remediation)
        }
      } else {
        throw new Error()
      }
    },
    /**
     * This is the method that will be the data from the backend, with required parameters
     * @public
     */
    fetchData: function () {
      const _this = this
      if (this.display_loader) {
        _this.$store.commit('loading')
      }
      if (_this.preFetchData) {
        _this.preFetchData()
      }
      let params = {}
      if (this.queryParams) {
        params = {
          queryParams: this.queryParams,
          page: this.queryParams.page,
        }
        let qget = ''
        if (this.api_endpoint_get_params) {
          qget = '?'
          let np = 0
          for (const p in this.api_endpoint_get_params) {
            qget += p + '=' + this.api_endpoint_get_params[p] + '&'
            np += 1
          }
          if (np > 0) {
            qget = qget.slice(0, -1)
          }
        }
        this.current_request_number += 1
        this.$http
          .post(`/${this.api_endpoint}/page${qget}`, params)
          .then(_this.fetchDataOk)
          .catch((err) => {
            this.apiError(err)
          })
        return
      } else if (this.api_endpoint_get_params) {
        params = this.api_endpoint_get_params
      }
      this.$http
        .get(`/${this.api_endpoint}`, { params: params })
        .then(_this.fetchDataOk)
        .catch((err) => {
          this.apiError(err)
        })
    },
    /**
     * This method is called when an import has finished
     * It will show the report (if not empty) and trigger a `fetchData` to update the list
     * @public
     */
    imported(report) {
      this.show_import = ''
      this.import_report = report
      if (this.postImport) {
        this.postImport()
      }
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    /**
     * Helper function that will automatically select the first non-empty tab
     * @public
     */
    activeTab: function () {
      const _this = this
      const active_tab = _this.tabs.findIndex((tab) => tab.filter(_this.rowData).length > 0)
      if (active_tab >= 0) {
        _this.tabs[active_tab].active = true
      }
    },
    changeQuery: function (queryParams) {
      this.queryParams = queryParams
      this.queryParams.filters = []
      this.filters_selected = false
      if (this.queryParams && this.queryParams.global_search) {
        this.queryParams.global_search = this.queryParams.global_search.trim()
      }
      this.queryFilters.forEach((f) => {
        if (f.values.length > 0) {
          this.queryParams.filters.push({
            name: f.attr,
            selected_options: f.values.map((e) => e.id),
          })
          this.filters_selected = true
        }
      })
      if (this.quick_filters) {
        this.quick_filters.forEach((f) => {
          if (f.values.length > 0) {
            this.queryParams.filters.push({
              name: f.attr,
              selected_options: f.values.map((e) => e.id),
            })
          }
        })
      }
      this.fetchData()
    },
    /**
     * Helper function that will update the filter query
     * @public
     */
    updateFilters: function (filters) {
      this.queryFilters = filters
      this.changeQuery(this.queryParams)
    },
    /**
     * Helper function that will show the tree/graph visualization of the boject
     * @public
     * @param id database id of the object
     * @param type type of object
     * @param perimeter perimeter scope
     */
    showViz(id, type, perimeter = null) {
      this.$root.$emit('treeViz', { id: id, type: type, perimeter: perimeter })
    },
    parseDatetime(date) {
      if (date) {
        return this.$moment.utc(date, 'DD/MM/YYYY HH:mm:ss')
      } else {
        return null
      }
    },
  },
  mounted() {
    this.current_request_number = 0
    this.current_response_number = 0
    if (!this.override_mounted) {
      this.fetchData()
    }
  },
  beforeMount() {
    this.current_obj = {}
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchData()
    next()
  },
  updated() {},
}
