<template>
  <div>
    <b-row>
      <b-col cols="12">
        <aciso-table
          :columns="columnsReview"
          :config="config"
          :actions="actions"
          :rows="has_filter ? filterReviewsBySource(row_data_f) : filterReviewsBySource(rowData)"
          ref="review"
          :custom_no_results="_('No review')"
          @clicked="selectRow($event)"
          @on-toggle="switchClosed($event)"
        >
          <template
            slot="perimeter"
            slot-scope="props"
          >
            <b-badge variant="tertiary">{{ props.row.perimeter.identifier }}</b-badge>
          </template>
          <template
            slot="source"
            slot-scope="props"
          >
            <span v-if="props.row.source === 'REMEDIATION'">
              <b-badge variant="primary">{{ props.row.remediation.identifier }}</b-badge>
              {{ props.row.remediation.name | truncate(truncate_lenght) }}
            </span>

            <span v-if="props.row.source === 'MSDEFENDER'">
              <translate>Microsoft secure score</translate>
            </span>
            <span v-if="props.row.source === 'USER'">
              <translate>Archive</translate>
            </span>
          </template>
          <template
            slot="target"
            slot-scope="props"
          >
            <b-badge variant="primary">{{ getTargetLabel(props.row) }}</b-badge>
            <span
              v-if="props.row.target_type === 'EVAL'"
              v-b-tooltip
              :title="props.row.evaluation.control.name"
            >
              <b-badge variant="primary">{{ props.row.evaluation.campaign_source.identifier }}</b-badge>
              {{ props.row.evaluation.campaign_source.name }}
              <b-badge variant="light">{{ props.row.evaluation.control.identifier }}</b-badge>
              {{ props.row.evaluation.control.name }}
            </span>
            <span
              v-if="props.row.target_type === 'PROJECT'"
              v-b-tooltip
              :title="props.row.isp_project.name"
            >
              <b-badge variant="primary">{{ props.row.isp_project.identifier }}</b-badge>
              {{ props.row.isp_project.name }}
            </span>
            <span
              v-if="props.row.target_type === 'GAP'"
              v-b-tooltip
              :title="props.row.gap.name"
            >
              <b-badge variant="primary">{{ props.row.gap.identifier }}</b-badge>
              {{ props.row.gap.name | truncate(truncate_lenght) }}
            </span>
            <span v-if="props.row.target_type === 'POLICY'">
              <b-badge variant="primary">{{ props.row.policy.identifier }}</b-badge>
              {{ props.row.policy.name | truncate(truncate_lenght) }}
            </span>
            <span v-if="props.row.target_type === 'ACTION_MAPPING'">
              <b-badge variant="primary">{{ props.row.program.identifier }}</b-badge>
              {{ props.row.program.name | truncate(truncate_lenght) }}
            </span>
            <span v-if="props.row.source === 'CONNECTOR'">
              <b-badge variant="primary">{{ props.row.connector.identifier }}</b-badge>
              {{ props.row.connector.name | truncate(truncate_lenght) }}
            </span>
            <span v-if="props.row.source === 'AUTH_CONFIG'"> <translate>Authentication config</translate> </span>
            <span
              v-if="props.row.target_type === 'EXEMPTION'"
              v-b-tooltip
              :title="props.row.exemption.name"
            >
              <b-badge variant="primary">{{ props.row.exemption.identifier }}</b-badge>
              {{ props.row.exemption.name }}
            </span>
            <span
              v-if="props.row.target_type === 'INCIDENT'"
              v-b-tooltip
              :title="props.row.incident.name"
            >
              <b-badge variant="primary">{{ props.row.incident.identifier }}</b-badge>
              {{ props.row.incident.name }}
            </span>
            <span
              v-if="props.row.target_type === 'UPGRADE'"
              v-b-tooltip
              :title="props.row.measure.name"
            >
              <b-badge variant="primary">{{ props.row.measure.identifier }}</b-badge>
              {{ props.row.measure.name }}
            </span>
            <span
              v-if="props.row.target_type === 'BUILD'"
              v-b-tooltip
              :title="props.row.solution.name"
            >
              <b-badge variant="primary">{{ props.row.solution.identifier }}</b-badge>
              {{ props.row.solution.name }}
            </span>
            <span
              v-if="props.row.target_type === 'USER'"
              v-b-tooltip
              :title="props.row.user.name"
            >
              {{ props.row.user.name }}
            </span>
          </template>
          <template
            slot="description"
            slot-scope="props"
          >
            {{ descriptionSuggest(props.row) }}
          </template>
          <template
            slot="status"
            slot-scope="props"
          >
            <b-badge :variant="props.row.status === 'OPEN' ? 'success' : 'light-grey'"
              >{{ enumMixin.reviewStatusStr[props.row.status] }}
            </b-badge>
          </template>
          <template
            slot="manager"
            slot-scope="props"
          >
            <b-badge
              v-if="props.row.manager"
              variant="primary"
              >{{ props.row.manager.identifier }}
            </b-badge>
            {{ props.row.manager ? props.row.manager.name : '-' }}
          </template>
          <template
            slot="close_date"
            slot-scope="props"
          >
            <span v-if="props.row.timestamp">
              {{ props.row.timestamp | date }}
            </span>
            <span v-else> - </span>
          </template>
        </aciso-table>
      </b-col>
    </b-row>
    <solution-fork-select
      :current_obj_="current_solution"
      :current_solution_="fork_from_object"
      :type="'run'"
      @added=";(current_solution = null), fetchData()"
      @cancelled="current_solution = null"
      api_endpoint_post="solution/fork_run"
      current_operation="add"
      do_post
      is_modal
      modal_id="solution_review"
      ref="sp"
    ></solution-fork-select>
    <isp-project-transition-modal
      modal_id="CloseIspReview"
      :actions_not_finished="false"
      :current_date="current_date"
      :current_next_step="close_step"
      :current_obj="current_isp_project"
      scope="isp_project_modal"
      :show_end_date="true"
      @to-next-step="closeIspProject($event)"
    ></isp-project-transition-modal>
  </div>
</template>

<script>
import AcisoTable from '@/components/layout/aciso-table.vue'
import GridMixin from '@/components/mixins/grid-mixin'
import SolutionForkSelect from '@/components/forms/solution-fork-select.vue'
import IspProjectTransitionModal from '@/contexts/projects-security/project-security/ProjectSecurityTransitionModal.vue'
import { useEnumMixin } from '@/composables/UseEnumMixin'
import { getOptionsMixin } from '@/services/GetOptionsMixin.services'

export default {
  name: 'review-table',
  components: { IspProjectTransitionModal, SolutionForkSelect, AcisoTable },
  mixins: [GridMixin],
  props: {
    api_endpoint_: String,
    has_filter: Boolean,
    review_source: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      enumMixin: useEnumMixin(),
      api_endpoint: this.api_endpoint_,
      closed_visible: false,
      current_solution: null,
      fork_from_object: null,
      row_data_filtered_first: null,
      close_step: null,
      dev_cycle_options: null,
      current_date: null,
      current_isp_project: null,
    }
  },
  computed: {
    has_source() {
      if (this.$route.path === '/reviews') {
        return !(
          this.review_source === 'ACTION_MAPPING' ||
          this.review_source === 'POLICY' ||
          this.review_source === 'CONFIGURATION'
        )
      } else {
        return true
      }
    },

    truncate_lenght() {
      return this.has_filter ? 45 : 20
    },
    columnsReview: function () {
      if (this.has_source) {
        return [
          {
            label: this._('Perimeter'),
            slot_name: 'perimeter',
            name: 'perimeter.identifier',
            sort: true,
            row_classes: 'w_5',
          },
          {
            label: this._('Suggested action'),
            name: 'description',
            row_classes: 'w_25',
          },
          {
            label: this._('Target'),
            slot_name: 'target',
            name: 'target',
            row_classes: 'w_25',
          },
          {
            label: this._('Source'),
            slot_name: 'source',
            name: 'source',
            row_classes: 'w_20',
          },

          {
            label: this._('Status'),
            name: 'status',
            sort: true,
            row_classes: 'w_5',
          },
          {
            label: this._('Manager'),
            slot_name: 'manager',
            name: 'manager.name',
            row_classes: 'w_10',
            visibility: this.has_filter,
          },
          {
            label: this._('Closure date'),
            slot_name: 'close_date',
            name: 'timestamp',
            row_classes: 'w_10',
            visibility: this.has_filter,
          },
        ]
      } else {
        return [
          {
            label: this._('Suggested action'),
            name: 'description',
            row_classes: 'w_25',
          },
          {
            label: this._('Target'),
            slot_name: 'target',
            name: 'target',
            row_classes: 'w_25',
          },

          {
            label: this._('Status'),
            name: 'status',
            sort: true,
            row_classes: 'w_5',
          },
          {
            label: this._('Manager'),
            slot_name: 'manager',
            name: 'manager.name',
            row_classes: 'w_10',
            visibility: this.has_filter,
          },
          {
            label: this._('Closure date'),
            slot_name: 'close_date',
            name: 'timestamp',
            row_classes: 'w_10',
            visibility: this.has_filter,
          },
        ]
      }
    },
    config: function () {
      return {
        card_mode: false,
        checkbox_rows: false,
        rows_selectable: false,
        show_actions: true,
        show_refresh_button: true,
        show_reset_button: false,
        global_search: {
          visibility: false,
          placeholder: this._('Search'),
        },
        pagination: false,
        pagination_info: false,
        per_page: 20,
        per_page_options: this.$store.getters.per_page_options,
      }
    },
    actions: function () {
      if (this.has_filter) {
        return [
          {
            id: 'btn_toggle',
            btn_text: this._('Show closed'),
            event_name: 'on-toggle',
            event_payload: {},
            type: 'toggle',
          },
        ]
      } else {
        return []
      }
    },
  },
  methods: {
    filterReviewsBySource(data) {
      if (this.$route.path === '/reviews') {
        if (this.review_source === 'MEASURE') {
          return data.filter((review) => review.target_type === 'BUILD' || review.target_type === 'UPGRADE')
        } else if (this.review_source === 'CONFIGURATION') {
          return data.filter((review) => review.source === 'CONNECTOR' || review.source === 'AUTH_CONFIG')
        } else {
          return data.filter((review) => review.target_type === this.review_source)
        }
      } else {
        return data
      }
    },
    switchClosed(event) {
      this.closed_visible = event
      this.postFetchData()
    },
    selectRow(id) {
      const _this = this
      const row = _this.getRow(id).row
      if (row) {
        if (row === 'AUTH_CONFIG') {
          _this.goToAuthConfig(row)
        } else if (row.source === 'REMEDIATION' && row.target_type === 'EVAL') {
          _this.goToEvaluation(row)
        } else if (row.source === 'USER' && row.target_type === 'USER') {
          _this.goToReassignObject(row)
        } else if (
          row.source === 'MSDEFENDER' ||
          (row.source === 'REMEDIATION' &&
            row.target_type !== 'UPGRADE' &&
            row.target_type !== 'BUILD' &&
            row.target_type !== 'EVAL')
        ) {
          _this.closeTarget(row)
        } else if (row.source === 'REMEDIATION' && row.target_type === 'UPGRADE') {
          _this.upgradeMeasure(row)
        } else if (row.source === 'REMEDIATION' && row.target_type === 'BUILD') {
          _this.runSolution(row)
        } else if (row.source === 'POLICY') {
          _this.redirectToPolicy(row)
        } else if (row.source === 'CONNECTOR') {
          _this.redirectToConnector(row)
        } else if (row.source === 'ACTION_MAPPING') {
          _this.redirectToActionMapping(row)
        }
      }
    },
    getActions(r) {
      const _this = this
      return [
        {
          icon: 'external-link',
          label: this._('Go to authentication config'),
          cb: () => _this.goToAuthConfig(),
          show: r.source === 'AUTH_CONFIG',
        },
        {
          icon: 'external-link',
          label: this._('Go to evaluation'),
          cb: () => _this.goToEvaluation(r),
          show: r.source === 'REMEDIATION' && r.target_type === 'EVAL',
        },
        {
          icon: 'user-check',
          label: this._('Reassign objects'),
          cb: () => _this.goToReassignObject(r),
          show: r.source === 'USER' && r.target_type === 'USER',
        },
        {
          icon: 'check-square',
          label: this._('Close target'),
          cb: () => _this.closeTarget(r),
          show:
            r.source === 'MSDEFENDER' ||
            (r.source === 'REMEDIATION' &&
              r.target_type !== 'UPGRADE' &&
              r.target_type !== 'BUILD' &&
              r.target_type !== 'EVAL'),
        },
        {
          icon: 'chevron-double-up',
          label: _this.getUpgradeLabel(r),
          cb: () => _this.upgradeMeasure(r),
          show: r.source === 'REMEDIATION' && r.target_type === 'UPGRADE',
        },
        {
          icon: 'shield-check',
          label: this._('Run the measure'),
          cb: () => _this.runSolution(r),
          show: r.source === 'REMEDIATION' && r.target_type === 'BUILD',
        },

        {
          icon: 'external-link',
          label: this._('Go to policy mapping'),
          cb: () => {
            _this.redirectToPolicy(r)
          },
          show: r.source === 'POLICY',
        },
        {
          icon: 'external-link',
          label: this._('Go to connector'),
          cb: () => {
            _this.redirectToConnector(r)
          },
          show: r.source === 'CONNECTOR',
        },
        {
          icon: 'external-link',
          label: this._('Go to action mapping'),
          cb: () => {
            _this.redirectToActionMapping(r)
          },
          show: r.source === 'ACTION_MAPPING',
        },
        {
          icon: 'ban',
          label: this._('Close review'),
          cb: () => _this.updateReviewStatus(r, 'CLOSED'),
          show: r.status === 'OPEN',
        },
        {
          icon: 'undo',
          label: this._('Open review'),
          cb: () => _this.updateReviewStatus(r, 'OPEN'),
          show: r.status === 'CLOSED',
        },
      ]
    },
    redirectToPolicy(row) {
      this.$router.push({
        name: 'policy_version_catalog',
        params: { id: row.policy.current_version },
      })
    },
    redirectToConnector(row) {
      this.$router.push({
        name: 'connectors',
        params: { review: row },
      })
    },
    redirectToActionMapping(row) {
      this.$router.push({
        name: 'actionRegistersMapping',
        params: { id: row.program.id },
      })
    },
    getTargetLabel(row) {
      if (row.target_type === 'BUILD' || row.target_type === 'UPGRADE') {
        return this._('Measure')
      } else {
        return this.enumMixin.reviewTargetStr[row.target_type]
      }
    },
    postFetchData() {
      if (this.rowData.length === 0) {
        this.$emit('close-modal')
      }
      if (this.closed_visible) {
        this.row_data_f = this.rowData
      } else {
        this.row_data_f = this.rowData.filter((r) => r.status === 'OPEN')
      }
      this.$store.dispatch('updateNbReviews')
    },
    updateReviewStatus(row, status) {
      const obj = { id: row.id, status: status }
      this.$store.commit('loading')
      const _this = this
      this.$http
        .put('review/' + row.id, obj)
        .then((res) => {
          if (res.data.ok) {
            const index = _this.rowData.findIndex((r) => r.id === res.data.object.id)
            if (_this.has_filter) {
              _this.fetchData()
            } else {
              _this.rowData.splice(index, 1)
              if (_this.rowData.length <= 0) {
                _this.$emit('close-modal')
              }
            }
            _this.postFetchData()
            this.$emit('fetch_reviews')
          }
        })
        .catch((err) => {
          _this.$log.debug(err)
          _this.$toast.alert(err)
        })
        .finally(() => {
          this.$store.commit('unloading')
        })
    },
    closeTarget(row) {
      if (row.target_type === 'INCIDENT') {
        this.closeIncident(row)
      } else if (row.target_type === 'GAP') {
        this.closeGap(row)
      } else if (row.target_type === 'EXEMPTION') {
        this.closeExemption(row)
      } else if (row.target_type === 'PROJECT') {
        this.openIspProjectModal(row)
      }
      this.$emit('fetch_reviews')
    },
    descriptionSuggest(row) {
      if (row.source === 'REMEDIATION') {
        if (row.target_type === 'INCIDENT') {
          return this._('Close the incident')
        } else if (row.target_type === 'GAP') {
          return this._('Close the gap')
        } else if (row.target_type === 'EXEMPTION') {
          return this._('Close the exemption')
        } else if (row.target_type === 'PROJECT') {
          return this._('Close the project')
        } else if (row.target_type === 'EVAL') {
          return this._('Update evaluation')
        } else if (row.target_type === 'BUILD') {
          return this._('Run the measure')
        } else if (row.target_type === 'UPGRADE') {
          return this._('Improve the measure efficiency')
        }
      } else if (row.source === 'MSDEFENDER') {
        if (row.target_type === 'GAP') {
          return this._('Close the gap')
        }
      } else if (row.source === 'USER') {
        return this._('Reassign the user objects')
      } else if (row.source === 'POLICY' || row.source === 'ACTION_MAPPING') {
        return this._('Complete mapping')
      } else if (row.source === 'CONNECTOR') {
        return this._('Configure connector')
      } else if (row.source === 'AUTH_CONFIG') {
        return this._('Configure SAML')
      }
    },
    // BUILD
    runSolution(row) {
      this.current_solution = { perimeter: row.remediation.perimeter }
      this.fork_from_object = row.solution
      this.$set(this.fork_from_object, 'remediation_id', row.remediation.id)
    },
    // UPGRADE
    getUpgradeLabel(row) {
      if (row.measure) {
        return (
          this._('Improve efficiency from') +
          ' ' +
          row.measure.efficiency +
          ' ' +
          this._('to') +
          ' ' +
          this.newEfficiency(row)
        )
      } else {
        return ''
      }
    },
    newEfficiency(row) {
      if (row.measure.efficiency >= 0 && row.remediation.current_efficiency >= 0) {
        let new_efficiency = row.measure.efficiency + row.remediation.current_efficiency
        if (new_efficiency > 100) {
          new_efficiency = 100
        }
        return new_efficiency
      }
    },
    upgradeMeasure(row) {
      this.$store.commit('loading')
      let new_efficiency = this.newEfficiency(row)
      if (new_efficiency < 0) {
        new_efficiency = 0
      }
      this.$http
        .put('measure/' + row.measure.id, { id: row.measure.id, efficiency: this.newEfficiency(row), review: row.id })
        .then((resp) => {
          if (resp.data.ok) {
            this.$store.commit('unloading')
            this.fetchData()
          } else {
            throw new Error()
          }
        })
        .catch((err) => {
          this.$store.commit('unloading')
          this.$log.error(err)
        })
    },
    // INCIDENT
    closeIncident(row) {
      this.$store.commit('loading')
      this.$http
        .put('incident/' + row.incident.id, { id: row.incident.id, status: 'CLOSED' })
        .then((resp) => {
          if (resp.data.ok) {
            this.$store.commit('unloading')
            this.$emit('close-target', { incident_id: row.incident.id })
            this.fetchData()
          } else {
            throw new Error()
          }
        })
        .catch((err) => {
          this.$store.commit('unloading')
          this.$log.error(err)
        })
    },
    // GAP
    closeGap(row) {
      this.$store.commit('loading')
      this.$http
        .put('gap/' + row.gap.id, { id: row.gap.id, status: 'CLOSE', resolution_status: 'RESOLVED' })
        .then((resp) => {
          if (resp.data.ok) {
            this.$store.commit('unloading')
            this.$emit('close-target', { gap_id: row.gap.id })
            this.fetchData()
          } else {
            throw new Error()
          }
        })
        .catch((err) => {
          this.$store.commit('unloading')
          this.$log.error(err)
        })
    },
    // EXEMPTION
    closeExemption(row) {
      this.$store.commit('loading')
      this.$http
        .put('exemption/' + row.exemption.id, { id: row.exemption.id, status: 'CLOSED' })
        .then((resp) => {
          if (resp.data.ok) {
            this.$store.commit('unloading')
            this.$emit('close-target', { exemption_id: row.exemption.id })
            this.fetchData()
          } else {
            throw new Error()
          }
        })
        .catch((err) => {
          this.$store.commit('unloading')
          this.$log.error(err)
        })
    },
    // ISP-PROJECT
    openIspProjectModal(row) {
      this.current_isp_project = row.isp_project
      this.current_date = this.$moment().format('DD/MM/YYYY')
      this.$bvModal.show('CloseIspReview')
    },
    closeIspProject(step) {
      const _this = this
      const new_step = {
        dev_step: step.identifier,
        target_date: step.end_date,
      }
      this.$store.commit('loading')
      this.$http
        .post('isp_project/' + this.current_isp_project.id + '/transition', new_step)
        .then((resp) => {
          if (resp.data.ok) {
            if (_this.current_isp_project && _this.current_isp_project.current_step) {
              _this.$emit('close-target', { isp_project_id: resp.data.object.id })
              this.current_isp_project = null
              _this.fetchData()
            }
            this.$bvModal.hide('CloseIspReview')
            this.$store.commit('unloading')
          } else {
            throw new Error(resp.data.message)
          }
        })
        .catch((e) => {
          this.$store.commit('unloading')
          this.$log.debug(e)
          this.$toast.warning(this._('Cannot go to next transition'))
        })
    },
    goToAuthConfig() {
      const route = {
        name: 'auth_config',
        params: {},
      }
      const routeData = this.$router.resolve(route)
      window.open(routeData.href, '_blank')
    },
    // EVALUATION
    goToEvaluation(row) {
      let params = { campaign_id: row.evaluation.campaign_id, perimeter_id: row.perimeter.id }
      if (row.evaluation.campaign.questionnaire_id) {
        params = {
          campaign_id: row.evaluation.campaign_id,
          perimeter_id: row.perimeter.id,
          control_id: 0,
          questionnaire_id: row.evaluation.campaign.questionnaire_id,
        }
      }
      const route = {
        name: 'campaign_eval',
        params: params,
      }
      const routeData = this.$router.resolve(route)
      window.open(routeData.href, '_blank')
    },
    // ARCHIVE USER
    goToReassignObject(row) {
      const route = {
        name: 'user_objects',
        params: {
          id: row.user.id,
        },
      }
      const routeData = this.$router.resolve(route)
      window.open(routeData.href, '_blank')
    },
  },

  mounted() {
    const _this = this

    if (this.$store.getters.isPilote || this.$store.getters.isContributor) {
      void getOptionsMixin('isp_project/dev_cycle', true, false).then(({ options }) => {
        _this.dev_cycle_options = options
        if (_this.dev_cycle_options) {
          _this.close_step = _this.dev_cycle_options[0].dev_steps.find((d) => d.identifier === 'CLOSE')
        }
      })
    }
  },
}
</script>
