import { _, lazyLoadView } from '@/router/routing'

export const standaloneRoutes = [
  {
    path: '/supplier-evaluation',
    name: 'supplier_evaluation',
    component: () => lazyLoadView(import('@/views/Suppliers/SuppliersEvaluationView.vue')),
    icon: 'users',

    meta: {
      title: _('Suppliers evaluation'),
      standalone_auth: true,
    },
  },
]
