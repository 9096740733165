import apiMethod from '@/api_call'

function initialState() {
  return {
    nav: {},
    display_types: {
      actionlog: 'gantt',
      actionlog_label: 'gantt',
      runtask_label: 'gantt',
      actions: 'list',
      exemption: 'list',
      perimeters: 'liquor-tree',
      risks: 'list',
      runbook: 'list',
      runtask: 'gantt',
      risk: 'matrix',
      myrisks: 'matrix',
      gmetrics_entry: 'table',
      glrunbook: 'gantt',
    },
    nb_contributions: {
      nb_evaluations: null,
      nb_actions: null,
      nb_runtasks: null,
      nb_projects: null,
      nb_exemptions: null,
      nb_risks: null,
      nb_incidents: null,
      nb_metrics: null,
    },
    tree_selected: {
      perimeters_scores: null,
      perimeters: null,
    },
  }
}

const routerStore = {
  state: initialState,
  mutations: {
    // Stock last child navigation for policies_questionnaires route
    nav(state, { parent_name, child_name }) {
      state.nav[parent_name] = child_name
    },
    display_types(state, { page_name, display_type }) {
      state.display_types[page_name] = display_type
    },
    nb_contributions(state, data) {
      state.nb_contributions = data.object
    },
    tree_selected(state, { page_name, tree }) {
      state.tree_selected[page_name] = tree
    },
  },
  actions: {
    SET_NAV_L2({ commit }, { parent_name, child_name }) {
      commit('nav', { parent_name, child_name })
    },
    SET_DISPLAY_TYPES({ commit }, { page_name, display_type }) {
      commit('display_types', { page_name, display_type })
    },
    SET_TREE_SELECTED({ commit }, { page_name, tree }) {
      commit('tree_selected', { page_name, tree })
    },
    UPDATE_NB_CONTRIBUTIONS({ commit }) {
      return apiMethod.get('user/nb_contributions', {}, false).then((resp) => {
        commit('nb_contributions', resp.data)
      })
    },
  },
  getters: {
    getNavL2: (state) => (parent_name) => state.nav[parent_name],
    getDisplayType: (state) => (page_name) => state.display_types[page_name],
    getNbContribution: (state) => (nb_contribution) => state.nb_contributions[nb_contribution],
    getNbContributions: (state) => state.nb_contributions,
    getTreeSelected: (state) => (page_name) => state.tree_selected[page_name],
  },
}

export default routerStore
