import {
  allRoles,
  hasOption,
  isAuthorized,
  lazyLoadView,
  piloteAndAuditor,
  requiresInternal,
  setNavL2,
} from '@/router/routing'
import type { Route } from 'vue-router'

import { i18n } from '@/I18n'

export const projectsSecurityRoutes = {
  path: '/projects',
  name: 'projects',
  component: () => lazyLoadView(import('@/contexts/projects-security/ProjectsSecurityListView.vue')),
  icon: 'hard-hat',
  meta: {
    title: i18n.t('projects_security.page.project_security'),
    is_authorized: (route: Route) => isAuthorized(route),
    is_optional: true,
    has_option: () => hasOption('projects'),
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    doc_id: 'app_projects',
    hidden_for_fp_assessment: true,
    feature_id: 'ISP',
    type: 'ISP',
  },
  children: [
    {
      path: ':id',
      name: 'project',
      component: () => lazyLoadView(import('@/contexts/projects-security/ProjectSecurityView.vue')),
      meta: {
        title: null,
        is_authorized: (route: Route) => isAuthorized(route),
        hidden_for_fp_assessment: true,
        is_optional: true,
        has_option: () => hasOption('projects'),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'app_project',
      },
      children: [
        {
          path: 'eval/:campaign_id',
          name: 'app_campaign_eval',
          component: () => lazyLoadView(import('@/components/pages/campaign-eval.vue')),
          meta: {
            title: null,
            requires_auth: true,
            is_authorized: (route: Route) => isAuthorized(route),
            hidden_for_fp_assessment: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'campaign_eval',
          },
        },
        {
          path: 'results/:campaign_id',
          name: 'app_campaign_details',
          component: () => lazyLoadView(import('@/components/pages/campaign-details.vue')),
          meta: {
            is_authorized: (route: Route) => isAuthorized(route),
            hidden_for_fp_assessment: true,
            title: null,
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'campaign_details',
          },
          children: [
            {
              path: ':perimeter_id/eval-details',
              name: 'campaign_eval_details_project',
              component: () => lazyLoadView(import('@/components/pages/campaign-eval.vue')),
              meta: {
                title: null,
                is_authorized: (route: Route) => isAuthorized(route),
                hidden_for_fp_assessment: true,
                requires_auth: true,
                requires_tenant: true,
                requires_role: allRoles,
                challenge_mode: true,
                doc_id: 'campaign_eval_details',
              },
            },
          ],
        },
      ],
    },
  ],
}

export const projectsSecurityContributionRoutes = {
  path: 'projects',
  name: 'my_projects',
  component: () => lazyLoadView(import('@/contexts/projects-security/ProjectsSecurityListView.vue')),
  beforeEnter: setNavL2,
  icon: 'hard-hat',
  meta: {
    title: i18n.t('projects_security.page.projects'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    is_optional: true,
    has_option: () => hasOption('projects'),
    requires_tenant: true,
    requires_role: allRoles,
    hidden_for_fp_assessment: true,
    requires_internal: () => requiresInternal(),
    doc_id: 'app_projects',
    feature_id: 'ISP',
    nb_contribution: 'nb_projects',
  },
  children: [
    {
      path: ':id',
      name: 'my_project',
      component: () => lazyLoadView(import('@/contexts/projects-security/ProjectSecurityView.vue')),
      meta: {
        title: null,
        is_authorized: (route: Route) => isAuthorized(route),
        hidden_for_fp_assessment: true,
        is_optional: true,
        has_option: () => hasOption('projects'),
        requires_auth: true,
        requires_tenant: true,
        requires_role: allRoles,
        doc_id: 'app_project',
      },
      children: [
        {
          path: 'eval/:campaign_id',
          name: 'my_campaign_eval',
          component: () => lazyLoadView(import('@/components/pages/campaign-eval.vue')),
          meta: {
            title: i18n.t('projects_security.page.evaluation'),
            is_authorized: (route: Route) => isAuthorized(route),
            hidden_for_fp_assessment: true,
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'campaign_eval',
          },
        },
        {
          path: 'results/:campaign_id',
          name: 'my_campaign_details',
          component: () => lazyLoadView(import('@/components/pages/campaign-details.vue')),
          meta: {
            title: null,
            is_authorized: (route: Route) => isAuthorized(route),
            hidden_for_fp_assessment: true,
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'campaign_details',
          },
          children: [
            {
              path: 'eval-details',
              name: 'campaign_eval_details_contrib',
              component: () => lazyLoadView(import('@/components/pages/campaign-eval.vue')),
              meta: {
                title: null,
                is_authorized: (route: Route) => isAuthorized(route),
                hidden_for_fp_assessment: true,
                requires_auth: true,
                requires_tenant: true,
                requires_role: allRoles,
                challenge_mode: true,
                doc_id: 'campaign_eval_details',
              },
            },
          ],
        },
      ],
    },
  ],
}
