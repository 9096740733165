<template>
  <aciso-form
    :panel_id="modal_id"
    :panel_title="modal_title"
    :read_only="current_operation === 'view'"
    :ref="modal_id"
    @cancel="cancelled"
    @submit="submit()"
    modal
  >
    <viewer
      v-if="policy_password"
      :value="policy_password"
    ></viewer>
    <form :data-vv-scope="scope">
      <form-field
        :error="errors.first(scope + '.password')"
        :label="_('Current password') + ' *'"
        name="password"
        type="password"
        v-model="current_obj.password"
        v-validate="{ required: true }"
        data-testid="change-pwd-current"
      ></form-field>

      <form-field
        :error="errors.first(scope + '.new_password')"
        :label="_('New password') + ' *'"
        name="new_password"
        ref="new_password"
        type="password"
        v-model="current_obj.new_password"
        v-validate="{ required: true, 'password-validator': true, max: 128 }"
        data-testid="new-pass"
      ></form-field>

      <form-field
        :error="errors.first(scope + '.new_password_confirm')"
        :label="_('Confirm new password') + ' *'"
        data-vv-as="new_password"
        name="new_password_confirm"
        type="password"
        v-model="current_obj.new_password_confirm"
        v-validate="'required|confirmed:new_password'"
        data-testid="new-pass-confirmation"
      ></form-field>
    </form>
  </aciso-form>
</template>

<script>
import FormField from '../ui/form-field.vue'
import EditMixin from '../mixins/edit-mixin'
import AcisoForm from '../layout/aciso-form.vue'
import { Viewer } from '@toast-ui/vue-editor'

export default {
  name: 'user-password',
  mixins: [EditMixin],
  data: function () {
    return {
      current_obj: {
        password: '',
        new_password: '',
        new_password_confirm: '',
        scope: 'form_user_password',
      },
      policy_password: '',
    }
  },
  computed: {
    modal_edit_title: function () {
      return this._('Change password')
    },
  },
  components: {
    AcisoForm,
    Viewer,
    FormField,
  },
  mounted() {
    this.registerValidator('password-validator', 'user/check_password')
    this.$http.get(this.aciso_config.base_url + '/user/password_policy').then((resp) => {
      if (resp.data.ok) {
        this.policy_password = resp.data.object
      } else {
        throw new Error()
      }
    })
  },
}
</script>
