export const fields_en = {
  access_code: 'access code',
  admin_email: 'administrator email',
  admin_firstname: 'administrator firstname',
  admin_lastname: 'administrator lastname',
  campaigns: 'campaigns',
  color: 'color',
  certificate: 'certificate',
  comments: 'comments',
  comments_en: 'comments',
  comments_fr: 'comments',
  consume_perimeters: 'perimeters',
  date_start: 'start date',
  description: 'description',
  description_en: 'description',
  description_fr: 'description',
  disp_type: 'display type',
  doer_name: "doer's name",
  done_date: 'date',
  duration: 'duration',
  email: 'email',
  effort: 'charge',
  end_date: 'end date',
  entity_id: 'entity id',
  eval_level_id: 'evaluation level',
  expires: 'expires',
  firstname: 'firstname',
  fork: 'fork',
  fork_p: 'fork policy',
  fork_v: 'fork version',
  formula: 'formula',
  high_value: 'high value',
  high_value_1: 'high value',
  high_value_2: 'high value',
  high_value_3: 'high value',
  high_value_4: 'high value',
  high_value_5: 'high value',
  high_value_6: 'high value',
  high_value_7: 'high value',
  high_value_8: 'high value',
  high_value_9: 'high value',
  initials: 'initials',
  identifier: 'identifier',
  indicators: 'indicators',
  indicator_scale: 'indicator scale',
  initial_cost: 'initial cost',
  invitation_en: 'invitation',
  invitation_fr: 'invitation',
  key_name: 'key name',
  lastname: 'lastname',
  limit_date_evaluation: 'evaluation deadline',
  limit_date_remediation: 'remediation deadline',
  level_scale: 'level',
  low_value: 'low value',
  low_value_0: 'low value',
  low_value_1: 'low value',
  low_value_2: 'low value',
  low_value_3: 'low value',
  low_value_4: 'low value',
  low_value_5: 'low value',
  low_value_6: 'low value',
  low_value_7: 'low value',
  low_value_8: 'low value',
  low_value_9: 'low value',
  mapped_measure: 'mapped measure',
  mapped_solution: 'mapped solution',
  message: 'message',
  name: 'name',
  name_en: 'name',
  name_fr: 'name',
  nature: 'nature',
  nature_type: 'type',
  nb_laptops: 'number of laptops',
  nb_servers_unix: 'number of unix server',
  nb_servers_windows: 'number of windows server',
  nb_smartphones: 'number of smartphones',
  nb_users: 'number of users',
  nb_workstations: 'number of workstations',
  new_password: 'new password',
  new_password_confirm: 'new password confirmation',
  password: 'password',
  password_confirm: 'password confirm',
  perimeter: 'perimeter',
  perimeters: 'perimeters',
  perimeter_tag: 'perimeter / grouping',
  periodicity: 'periodicity',
  policy: 'policy',
  priority: 'priority',
  project: 'project',
  references_en: 'reference',
  references_fr: 'reference',
  reminder_date: 'reminder date',
  remediations_number: 'number of remediations',
  scale: 'scale',
  solution: 'solution',
  start_date: 'start date',
  status: 'status',
  sso_url: 'SSO url',
  slo_url: 'SLO url',
  subtitle_en: 'subtitle',
  subtitle_fr: 'subtitle',
  target_group: 'target group',
  target_date: 'target date',
  target_level: 'target level',
  target_value: 'target value',
  title: 'title',
  measure: 'measure',
  title_en: 'title',
  title_fr: 'title',
  value: 'value',
  version: 'version',
  window: 'window',
  yearly_cost: 'yearly cost',
  weather_program: 'program',
  weather_project: 'project',
  nb_decimal: 'number of decimals',
  prefix_id_remediation: 'prefix',
  tweet_number: 'number of tweets',
  twitter_account: 'twitter account',
  tweet_last_days: 'number of days',
  execution_window: 'execution window',
  program: 'action plan',
  metadata_url: ' ',
}

export const fields_fr = {
  access_code: "code d'accès",
  admin_email: 'email administrateur',
  admin_firstname: 'prénom administrateur',
  admin_lastname: 'nom administrateur',
  campaigns: 'campagnes',
  color: 'couleur',
  certificate: 'certificat',
  comments: 'commentaires',
  comments_en: 'commentaires',
  comments_fr: 'commentaires',
  consume_perimeters: 'périmètres',
  date_start: 'date début',
  description: 'description',
  description_en: 'description',
  description_fr: 'description',
  disp_type: "type d'affichage",
  doer_name: 'responsable',
  done_date: 'date',
  duration: 'durée',
  email: 'email',
  effort: 'workload',
  end_date: 'date de fin',
  entity_id: 'id entité',
  eval_level_id: 'niveau de conformité',
  expires: 'expiration',
  firstname: 'prénom',
  fork: 'dériver',
  fork_p: 'dériver depuis politique',
  fork_v: 'dériver depuis version',
  formula: 'formule',
  high_value: 'valeur haute',
  high_value_1: 'valeur haute',
  high_value_2: 'valeur haute',
  high_value_3: 'valeur haute',
  high_value_4: 'valeur haute',
  high_value_5: 'valeur haute',
  high_value_6: 'valeur haute',
  high_value_7: 'valeur haute',
  high_value_8: 'valeur haute',
  high_value_9: 'valeur haute',
  identifier: 'identifiant',
  initials: 'initiales',
  indicators: 'indicateurs',
  indicator_scale: "échelle d'indicateur",
  initial_cost: 'coût initial',
  invitation_en: 'invitation',
  invitation_fr: 'invitation',
  key_name: 'nom de la clé',
  lastname: 'nom',
  limit_date_evaluation: 'date limite évaluation',
  limit_date_remediation: 'date limite remédiation',
  level_scale: 'niveau',
  low_value: 'valeur basse',
  low_value_0: 'valeur basse',
  low_value_1: 'valeur basse',
  low_value_2: 'valeur basse',
  low_value_3: 'valeur basse',
  low_value_4: 'valeur basse',
  low_value_5: 'valeur basse',
  low_value_6: 'valeur basse',
  low_value_7: 'valeur basse',
  low_value_8: 'valeur basse',
  low_value_9: 'valeur basse',
  mapped_measure: 'dispositif associé',
  measure: 'dispositif',
  mapped_solution: 'solution associée',
  message: 'message',
  name: 'nom',
  name_en: 'nom',
  name_fr: 'nom',
  nature: 'nature',
  nature_type: 'type',
  nb_laptops: 'nombre de PC portable',
  nb_servers_unix: 'nombre de serveurs unix',
  nb_servers_windows: 'nombre de serveurs windows',
  nb_smartphones: 'nombre de smarphones',
  nb_users: "nombre d'utilisateurs",
  nb_workstations: 'nombre de stations de travail',
  new_password: 'nouveau mot de passe',
  new_password_confirm: 'confirmation nouveau mot de passe',
  password: 'mot de passe',
  password_confirm: 'confirmation mot de passe',
  perimeter: 'périmètre',
  perimeters: 'périmètres',
  perimeter_tag: 'périmètre / groupement',
  periodicity: 'périodicité',
  policy: 'politique',
  priority: 'priorité',
  project: 'projet',
  references_en: 'référence',
  references_fr: 'référence',
  reminder_date: 'date du rappel',
  remediations_number: 'nombre de remédiations',
  scale: 'échelle',
  solution: 'solution',
  sso_url: 'url SSO',
  slo_url: 'url SLO',
  start_date: 'date de début',
  status: 'statut',
  subtitle_en: 'sous-titre',
  subtitle_fr: 'sous-titre',
  target_date: 'date cible',
  target_group: 'section cible',
  target_level: 'niveau cible',
  target_value: 'valeur cible',
  title: 'titre',
  title_en: 'titre',
  title_fr: 'titre',
  value: 'valeur',
  version: 'version',
  window: 'fenêtre',
  yearly_cost: 'coût annuel',
  weather_program: 'programme',
  weather_project: 'projet',
  nb_decimal: 'nombre de décimales',
  prefix_id_remediation: 'prefix',
  tweet_number: 'nombre de tweets',
  twitter_account: 'compte twitter',
  tweet_last_days: 'nombre de jours',
  execution_window: "fenêtre d'exécution",
  program: "registre d'action",
  metadata_url: ' ',
}
