<template>
  <div style="height: 100vh">
    <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
    <loading
      :active.sync="$store.getters.isLoading"
      :can-cancel="false"
      :height="32"
      :is-full-page="true"
      :width="32"
    ></loading>

    <!-- /.login-logo -->
    <b-row
      class="h-100"
      style="background: #f4f6f9"
    >
      <b-col
        md="12"
        lg="4"
        align-self="center"
      >
        <div class="login-div">
          <div class="login-logo mb-3">
            <img
              class="logo-login-img"
              :src="`${publicPath}static/img/Tenacy_couleur_borderless.svg`"
              alt="TENACY"
            />
          </div>

          <form
            :data-vv-scope="scope"
            v-on:submit.prevent.stop="$emit('dummy')"
          >
            <form-field
              :error="errors.first('form_forgot_password.email')"
              :label="_('Email')"
              name="email"
              no-check
              placeholder="john.doe@example.com"
              type="text"
              v-model="email"
              v-validate="'required|email'"
            ></form-field>
            <b-row>
              <b-col xs="12">
                <b-button
                  @click.stop="resetPassword"
                  class="w-100 login-button tenacy-btn ripple"
                  variant="primary"
                  :disabled="recaptcha_token === ''"
                >
                  <translate>Send reset instructions</translate>
                </b-button>
              </b-col>
            </b-row>
          </form>
          <b-row class="mt-3">
            <b-col class="text-center">
              <a
                @click="$router.push('/login')"
                href="#"
              >
                <translate>Sign in</translate>
              </a>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <auth-col-right class="hide-col"></auth-col-right>
    </b-row>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import FormField from '../ui/form-field.vue'
import UnauthPageMixin from '../mixins/unauth-page-mixin'
import KeyboardEvents from '../ui/keyboard-events.vue'
import AuthColRight from '../ui/auth-col-right.vue'
import { isLanguageFrench } from '@/helpers/Language.helpers'

export default {
  name: 'reset-password',
  data: function () {
    return {
      email: '',
      password: '',
      recaptcha_token: '',
      scope: 'form_forgot_password',
      publicPath: import.meta.env.BASE_URL,
    }
  },
  mixins: [UnauthPageMixin],
  components: {
    KeyboardEvents,
    FormField,
    Loading,
    AuthColRight,
  },
  methods: {
    resetPassword: function () {
      const _this = this
      const email = this.email
      const recaptcha_token = this.recaptcha_token
      this.$log.debug(recaptcha_token, email)
      this.$validator
        .validateAll('form_forgot_password')
        .then((res) => {
          if (res) {
            this.$store.commit('loading')
            this.$http
              .post(this.aciso_config.base_url + '/auth/password/forgot', {
                email: email,
                recaptcha_token: recaptcha_token,
              })
              .then((resp) => {
                if (resp.data.ok) {
                  _this.$store.commit('unloading')
                  _this.$router.push('/login')
                  _this.$toast.info(resp.data.message)
                } else {
                  throw new Error(resp.data.message)
                }
              })
              .catch((err) => {
                _this.$store.commit('unloading')
                _this.$log.debug(err)
                _this.$toast.info(err.message)
              })
          }
        })
        .catch((err) => {
          _this.$log.debug(err)
        })
    },
    keyboardEvent(e) {
      if (e.which === 13) {
        this.resetPassword()
      }
    },
  },
  mounted() {
    if (isLanguageFrench()) {
      this.$language.current = 'fr_FR'
    } else {
      this.$language.current = 'en_US'
    }
  },
}
</script>
