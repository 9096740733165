<template>
  <div>
    <b-row>
      <b-col cols="12">
        <vue-bootstrap4-table
          :actions="actions_"
          :classes="classes"
          :columns="columns_"
          :delete_icon="delete_icon"
          :filterInMounted="filterInMounted"
          :config="config_"
          :no-header="noHeader"
          :rows="rows"
          :clickable="clickable"
          :enableDrag="enableDrag"
          :smallTable="smallTable"
          :mappingEllipsis="mappingEllipsis"
          :totalRows="total_rows"
          :sticky_="sticky_"
          @clicked="clicked"
          @per_page_update="perPageUpdate"
          @delete-selected="trigger('delete-selected')"
          @on-add="trigger('on-add')"
          @on-edit="trigger('on-edit')"
          @on-toggle="toggle"
          @on-checkbox="checkbox"
          @on-add-wizard="trigger('on-add-wizard')"
          @on-enable="trigger('on-enable')"
          @on-all-select-rows="selected"
          @on-all-unselect-rows="selected"
          @on-change-query="onChangeQuery"
          @reorder="reorder"
          @on-clear="trigger('on-clear')"
          @on-show-modal="trigger('on-show-modal')"
          @on-return="trigger('on-return')"
          @on-export="trigger('on-export')"
          @on-export-pdf="trigger('on-export-pdf')"
          @on-export-admin="trigger('on-export-admin')"
          @on-export-assets="trigger('on-export-assets')"
          @on-filters-open="trigger('on-filters-open')"
          @on-filter="trigger('on-filter')"
          @on-import="trigger('on-import')"
          @on-import-zip="trigger('on-import-zip')"
          @on-import-xls="trigger('on-import-xls')"
          @on-report="trigger('on-report')"
          @on-import-assets="trigger('on-import-assets')"
          @on-refresh="refresh()"
          @on-show-warning="$emit('on-show-warning')"
          @on-recompute="$emit('on-recompute')"
          @on-remind="trigger('on-remind')"
          @on-save="trigger('on-save')"
          @on-sync="trigger('on-sync')"
          @on-select-row="selected"
          @on-unselect-row="unselected"
          @on-invite="trigger('on-invite')"
          @on-access="trigger('on-access')"
          @refresh-data="trigger('on-refresh')"
          @row_out="row_out"
          @row_over="row_over"
          @row_hover_out="row_hover_out"
          @row_hover="row_hover"
          @selected="$emit('selected', $event)"
          @left-selected="$emit('left-selected', $event)"
          ref="table"
          v-on:update:length="$emit('update:length', $event)"
        >
          <template
            v-if="!loading"
            slot="empty-results"
          >
            {{ custom_no_results ? custom_no_results : _('No data') }}
          </template>
          <template slot="sort-asc-icon"> <span class="sort">&#x2191;</span><span class="no-sort"></span> </template>
          <template slot="sort-desc-icon"> <span class="no-sort"></span><span class="sort">&#x2193;</span> </template>
          <template slot="no-sort-icon">
            <span class="no-sort">&#x2191;</span>
          </template>
          <template slot="refresh-button-text">
            <i class="far fa-refresh"></i>
          </template>
          <template slot="reset-button-text">
            <translate>Reset filters</translate>
          </template>
          <template
            v-if="delete_icon"
            slot="multi_edit"
          >
            <icon-action
              @click="$emit('delete-selected')"
              class="mt-2 fix-trash"
              color="danger"
              icon="trash"
            >
            </icon-action>
          </template>
          <template
            v-if="loading"
            slot="spin-loading"
          >
            <div
              class="text-center"
              style="width: 100px; height: 100px; display: block; margin: auto"
            >
              <i
                class="far text-primary text-center fa-circle-notch fa-2x fa-spin"
                style="display: block; margin: auto"
              ></i>
            </div>
          </template>

          <!-- Pass on all named slots -->
          <slot
            :name="slot"
            :slot="slot"
            v-for="slot in Object.keys($slots)"
          />

          <!-- Pass on all scoped slots -->
          <template
            :slot="slot"
            slot-scope="scope"
            v-for="slot in Object.keys($scopedSlots)"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </vue-bootstrap4-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueBootstrap4Table from '../ui/fork-bootstrap4-table/src/components/VueBootstrap4Table.vue'
import IconAction from '../ui/icon-action.vue'

export default {
  name: 'aciso-table',
  props: {
    rows: Array,
    enableDrag: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    filterInMounted: {
      type: Boolean,
      default: true,
    },
    total_rows: Number,
    config: Object,
    classes: Object,
    actions: Array,
    smallTable: {
      type: Boolean,
      default: false,
    },
    delete_icon: {
      type: Boolean,
      default: false,
    },
    mappingEllipsis: {
      type: Boolean,
      default: false,
    },
    custom_no_results: {
      type: String,
      default: null,
    },
    filter_btn: Object,
    columns: Array,
    'no-header': {
      type: Boolean,
      default: false,
    },
    sticky_: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      hover_item: null,
      do_show_refresh: false,
      total_new_rows: 0,
      selected_items: [],
    }
  },
  computed: {
    columns_: function () {
      const res = []
      this.columns.forEach((c) => {
        const c2 = this.copyNestedObjects(c)
        if (!c2.row_text_alignment) {
          c2.row_text_alignment = 'text-left'
        }
        if (!c2.column_text_alignment) {
          c2.column_text_alignment = 'text-left'
        }
        res.push(c2)
      })
      return res
    },
    config_() {
      const _this = this
      const config_ = this.copyNestedObjects(this.config)
      if (config_.rows_selectable) {
        config_.highlight_row_hover_color = '#2C526308'
      }
      if (config_.show_refresh_button) {
        config_.show_refresh_button = false
        _this.do_show_refresh = true
      }

      return config_
    },
    actions_() {
      let actions_ = this.copyNestedObjects(this.actions)
      if (!actions_) {
        actions_ = []
      }
      if (this.do_show_refresh) {
        if (this.filter_btn) {
          actions_.push(this.filter_btn)
        }
      }
      return actions_
    },
  },
  components: {
    IconAction,
    VueBootstrap4Table,
  },
  mounted: function () {
    this.$refs.table.$refs.global_search = { value: '' }
  },
  methods: {
    setSelectedRow(row) {
      this.$refs.table.setSelectedRow(row)
    },
    perPageUpdate(per_page) {
      this.$log.debug('PER PAGE UPDATE !!!', per_page)
      this.$store.commit('per_page', per_page)
    },
    onChangeQuery: function (q) {
      this.$emit('changeQuery', q)
    },
    trigger: function (event_name) {
      this.$emit(event_name)
    },
    selected: function (obj) {
      this.selected_items = obj.selected_items
      this.$emit('selected', obj.selected_items)
    },
    reorder: function (obj) {
      this.$emit('reorder', obj)
    },
    toggle: function (obj) {
      this.$emit(obj.event_name, obj.value)
    },
    checkbox: function (obj) {
      this.$log.debug('EVENT1', obj)
      this.$emit(obj.event_name, obj.value)
    },
    clicked: function (obj) {
      this.$emit('clicked', obj.id, obj.type)
      this.$emit('clicked_row', obj)
      this.$emit('clicked_simple', obj)
    },
    row_out: function (obj) {
      this.$emit('row_out', obj.id)
    },
    row_over: function (obj) {
      this.$emit('row_over', obj.id)
    },
    row_hover_out: function () {
      this.$emit('row_hover_out')
    },
    row_hover: function (obj) {
      this.$emit('row_hover', obj.order)
    },
    unselected: function (obj) {
      this.selected_items = obj.selected_items
      this.$emit('selected', obj.selected_items)
    },
    refresh() {
      this.$refs.table.allRowsSelected = false
      this.$refs.table.unSelectAllItems()
      this.$refs.table.selected_items = []
      this.selected_items = []
      this.$emit('selected', [])
      this.trigger('on-refresh')
    },
  },
}
</script>

<style lang="scss" scoped>
.list-group-item {
  cursor: pointer;
}

.list-group-item-draggable i {
  cursor: pointer;
}

.list-group-item-draggable {
  cursor: move;
}

.list-group-item-draggable i {
  cursor: pointer;
}

.icon-todo-remove {
  color: grey;
}

.list-group-item-draggable {
  cursor: move;
}

.list-group-item-draggable i {
  cursor: pointer;
}

.hover-todo:hover {
  background-color: #f5f5f5;
}
</style>
