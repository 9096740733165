import Vue from 'vue'

import moment from 'moment'
import { truncate } from '@/helpers/text_helper'

Vue.filter('upper', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.toUpperCase()
})

Vue.filter('capitalize', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('truncate', truncate)

Vue.filter('datetime', function (value) {
  if (value) {
    return moment(String(value)).format('LLL')
  }
})

Vue.filter('date', function (value) {
  if (value) {
    return moment(value, 'DD/MM/YYYY').format('L')
  }
})

Vue.filter('date_large', function (value) {
  if (value) {
    return moment(value, 'DD/MM/YYYY').format('LL')
  }
})

Vue.filter('datetime_small', function (value) {
  if (value) {
    return moment(value, 'DD/MM/YYYY HH:mm:ss').local().format('L LT')
  } else {
    return '-'
  }
})

Vue.filter('local_datetime', function (value) {
  if (value) {
    return moment.utc(value, 'DD/MM/YYYY HH:mm:ss').local().format('L LT')
  } else {
    return '-'
  }
})

Vue.filter('local_datetime_date', function (value) {
  if (value) {
    return moment.utc(value, 'DD/MM/YYYY HH:mm:ss').local().format('L')
  } else {
    return '-'
  }
})

Vue.filter('local_datetime_large', function (value) {
  if (value) {
    return moment.utc(value, 'DD/MM/YYYY HH:mm:ss').local().format('LLL')
  } else {
    return '-'
  }
})

function formatLargeNumber(value, sp = true) {
  let newVar = value.toString()
  const value_length = newVar.length
  if (value_length >= 4 && value_length <= 6) {
    newVar = (value / 1000).toFixed(0) + (sp ? ' ' : '') + 'k'
  } else if (value_length > 6) {
    newVar = (value / 1000000).toFixed(1) + (sp ? ' ' : '') + 'M'
  } else if (value_length > 9) {
    newVar = (value / 1000000000).toFixed(1) + (sp ? ' ' : '') + 'G'
  } else {
    newVar += ' '
  }
  return newVar
}

Vue.filter('currency', function (value) {
  if (value) {
    return formatLargeNumber(value) + '€'
  } else {
    return ''
  }
})

Vue.filter('large_amount', function (value) {
  if (value != null && typeof value != 'undefined') {
    return formatLargeNumber(value, false)
  } else {
    return ''
  }
})
