import Vue, { nextTick } from 'vue'
import Router from 'vue-router'
import store from '@/store'
import axios from 'axios'
import { config } from '@/config.settings'
import { api_cache } from '@/api_call'
import { routes } from '@/router/routes'
import { redirectHome } from '@/router/routing'

Vue.use(Router)

export const router = new Router(routes)

router.beforeEach(async (to, from, next) => {
  api_cache.clear()

  document.title = `Tenacy`
  // if route has no name, redirect to root "/"
  if (to.name == null) {
    next('/')
  }
  // if the route manages its own authentication process
  if (to.meta.standalone_auth === true) {
    return next()
  }

  // need to be authenticated
  if (to.matched.some((record) => record.meta.requires_auth)) {
    // if route requires auth but user is not logged in
    if (!store.getters.isLoggedIn) {
      // feature for cypress auto test only, if cypress logged in from api.
      if (config.dev || config.is_test) {
        await axios({
          url: config.base_url + '/auth/check',
          method: 'GET',
          headers: { 'x-page': window.location.pathname },
        })
          .then((resp) => {
            if (!resp.data.ok || resp.data.ok !== true) {
              store.commit('auth_error')
              store.commit('init_user')
              next('/login')
            } else {
              store.commit('auth_success', {})
              store.commit('set_user', resp.data)
              store.commit('newsdyk', resp.data)
              void store.dispatch('loadGroupsUsers')
              void store.dispatch('updateNbReviews')
            }
          })
          .catch(() => {
            store.commit('auth_error')
            store.commit('init_user')
            next('/login')
          })
      } else {
        next('/login')
      }
    }
    // if route requires auth and user is logged in
    if (store.getters.isLoggedIn) {
      if (to.meta.title === null) {
        await store.dispatch('setMainTitle', { route: to.name, title: '' })
      }
      if (to.meta.dummy === true) {
        // if dummy route is multi, find nav2 in store, or next to first child.
        if (to.meta.multi === true) {
          // find nav2 in store
          let child_name = store.getters['getNavL2'](to.name)
          const children = router.getRoutes().filter((route) => route.parent && route.parent.name === to.name)
          const navl2 = children.find((route) => route.name === child_name)
          if (
            child_name == null ||
            !navl2 ||
            (child_name != null && navl2 && navl2.meta && !navl2.meta.is_authorized(navl2))
          ) {
            // find first authorized child
            child_name = null
            children.forEach((child) => {
              // if user switch between different role / context / None etc .... authorization could change. (test case : go on None to technical solution, return to a context and navigate to catalog)
              if (child_name == null && child.name && child.meta.is_authorized(child)) {
                child_name = child.name
              }
            })
          }
          if (child_name != null) {
            void router.push({ name: child_name })
          } else {
            next(redirectHome())
          }
        } else {
          next(redirectHome())
        }
      }
      // Route is not dummy, and need to be authenticated. Check authorization before next().
      else {
        if (to.meta.is_authorized(to) === false) {
          next(redirectHome())
        }
        next()
      }
    } else {
      next('/login')
    }
  }
  // don't need to be authenticated
  else {
    if (store.getters.isLoggedIn) {
      // user is logged in and want to access to shared dashboard.
      if (to.matched.some((record) => record.name === 'dashboard_share')) {
        next()
      }
      // if route is not "dashboard_share" and isShare is true (dashboard is share to you, and you have valid token in url)
      else if (store.getters.isShare) {
        store.commit('auth_error')
        store.commit('init_user')
        next('/login')
      }
      // if user is logged in and want to access to requires_auth=false route, redirect to /home
      else {
        next(redirectHome())
      }
    }
    // access is authorised if user is not logged in and want to access requires_auth=false route
    next()
  }
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    nextTick(() => {
      Vue.prototype.$posthog.capture('$pageview', { path: to.fullPath })
    })
  }
  Vue.prototype.$http.defaults.headers.common['x-page'] = window.location.pathname.replaceAll(/\/[0-9]+/g, '/<id>')
})

export default router
