<template>
  <th
    class="text-center justify-content-center"
    :class="{ 'bg-light': rows_selected > 0 }"
  >
    <p-check
      @change="cb_change"
      class="p-icon"
      color="primary"
      :indeterminate="showIndeterminateState"
      v-model="allRowsSelected_"
    >
      <i
        class="icon far fa-check"
        slot="extra"
      ></i>
      <i
        class="icon far fa-minus text-primary"
        slot="indeterminate-extra"
      ></i>
    </p-check>
  </th>
</template>

<script>
export default {
  name: 'SelectAllRowsCheckBox',
  props: {
    allRowsSelected: {
      type: Boolean,
      default: false,
    },
    currentPageSelectionCount: {
      type: Number,
      default: 0,
    },
    rows_selected: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data: function () {
    return {
      allRowsSelected_: false,
    }
  },
  computed: {
    showIndeterminateState() {
      return !this.allRowsSelected && this.currentPageSelectionCount > 0
    },
  },
  methods: {
    cb_change() {
      this.$emit('select-all-row-checkbox')
      this.allRowsSelected_ = false
    },
  },
  mounted() {
    this.allRowsSelected_ = this.allRowsSelected
  },
  watch: {
    allRowsSelected(val) {
      this.allRowsSelected_ = val
    },
  },
}
</script>

<style scoped>
.custom-control-label {
  vertical-align: top;
}
</style>
