<template>
  <div
    class="extension-panel-wrapper"
    :id="panelid"
  >
    <div
      class="extension-panel-overlay"
      @click="$emit('cancel')"
    />

    <overlay-scrollbars
      :options="{ scrollbars: { autoHide: 'scroll', autoHideDelay: 400 } }"
      :class="{
        'extension-panel-lg': size === 'xl',
        'extension-panel-s': size === 's',
      }"
      class="extension-panel"
      ref="os"
    >
      <keyboard-events v-on:keyup="escDown"></keyboard-events>

      <button
        @click="$emit('cancel')"
        aria-label="Close"
        class="close"
        type="button"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <h4 v-if="title">
        {{ title }}
        <hr />
      </h4>
      <div class="padding-bot-panel">
        <slot></slot>
      </div>
      <div
        :class="{ 'panel-save-container-lg': size === 'xl' }"
        class="h-25 w-100 panel-save-container shadow-lg"
        v-if="show_save"
      >
        <icon-action
          :tooltip="_('Back')"
          @click="$emit('rewind-form')"
          class="ml-2 mt-5"
          color="primary"
          v-if="showRewind"
        >
          <i class="fal fa-long-arrow-left fa-3x"></i>
        </icon-action>
        <b-button
          @click="handleSubmit"
          class="pull-right btn-save-panel do-save"
          v-if="show_save && !ro && hide_buttons === false"
          variant="outline-primary"
          data-testid="save-button"
        >
          <b-spinner
            v-if="is_save_loading"
            small
          />
          <template v-else>{{ okTitle }}</template>
        </b-button>

        <b-button
          @click="$emit('cancel')"
          class="pull-right btn-save-panel"
          v-if="!ro && hide_buttons === false"
          variant="light"
          data-testid="cancel-button"
          >{{ cancelTitle }}
        </b-button>
        <slot name="footer"></slot>
      </div>
    </overlay-scrollbars>
  </div>
</template>

<script>
import IconAction from './icon-action.vue'
import KeyboardEvents from './keyboard-events.vue'

export default {
  name: 'extension-panel',
  props: {
    panelid: {
      type: String,
      required: true,
    },
    panelZindex: Number,
    ro: {
      type: Boolean,
      default: false,
    },
    title: String,
    size: String,
    'ok-disabled': Boolean,
    'ok-title': {
      type: String,
    },
    'cancel-disabled': Boolean,
    'cancel-title': {
      type: String,
    },
    show_save: {
      type: Boolean,
      default: true,
    },
    is_save_loading: {
      type: Boolean,
      default: false,
    },
    hide_buttons: {
      type: Boolean,
      default: false,
    },
    showRewind: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openAlert: false,
      open: false,
      data: [],
    }
  },
  mounted() {
    const _this = this
    this.$root.$on('panel:reopen', (panel_id) => {
      if (_this.panelid === panel_id) {
        _this.show()
      }
    })
  },
  components: { KeyboardEvents, IconAction },
  methods: {
    show() {
      const _this = this
      if (this.$refs.os && this.panelid === 'help') {
        this.$refs.os.osInstance().scroll({ y: 0 }, 1000)
      }
      const panel = document.getElementById(this.panelid)
      if (!panel.classList.contains('active')) {
        panel.classList.add('active')
        _this.escDown(panel)
      }
      this.$root.$emit('panel:open')
    },
    hide() {
      const panel = document.getElementById(this.panelid)
      panel.classList.remove('active')
    },
    handleScroll(event) {
      const _this = this
      if (!event || !event.path || event.path.length < 1) {
        return
      }
      if (event.path[1].scrollY > 0) {
        $('#' + _this.panelid).addClass('extension-after-scroll')
      } else if (event.path[1].scrollY < 55) {
        $('#' + _this.panelid).removeClass('extension-after-scroll')
      }
      if ($(window).scrollTop() + $(window).height() === $(document).height()) {
        $('#' + _this.panelid).addClass('extension-at-bottom')
      } else {
        $('#' + _this.panelid).removeClass('extension-at-bottom')
      }
    },
    handleSubmit() {
      if (!this.is_save_loading) {
        this.$emit('submit')
      }
    },
    escDown: function (e) {
      const _this = this
      if (e.code === 'Escape') {
        _this.$emit('cancel')
      }
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped>
.extension-panel-overlay {
  position: fixed;
  height: 0;
  width: 100vw;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 0.4s;
  opacity: 0;
  z-index: 2000;
}

.extension-panel {
  position: fixed;
  top: 0px;
  width: 800px;
  height: 100vh;
  background: #f8f9fa;
  box-shadow: -3px 1px 7px 0px #80808075;
  color: black;
  overflow: auto;
  padding: 1em;
  right: -880px;
  z-index: 2001;
  -webkit-transition: height 0s, top 0.1s, right 0.4s; /* For Safari 3.1 to 6.0 */
  transition: height 0s, top 0.1s, right 0.4s;
}

.extension-panel-lg {
  width: 850px;
  right: -870px;
}

.extension-panel-s {
  width: 430px;
}

.extension-after-scroll .extension-panel {
  top: 0px !important;
}

.extension-at-bottom .extension-panel {
  height: 94.5% !important;
}

.extension-panel-wrapper.active {
  & > .extension-panel-overlay {
    opacity: 1;
    height: 100vh;
  }
  & > .extension-panel {
    right: 0;
  }
}

.padding-bot-panel {
  padding-bottom: 6rem;
}
</style>
