<template>
  <div
    :class="
      Object.assign(
        {
          'h-80': rows === 0,
          'mb-0': rows === 0 || no_mb,
          'no-input': noInput,
        },
        fgClasses
      )
    "
    class="form-group"
    v-if="show_field"
  >
    <label
      :class="{ 'mb-0': !disabled && error }"
      v-if="label"
      :style="{ 'font-weight': font_bold ? 'bold' : 'normal' }"
      >{{ label_ }} <span v-if="unit === 'k€' && false">{{ value | thousandSep }} k€</span></label
    >
    <slot name="icon_action"></slot>
    <b-badge
      :title="help"
      class="pull-right mt-1 mb-1"
      v-b-tooltip
      v-if="help && help.length > 0"
      variant="default"
    >
      <i
        class="far fa-info-circle ml-1 fa-lg"
        style="color: #61a0ff"
      ></i>
    </b-badge>
    <b-badge
      :title="_('You can use markdown in this field')"
      class="pull-right bg-gray"
      v-b-tooltip
      v-if="markdown && !disabled && !enable_markdow_editor"
      variant="default"
    >
      <i class="far fa-font"></i>
    </b-badge>
    <div
      class="error-container"
      v-if="(!disabled && error) || (show_error_disabled && error)"
    >
      <span
        class="invalid-feedback"
        v-if="!custom_error_message"
      >
        {{ error }}
      </span>
      <span
        class="invalid-feedback"
        v-else
      >
        {{ custom_error_message }}
      </span>
    </div>
    <template v-if="!noInput">
      <div
        class="input-group"
        v-if="rows === undefined && !disabled && !show_input"
      >
        <input
          :aria-placeholder="placeholder"
          :autocomplete="autocomplete"
          :class="{
            'is-invalid': !!error && touched && !noCheck && !noValidateCls,
            'is-valid': !error && !disabled && touched && !noCheck && !noValidateCls,
          }"
          :data-hidden="datahidden"
          :min="min"
          :name="name"
          ref="input_field"
          :placeholder="!disabled ? placeholder : ''"
          :type="type"
          @blur="blured"
          v-on="!stopEvent ? { change: changed } : {}"
          @keydown="type === 'number' ? onlyNumber($event, float) : null"
          @keyup="changed"
          @keyup.enter.prevent="$emit('enter')"
          @keyup.esc="$emit('esc')"
          class="form-control"
          v-model="value_"
        />
        <div
          class="input-group-append"
          v-if="unit"
        >
          <span class="input-group-text">{{ unit }}</span>
        </div>
        <div
          class="input-group-append"
          v-if="has_reset && value_ && value_.length > 0"
          @click="$emit('reset')"
        >
          <span class="input-group-text custom-background"
            ><i
              class="far fa-times-circle"
              style="color: #ff0000"
            ></i
          ></span>
        </div>
        <div
          class="input-group-append"
          v-if="show_password"
          @click="$emit('toggle_visibility')"
        >
          <span class="input-group-text">
            <i
              class="fa fa-eye"
              v-if="type === 'password'"
            ></i>
            <i
              class="fa fa-eye-slash"
              v-else
            ></i>
          </span>
        </div>
        <div
          class="input-group-append"
          v-if="type === 'url'"
          @click="openNewTab()"
        >
          <span
            class="input-group-text"
            :title="_('Go to')"
          >
            <i class="fa fa-external-link"></i>
          </span>
        </div>
      </div>
      <div
        :style="{ 'min-height': '40px' }"
        v-else-if="show_input"
      >
        <input
          :aria-placeholder="placeholder"
          :autocomplete="autocomplete"
          :class="{
            'is-invalid': !!error && touched && !noCheck && !noValidateCls,
            'is-valid': !error && !disabled && touched && !noCheck && !noValidateCls,
          }"
          :data-hidden="datahidden"
          :min="min"
          :name="name"
          ref="input_field"
          :disabled="disabled"
          :placeholder="!disabled ? placeholder : ''"
          :type="type"
          @blur="blured"
          v-on="!stopEvent ? { change: changed } : {}"
          @keydown="type === 'number' ? onlyNumber($event, float) : null"
          @keyup="changed"
          @keyup.enter.prevent="$emit('enter')"
          @keyup.esc="$emit('esc')"
          class="form-control"
          v-model="value_"
        />
      </div>
      <div
        :style="{ 'min-height': '40px' }"
        v-else-if="rows === undefined && !show_input"
      >
        <span v-if="not_set && not_set_label"
          ><i> {{ not_set_label }}</i></span
        >
        <span
          v-else-if="not_set"
          class="text-muted"
        >
          <i>{{ _('Not set') }}</i>
        </span>
        <a
          v-else-if="type === 'url'"
          :href="value_"
          target="_blank"
        >
          {{ value_ | truncate(50) }}
        </a>
        <span v-else>
          {{ value_ }}
        </span>
        <span v-if="unit && !not_set">
          <span>{{ unit }}</span>
        </span>
      </div>

      <editor
        :height="editorHeight"
        :options="editorOptions"
        :style="{ 'min-height': editorHeight2 }"
        @blur="bluredMarkdown"
        @change="editorChanged"
        mode="wysiwyg"
        previewStyle="tab"
        ref="editor"
        v-if="rows !== undefined && !disabled && markdown && enable_markdow_editor"
        v-model="value_"
      ></editor>

      <textarea
        :aria-placeholder="placeholder"
        :class="{
          'is-invalid': !!error && touched && !noValidateCls,
          'is-valid': !error && !disabled && touched && !noValidateCls,
          'h-100': rows === 0,
        }"
        :disabled="disabled"
        :name="name"
        :placeholder="!disabled ? placeholder : ''"
        :rows="rows"
        @blur="blured"
        @change="changed"
        @keyup="changed"
        class="form-control"
        v-if="rows !== undefined && !disabled && (!markdown || !enable_markdow_editor)"
        v-model="value_"
      ></textarea>
      <div v-if="rows !== undefined && disabled && markdown && value">
        <viewer-sanitize
          :sanitize="sanitize"
          :value="value"
        />
      </div>
      <div
        :style="{ 'min-height': '40px' }"
        v-if="rows !== undefined && disabled && !markdown && value"
      >
        {{ value_ }}
      </div>
      <div
        :style="{ 'min-height': '40px' }"
        class="text-muted"
        v-else-if="rows !== undefined && disabled && not_set"
      >
        <span v-if="not_set_label"
          ><i> {{ not_set_label }}</i></span
        >
        <span v-else
          ><i>{{ _('Not set') }}</i></span
        >
      </div>
    </template>
    <template v-else>
      <input
        :name="name"
        :value="value"
        type="hidden"
      />
    </template>
  </div>
</template>

<script>
import ViewerSanitize from '@/components/ui/viewer-sanitize.vue'
import Editor from '@toast-ui/vue-editor/src/Editor.vue'
import { onlyNumber } from '@/helpers/keyboard-helper'

/**
 * This component is used to render an input field, supporting multiple options to edit a single line, multiple line,
 * number, markdown, etc.
 * It emits the `input` event so it is compatible with v-model
 */
export default {
  name: 'form-field',
  props: {
    font_bold: {
      type: Boolean,
      default: true,
    },
    show_input: {
      type: Boolean,
      default: false,
    },
    show_error_disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * This is the name of the field, it should be unique within the form
     */
    name: String,
    /**
     * This is the label of the field
     */
    label: String,
    /**
     * This is the label of the unseted disabled field (optional)
     */
    not_set_label: {
      type: String,
      default: null,
    },
    /**
     * Flag. If true, no margin bottom will be added to the field
     */
    no_mb: Boolean,
    /**
     * This classes will be added to the form-group element
     */
    fgClasses: {
      type: Object,
      default: () => {},
    },
    /**
     * This will be send to the input element
     */
    autocomplete: {
      type: [Boolean, String],
      default: 'false',
    },
    autofocus: {
      type: [Boolean, String],
      default: 'false',
    },
    /**
     * If true, a reset event will be emitted on click on the input-group-append element
     */
    has_reset: {
      type: Boolean,
      default: false,
    },
    stopEvent: {
      type: Boolean,
      default: false,
    },
    /**
     * Forwarded to the input element
     */
    datahidden: {
      type: Boolean,
      default: false,
    },
    /**
     * Forwarded to the input element
     */
    hide_dual_mode: {
      type: Boolean,
      default: true,
    },
    sanitize: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: [String, Number],
    },
    /**
     * If defined, this will show a question mark on the right, with given text as tooltip
     */
    help: String,
    /**
     * flag to indicate that no validation should be done on the field
     */
    'no-validate-cls': {
      type: Boolean,
      default: false,
    },
    /**
     * flag to indicate that no validation should be done on the field
     */
    'no-check': {
      type: Boolean,
      default: false,
    },
    /**
     * flag to indicate that the content is markdown
     */
    markdown: {
      type: Boolean,
      default: false,
    },
    /**
     * Overriddes the v-validate error message
     */
    custom_error_message: {
      type: String,
    },
    /**
     * If true, the field is hidden
     */
    'no-input': {
      type: Boolean,
      default: false,
    },
    /**
     * If true, the field is disabled (non editable)
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    show_password: {
      type: Boolean,
      default: false,
    },
    /**
     * The error string (coming from v-validate in most cases)
     */
    error: {
      type: String,
      required: false,
    },
    /**
     * A input-group-append will be added with the content of this props
     */
    unit: {
      type: String,
      required: false,
    },
    /**
     * Forwarded to the input element
     */
    min: {
      type: Number,
      default: 0,
      required: false,
    },
    /**
     * The type of input
     * @values url, text, password, email, search, number
     */
    type: {
      type: String,
      default: 'text',
      validator: (val) => {
        return ['url', 'text', 'password', 'email', 'search', 'number'].indexOf(val) !== -1
      },
    },
    float: {
      type: Boolean,
      default: false,
      required: false,
    },
    rows: [Number, String],
    value: {
      type: [String, Number],
    },
    toolbar_items: {
      type: Array,
      default: () => [
        'heading',
        'bold',
        'italic',
        'divider',
        'hr',
        'quote',
        'divider',
        'ul',
        // 'ol',
        // 'task',
        // 'indent',
        // 'outdent',
        // 'divider',
        // 'table',
        'image',
        'link',
        // 'divider',
        // 'code',
        // 'codeblock'
      ],
    },
  },
  data: function () {
    return {
      touched: false,
      value_: null,
      enable_markdow_editor: true,
      editorOptions: {
        usageStatistics: false,
        language: this.$language.current,
        hideModeSwitch: this.hide_dual_mode,
        useDefaultHTMLSanitizer: this.sanitize,
        toolbarItems: this.toolbar_items,
      },
    }
  },
  computed: {
    show_field: function () {
      return true
    },
    editorHeight: function () {
      if (+this.rows) {
        return +this.rows * 25 + 'px'
      } else {
        return 3 * 25 + 'px'
      }
    },
    editorHeight2: function () {
      if (+this.rows) {
        return (+this.rows + 2) * 25 + 'px'
      } else {
        return 5 * 25 + 'px'
      }
    },
    label_: function () {
      if (this.disabled) {
        return this.label.replace('*', '')
      }
      return this.label
    },
    not_set: function () {
      return this.disabled && (this.value === '' || this.value == null || typeof this.value == 'undefined')
    },
  },
  methods: {
    onlyNumber,
    bluredMarkdown() {
      this.blured()
      this.editorChanged()
    },
    openNewTab() {
      if (this.value_) {
        window.open(this.value_, '_blank')
      }
    },
    focus() {
      if (this.$refs.input_field) {
        this.$nextTick(() => {
          this.$refs.input_field.focus()
        })
      } else {
        this.$log.debug('INPUT FIELD NOT PRESENT')
      }
    },
    changed: function (e) {
      this.touched = true
      /**
       * Classical input event, which allows v-modal to be used
       */
      this.$emit('input', e.target.value)
    },
    triggerCheckValue() {
      this.$emit('check-value', this.value_)
    },
    editorChanged: function () {
      const markdown_source = this.$refs['editor'].invoke('getMarkdown')
      const value_ = this.value || ''
      if (markdown_source.replace('\\', '') !== value_.replace('\\', '')) {
        this.touched = true
        this.$emit('input', markdown_source)
      }
    },
    blured: function () {
      /**
       * Forward blur event
       */
      this.$emit('blur')
      /**
       * This event is sent to the root, so that the parent component can known that the field was edited
       */
      this.$root.$emit('data:edited')
    },
    reset: function () {
      this.touched = false
    },
    getHtml() {
      return this.$refs.editor.invoke('getHtml')
    },
  },
  mounted() {
    this.value_ = this.value
    const _this = this
    this.$root.$on('data:submit', () => {
      _this.touched = false
    })
  },
  filters: {
    /**
     * Filter for displaying number with space/dot separator
     * @public
     */
    thousandSep(value) {
      if (value) {
        return `$${value.toLocaleString()}`
      } else {
        return ''
      }
    },
  },
  components: {
    ViewerSanitize,
    Editor,
  },
  watch: {
    value: function (val) {
      this.value_ = val
    },
  },
}
</script>

<style scoped>
.fieldOuter {
  width: 100%;
}

.fieldOuter label {
  position: absolute;
  left: 12px;
  top: 10px;
  line-height: 15px;
  transition: all 0.1s;
  overflow: hidden;
  font-weight: 300;
  font-size: 14px;
  color: #222;
  white-space: nowrap;
  z-index: 20;
  opacity: 0;
  outline: none;
  pointer-events: none;
}

.fieldOuter input:focus + label {
  opacity: 1;
  top: 1px;
}

.fieldOuter input:focus {
  outline: none;
  border-color: rgba(82, 168, 236, 0.8);
  padding-top: 15px;
}
</style>
