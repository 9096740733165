import apiMethod from '../api_call'
import Vue from 'vue'

function initialState() {
  return {
    rowData: {},
    total_rows: {},
  }
}

const suggestionStore = {
  namespaced: true,
  state: initialState,
  mutations: {
    setRowData(state, { rows, perimeter_identifier, policy_identifier }) {
      if (!state.rowData[perimeter_identifier]) {
        Vue.set(state.rowData, perimeter_identifier, {})
      }
      Vue.set(state.rowData[perimeter_identifier], policy_identifier, rows)
    },
    setTotalRows(state, { perimeter_identifier, policy_identifier }) {
      if (!state.total_rows[perimeter_identifier]) {
        Vue.set(state.total_rows, perimeter_identifier, {})
      }
      state.total_rows[perimeter_identifier][policy_identifier] =
        state.rowData[perimeter_identifier][policy_identifier].length
    },
    setRun(state, { solution_id, perimeter_identifier }) {
      for (const key of Object.entries(state.rowData[perimeter_identifier])) {
        const index = state.rowData[perimeter_identifier][key[0]].findIndex((e) => e.id === solution_id)
        if (index >= 0) {
          state.rowData[perimeter_identifier][key[0]][index].run = true
        }
      }
    },
    setBuild(state, { solution_id, perimeter_identifier }) {
      for (const key of Object.entries(state.rowData[perimeter_identifier])) {
        const index = state.rowData[perimeter_identifier][key[0]].findIndex((e) => e.id === solution_id)
        if (index >= 0) {
          state.rowData[perimeter_identifier][key[0]][index].build = true
        }
      }
    },
    setRuntasks(state, { solution_id, runtasks, perimeter_identifier }) {
      for (const key of Object.entries(state.rowData[perimeter_identifier])) {
        const index = state.rowData[perimeter_identifier][key[0]].findIndex((e) => e.id === solution_id)
        if (index >= 0) {
          state.rowData[perimeter_identifier][key[0]][index].runtasks = runtasks
        }
      }
    },
    setIndicators(state, { solution_id, indicators, perimeter_identifier }) {
      for (const key of Object.entries(state.rowData[perimeter_identifier])) {
        const index = state.rowData[perimeter_identifier][key[0]].findIndex((e) => e.id === solution_id)
        if (index >= 0) {
          state.rowData[perimeter_identifier][key[0]][index].indicators = indicators
        }
      }
    },
  },
  actions: {
    LOAD_SUGGESTIONS({ commit, state }, { endpoint, perimeter_identifier, policy_identifier }) {
      if (!state.rowData[perimeter_identifier] || !state.rowData[perimeter_identifier][policy_identifier]) {
        let data = []
        apiMethod.get(endpoint).then((resp) => {
          data = resp.data.objects
          commit('setRowData', {
            rows: data,
            perimeter_identifier: perimeter_identifier,
            policy_identifier: policy_identifier,
          })
          commit('setTotalRows', { perimeter_identifier: perimeter_identifier, policy_identifier: policy_identifier })
        })
      }
    },
    DO_CONSUME({ commit }, { solution, perimeter_identifier }) {
      void apiMethod.post('perimeter/consume', solution).then(() => {
        commit('setRun', { solution_id: solution.id, perimeter_identifier: perimeter_identifier })
      })
    },
    DO_RUN({ commit }, { solution, perimeter_identifier, runtasks, indicators }) {
      solution.runtasks = runtasks
      solution.indicators = indicators
      commit('setRuntasks', {
        solution_id: solution.id,
        runtasks: runtasks,
        perimeter_identifier: perimeter_identifier,
      })
      commit('setIndicators', {
        solution_id: solution.id,
        indicators: indicators,
        perimeter_identifier: perimeter_identifier,
      })
      void apiMethod.post('solution/fork_run', solution).then(() => {
        commit('setRun', { solution_id: solution.id, perimeter_identifier: perimeter_identifier })
      })
    },
    DO_BUILD({ commit }, { solution, perimeter_identifier }) {
      void apiMethod.post('solution/fork_build', solution).then(() => {
        commit('setBuild', { solution_id: solution.id, perimeter_identifier: perimeter_identifier })
      })
    },
    DO_IGNORE({ commit }, { solution_id, perimeter_identifier }) {
      commit('setRun', { solution_id: solution_id, perimeter_identifier: perimeter_identifier })
    },
  },
  getters: {
    getRowData: (state) => {
      return state.rowData
    },
    getTotalRows: (state) => {
      return state.total_rows
    },
  },
}

export default suggestionStore
