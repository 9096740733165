<template>
  <div>
    <span v-if="modal === null">&nbsp;</span>
    <extension-panel
      :cancel-disabled="read_only"
      :cancel-title="cancel_label_"
      :ok-disabled="read_only"
      :ok-title="ok_label_"
      :panelZindex="10000"
      :panelid="panel_id"
      :ro="read_only"
      :show-rewind="showRewind"
      :show_save="!hide_footer"
      :is_save_loading="is_save_loading"
      :hide_buttons="hide_buttons"
      :size="size"
      :title="panel_title"
      @cancel="doClose()"
      @close="doClose()"
      @rewind-form="$emit('rewind-form')"
      @submit="doSubmit"
      ref="panel"
      v-else-if="!modal"
    >
      <slot></slot>
      <template v-slot:footer>
        <slot name="footer"></slot>
      </template>
    </extension-panel>
    <b-modal
      :scrollable="isModalScrollable"
      :cancel-disabled="read_only"
      :cancel-title="cancel_label_"
      :id="panel_id"
      :no-enforce-focus="true"
      :ok-disabled="read_only || ok_disabled"
      :size="size"
      :title="panel_title"
      :hide-footer="hide_footer"
      :hide-header="hide_header"
      @cancel="$emit('cancel')"
      @hide="$emit('hideModal')"
      @close="$emit('cancel')"
      @ok.prevent="doSubmit"
      cancel-variant="light"
      :no-close-on-backdrop="no_close_on_backdrop"
      :no-close-on-esc="no_close_on_esc"
      ok-variant="outline-primary"
      :ok-only="ok_only"
      v-else
    >
      <slot></slot>
      <template
        :slot="slot"
        slot-scope="scope"
        v-for="slot in Object.keys($scopedSlots)"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
      <div
        slot="modal-footer"
        v-if="read_only"
      >
        &nbsp;
      </div>
      <div slot="modal-ok">
        <b-spinner
          v-if="is_save_loading"
          small
        />
        <template v-else>{{ ok_label_ }}</template>
      </div>
    </b-modal>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'
import ExtensionPanel from '../ui/extension-panel.vue'

export default {
  name: 'aciso-form',
  props: {
    read_only: {
      type: Boolean,
      default: false,
    },
    hide_buttons: {
      type: Boolean,
      default: false,
    },
    no_close_on_backdrop: {
      type: Boolean,
      default: true,
    },
    no_close_on_esc: {
      type: Boolean,
      default: true,
    },
    hide_footer: {
      type: Boolean,
      default: false,
    },
    hide_header: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: null,
      required: true,
    },
    panel_id: String,
    panel_title: String,
    ok_label: {
      type: String,
    },
    cancel_label: {
      type: String,
    },
    size: {
      type: String,
      default: 'lg',
    },
    showRewind: {
      type: Boolean,
      default: false,
    },
    ok_only: {
      type: Boolean,
      default: false,
    },
    ok_disabled: {
      type: Boolean,
      default: false,
    },
    is_save_loading: {
      type: Boolean,
      default: false,
    },
    isModalScrollable: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      ok_label_: '',
      cancel_label_: '',
    }
  },
  components: { ExtensionPanel },
  mounted: function () {
    if (this.ok_label) {
      this.ok_label_ = this.ok_label
    } else {
      this.ok_label_ = this._('Save')
    }
    if (this.cancel_label) {
      this.cancel_label_ = this.cancel_label
    } else {
      this.cancel_label_ = this._('Cancel')
    }
  },
  methods: {
    doSubmit: throttle(
      function () {
        if (!this.is_save_loading) {
          this.$emit('submit')
        }
      },
      1000,
      { leading: true, trailing: false }
    ),
    show() {
      if (!this.modal) {
        this.$log.debug(this.$refs)
        this.$refs.panel.show()
      } else {
        this.$bvModal.show(this.panel_id)
      }
    },
    hide() {
      if (!this.modal) {
        this.$refs.panel.hide()
      } else {
        this.$bvModal.hide(this.panel_id)
      }
    },
    doClose() {
      this.$emit('cancel')
    },
  },
}
</script>
