import Vue from 'vue'
import { useStore } from '@/store'
const store = useStore()
import { config } from '/src/config.settings.js'

const posthogPlugin = {
  install() {
    Vue.prototype.$posthog.init(config.posthog_token, {
      api_host: config.posthog_host,
      capture_pageview: false,
      person_profiles: 'identified_only',
    })
    Vue.prototype.$posthog.register({
      customer: store.getters.customer,
    })
  },
  identifyUser() {
    Vue.prototype.$posthog.identify(store.getters.email, {
      email: store.getters.email,
      name: store.getters.name,
    })
  },
}

export default posthogPlugin
