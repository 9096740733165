import Vue from 'vue'
import Vuex from 'vuex'
import cookie from './stores/cookies'
import localStorage from './stores/localstorage'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import questionnaireStore from '@/stores/questionnaire-store'
import connectorStore from '@/stores/connector-store'
import suggestionStore from '@/stores/suggestion-store'
import routerStore from './stores/router-store'

Vue.use(Vuex)
const ls = new SecureLS({ isCompression: false })

const store = new Vuex.Store({
  modules: {
    cookie: cookie,
    localStorage: localStorage,
    questionnaireStore: questionnaireStore,
    connectorStore: connectorStore,
    suggestionStore: suggestionStore,
    routerStore: routerStore,
  },
  plugins: [
    createPersistedState({
      paths: ['cookie', 'localStorage', 'routerStore'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})

export const useStore = () => store
export default store
