<template>
  <tr
    :class="rowClasses"
    :data-id="rowId"
    :data-testid="row.identifier ? row.identifier : row.id"
    :id="'row_' + rowId"
    :style="row_style"
    @mouseleave="is_hover = false"
    @mouseover="is_hover = true"
    class="link"
    ref="vbt_row"
    v-on="
      rowsSelectable
        ? {
            click: ($event) => {
              handleRowSelect($event)
            },
          }
        : { click: ($event) => $emit('clicked', { id: row.id, type: row.type, event: $event, row: row }) }
    "
  >
    <CheckBox
      :disabled="!row.delete_right && !row.checkbox_right"
      :row-selected="rowSelected"
      :rowsSelectable="rowsSelectable"
      :rows_selected="selectedItems.length"
      :radio_button="radio_button"
      @add-row="addRow"
      @remove-row="removeRow"
      v-if="checkboxRows"
    />
    <template v-for="(column, _key, hindex) in columns">
      <td
        :data-testid="column.name ? column.name : hindex"
        :class="cellClasses(column)"
        :key="hindex"
        class="valign-middle"
        :ref="column.name + '_' + row.vbt_id + '_'"
        :title="
          column.name === 'name' || column.ellipsis
            ? isEllipsisActive(column.name + '_' + row.vbt_id + '_', getRowValue(row, column.name))
            : null
        "
        v-if="canShowColumn(column)"
      >
        <slot :name="'vbt-' + getCellSlotName(column)"></slot>
      </td>
    </template>
    <td
      :class="'w-actions-' + row_actions.length"
      :style="{ height: row_height + 'px', 'line-height': row_height + 'px', background: highlightRowHoverColor }"
      class="actions no-row-click text-right p-0"
      v-if="show_actions && row_actions.length > 0"
      v-show="is_hover"
    >
      <icon-action
        :icon="a.icon"
        :disabled="a.disabled === true"
        :key="i"
        :tooltip="a.label"
        placement="bottom"
        :matomo="a.matomo"
        @click.stop="a.cb(row.id, a.additional_delete ? a.additional_delete : null)"
        class="no-row-click mr-1"
        color="gray"
        icon_size="15px"
        is_table_action
        v-for="(a, i) in row_actions"
      ></icon-action>
    </td>
  </tr>
</template>

<script>
import has from 'lodash/has'
import get from 'lodash/get'
import includes from 'lodash/includes'

import CheckBox from './CheckBox.vue'
import IconAction from '../../../icon-action.vue'

const TR_ISSUE_REGEX = RegExp(/[\u2002\u2001]$/)
export default {
  name: 'Row',
  props: {
    show_actions: Boolean,
    row: {
      type: Object,
      required: true,
    },
    propRowClasses: {
      type: Object | String,
      required: false,
    },
    propCellClasses: {
      type: Object | String,
      required: false,
    },
    columns: {
      type: Array,
      default: function () {
        return []
      },
    },
    uniqueId: {
      type: Number | String,
      required: true,
    },
    selectedItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    checkboxRows: {
      type: Boolean,
      default: false,
    },
    radio_button: {
      type: Boolean,
      default: false,
    },
    noEllipsis: {
      type: Boolean,
      default: false,
    },
    mappingEllipsis: {
      type: Boolean,
      default: false,
    },
    smallTable: {
      type: Boolean,
      default: false,
    },
    highlightRowHover: {
      type: Boolean,
      default: false,
    },
    highlightRowHoverColor: {
      type: String,
      default: '#d6d6d6',
    },
    rowsSelectable: {
      type: Boolean,
      default: false,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      row_height: 40,
      row_height_set: false,
      is_hover: false,
      rowSelected: false,
      rowHiglighted: false,
      highlightRowFixe: false,
    }
  },
  mounted() {
    if (this.highlightRowHover) {
      this.$refs.vbt_row.addEventListener('mouseover', () => {
        this.rowHiglighted = true
        this.$emit('row_over', { id: this.row.id })
        this.$emit('row_hover', { order: this.row.order })
      })
      this.$refs.vbt_row.addEventListener('mouseleave', () => {
        this.rowHiglighted = false
        this.$emit('row_out', { id: this.row.id })
        this.$emit('row_hover_out')
      })
    }
    this.setHeight()
    this.checkInSelecteditems(this.selectedItems, this.row)
  },
  methods: {
    getRowValue(row, name) {
      return get(row, name)
    },
    setHeight() {
      if (this.$refs.vbt_row) {
        const h = this.$refs.vbt_row.clientHeight
        if (h) {
          this.row_height = h
          this.row_height_set = true
          return
        }
      }
      setTimeout(this.setHeight, 500)
    },
    addRow(shiftKey) {
      this.$emit('add-row', { shiftKey: shiftKey, rowIndex: this.rowIndex })
    },
    removeRow(shiftKey) {
      this.$emit('remove-row', { shiftKey: shiftKey, rowIndex: this.rowIndex })
    },
    handleRowSelect(event) {
      if (this.rowSelected) {
        this.removeRow(event.shiftKey)
      } else {
        this.addRow(event.shiftKey)
      }
      this.rowSelected = !this.rowSelected
    },
    // compare the selected items list with curretn row item and update checkbox accordingly
    checkInSelecteditems(selectedItems, row) {
      if (!this.checkboxRows && !this.rowsSelectable) {
        return
      }
      if (selectedItems.findIndex((e) => e.vbt_id === row.vbt_id) >= 0) {
        this.rowSelected = true
      } else {
        this.rowSelected = false
      }
    },
    rowHover(state) {
      this.rowHiglighted = state
    },
    SwitchhighlightRowFixe(state) {
      this.highlightRowFixe = state
    },
    getValueFromRow(row, name) {
      return get(row, name)
    },
    cellClasses(column) {
      let classes = ''

      const default_text_alignment = 'text-center'

      //decide text alignment class - starts here
      const alignments = ['text-justify', 'text-right', 'text-left', 'text-center']
      if (has(column, 'row_text_alignment') && includes(alignments, column.row_text_alignment)) {
        classes = classes + ' ' + column.row_text_alignment
      } else {
        classes = classes + ' ' + default_text_alignment
      }
      //decide text alignment class - ends here

      // adding user defined classes from column config to rows - starts here
      if (has(column, 'row_classes')) {
        classes = classes + ' ' + column.row_classes
      }
      // adding user defined classes from column config to rows - ends here

      if (!(column.ellipsis === false)) {
        if (!this.noEllipsis && !this.smallTable && !this.mappingEllipsis) {
          classes += ' text-ellipsis'
        } else if (!this.noEllipsis && this.smallTable) {
          classes += ' text-ellipsis-s'
        } else if (this.mappingEllipsis) {
          classes += ' text-ellipsis-mapping'
        }
      }
      if (
        (column.name === 'name' || column.name === 'description') &&
        TR_ISSUE_REGEX.test(this.getValueFromRow(this.row, column.name))
      ) {
        classes += ' font-italic'
      }
      if (typeof this.propCellClasses == 'string') {
        return this.propCellClasses
      } else if (typeof this.propCellClasses == 'object') {
        Object.entries(this.propCellClasses).forEach(([key, value]) => {
          if (typeof value == 'boolean' && value) {
            classes += ' ' + key
          } else if (typeof value == 'function') {
            const truth = value(this.row, column, this.getValueFromRow(this.row, column.name))
            if (typeof truth == 'boolean' && truth) {
              classes += ' '
              classes += key
            }
          }
        })
      }

      return classes
    },
    getCellSlotName(column) {
      if (has(column, 'slot_name')) {
        return column.slot_name
      }
      return column.name.replace(/\./g, '_')
    },
    canShowColumn(column) {
      return column.visibility == undefined || column.visibility ? true : false
    },
  },
  computed: {
    row_actions() {
      if (this.row.tactions_) {
        return this.row.tactions_.filter((e) => (typeof e.show === 'function' ? e.show() === true : e.show === true))
      }
      return []
    },
    row_style() {
      return {
        background: this.rowHiglighted || this.highlightRowFixe ? this.highlightRowHoverColor : '',
        // 'background-color': (this.highlightRowFixe || this.rowHiglighted) ? this.highlightRowHoverColor : '',
        '-webkit-box-shadow': this.rowHiglighted
          ? '0px 1px 4px 0px rgba(183,192,201,1)'
          : '0px 0px 0px 0px rgba(0,0,0,0)',
        '-moz-box-shadow': this.rowHiglighted ? '0px 1px 4px 0px rgba(183,192,201,1)' : '0px 0px 0px 0px rgba(0,0,0,0)',
        '-box-shadow': this.rowHiglighted ? '0px 1px 4px 0px rgba(183,192,201,1)' : '0px 0px 0px 0px rgba(0,0,0,0)',
        'border-top': this.rowHiglighted ? '0' : '0',
      }
    },
    rowClasses() {
      let classes = this.userRowClasses

      if (this.rowSelected) {
        classes += ' '
        classes += 'vbt-row-selected'
      }
      if (this.rowsSelectable) {
        classes += ' link'
      }

      return classes
    },
    userRowClasses() {
      let classes = ''
      if (typeof this.propRowClasses == 'string') {
        return this.propRowClasses
      } else if (typeof this.propRowClasses == 'object') {
        Object.entries(this.propRowClasses).forEach(([key, value]) => {
          if (typeof value == 'boolean' && value) {
            classes += key
          } else if (typeof value == 'function') {
            const truth = value(this.row)
            if (typeof truth == 'boolean' && truth) {
              classes += ' '
              classes += key
            }
          }
        })
      }

      return classes
    },
    rowId() {
      return this.getValueFromRow(this.row, this.uniqueId)
    },
  },
  watch: {
    row: {
      handler: function (newVal) {
        this.checkInSelecteditems(this.selectedItems, newVal)
      },
      deep: true,
    },
    selectedItems: {
      handler: function (newVal) {
        this.checkInSelecteditems(newVal, this.row)
      },
      deep: true,
    },
  },
  components: {
    IconAction,
    CheckBox,
  },
}
</script>

<style scoped>
.actions {
  position: absolute;
  height: 40px;
  line-height: 40px;
  text-align: center;
  right: 0;
  margin-top: 0;
  padding-right: 10px;
  margin-right: 5px;
  background-color: #f9fafae3;
  empty-cells: show;
  min-width: 50px !important;
}

.action-drag {
  position: absolute;
  height: 40px;
  line-height: 40px;
  text-align: center;
  left: 0;
  margin-top: 0;
  padding-left: 10px;
  margin-left: 0;
  background-color: #f9fafae3;
  empty-cells: show;
  min-width: 50px !important;
}

.actions_tr {
  display: inline;
}
</style>
