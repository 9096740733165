import MainApp from '@/components/main-app.vue'
import { redirectHome } from '@/router/routing'
import { myRoutes } from '@/router/routes/nav_routes/my_routes'
import { operationRoutes } from '@/router/routes/nav_routes/operation_routes'
import { performanceRoutes } from '@/router/routes/nav_routes/performance_routes'
import { strategyRoutes } from '@/router/routes/nav_routes/strategy_routes'

// Build mother route for main navigation (use to create main menu => main-sidebar.vue)

export const navRoutes = [
  {
    path: '/nav',
    name: 'nav',
    redirect: () => redirectHome(),
    component: MainApp,
    meta: {
      dummy: true,
    },
    // each children represent a category in the main menu.
    children: [myRoutes, operationRoutes, performanceRoutes, strategyRoutes],
  },
]
