import { _, isAuthorized, lazyLoadView, piloteAndAuditor } from '@/router/routing'
import type { Route } from 'vue-router'

export const gapsRoutes = {
  path: '/gaps',
  name: 'gaps',
  component: () => lazyLoadView(import('@/contexts/gaps/GapRegistersListView.vue')),
  icon: 'exclamation-square',
  meta: {
    title: _('Gaps'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    doc_id: 'gap_register',
    feature_id: 'GAPS',
  },
  children: [
    {
      path: ':id',
      name: 'gap',
      component: () => lazyLoadView(import('@/contexts/gaps/GapRegisterView.vue')),
      icon: 'exclamation-square',
      meta: {
        title: null,
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'gap',
      },
    },
  ],
}
