import apiMethod from '../api_call'
import Vue from 'vue'

function initialState() {
  return {
    template: {
      template_configuration: {
        fields: [],
        modules: [{}],
      },
    },
    current_module: 0,
    customer_connector: {
      id: null,
      configuration: {},
      modules: [
        {
          id: null,
          name: null,
          enable: true,
          configuration: {},
          measure_identifier: null,
          perimeters_mapping: [{}],
          metrics_mapping: [{}],
          config_metrics_mapping: [{}],
        },
      ],
    },
  }
}

const connectorStore = {
  namespaced: true,
  state: initialState,
  mutations: {
    // Set current module by index
    setCurrentModule(state, index) {
      state.current_module = index
    },
    // Set the template object in the state
    setTemplate(state, template) {
      state.template = template
    },
    // Set the customer connector object in the state
    setCustomerConnector(state, customerConnector) {
      state.customer_connector = customerConnector
    },
    // Reset data connector store
    resetDataConnector(state) {
      state.customer_connector = {
        id: null,
        configuration: {},
        modules: [
          {
            id: null,
            name: null,
            enable: true,
            configuration: {},
            measure_identifier: null,
            perimeters_mapping: [{}],
            metrics_mapping: [{}],
            config_metrics_mapping: [{}],
          },
        ],
      }
      ;(state.template = {
        template_configuration: {
          fields: [],
          modules: [
            {
              config_metrics_mapping: [{}],
            },
          ],
        },
      }),
        (state.current_module = 0)
    },
    // Set modules object in the state
    setModules(state, modules) {
      state.customer_connector.modules = modules
    },
    // Set a specific module in the customer connector modules array
    setModule(state, { index, module }) {
      Vue.set(state.customer_connector.modules, index, module)
    },
    // Set a specific configuration module in the customer connector modules array
    setModuleConfig(state, { index, configuration }) {
      if (!state.customer_connector.modules[index]) {
        state.customer_connector.modules[index] = {}
      }
      Vue.set(state.customer_connector.modules[index], 'configuration', configuration)
    },
    // Set the configuration in the customer connector
    setGlobalConfig(state, { configuration }) {
      Vue.set(state.customer_connector, 'configuration', configuration)
    },
    // Set a specific configurable_metric_mapping object module in the customer connector modules array
    setCurrentConfigMetricMapping(state, { configurable_metric_mapping }) {
      if (!state.customer_connector.modules[state.current_module]) {
        state.customer_connector.modules[state.current_module] = {}
      }
      Vue.set(
        state.customer_connector.modules[state.current_module],
        'configurable_metric_mapping',
        configurable_metric_mapping
      )
    },
    // Set a specific perimeters_mapping object module in the customer connector modules array
    setCurrentPerimetersMapping(state, { perimeters_mapping }) {
      if (!state.customer_connector.modules[state.current_module]) {
        state.customer_connector.modules[state.current_module] = {}
      }
      Vue.set(state.customer_connector.modules[state.current_module], 'perimeters_mapping', perimeters_mapping)
    },
    // Set the configuration in the customer connector
    addMetricMapping(state, { index, metric_mapping }) {
      if (state.customer_connector.modules[index].metrics_mapping && metric_mapping) {
        state.customer_connector.modules[index].metrics_mapping.push(metric_mapping)
      } else {
        Vue.set(state.customer_connector.modules[index], 'metrics_mapping', [metric_mapping])
      }
    },
  },
  actions: {
    RESET_DATA({ commit }) {
      commit('resetDataConnector')
    },
    LOAD_TEMPLATE({ commit }, { connector_id }) {
      apiMethod.get('connector/' + connector_id + '/config_template', {}, false).then((response) => {
        if (response.data.object) {
          commit('setTemplate', response.data.object)
        }
      })
    },
    LOAD_CUSTOMER_CONNECTOR({ commit }, { customer_connector_id }) {
      apiMethod.get('connector/customer/' + customer_connector_id + '/config', {}, false).then((response) => {
        if (response.data.object) {
          commit('setCustomerConnector', response.data.object)
        }
      })
    },
    SET_MODULE({ commit }, { index, module }) {
      commit('setModule', { index, module })
    },
    SET_MODULES({ commit }, { modules }) {
      commit('setModules', { modules })
    },
    SET_MODULE_CONFIG({ commit }, { configuration }) {
      commit('setModuleConfig', { configuration })
    },
    SET_GLOBAL_CONFIG({ commit }, { configuration }) {
      commit('setGlobalConfig', { configuration })
    },
    SET_CURRENT_CONFIG_METRICS({ commit }, { configurable_metric_mapping }) {
      commit('setCurrentConfigMetricMapping', { configurable_metric_mapping })
    },
    SET_CURRENT_PERIMETERS_MAPPING({ commit }, { perimeters_mapping }) {
      commit('setCurrentPerimetersMapping', { perimeters_mapping })
    },
    ADD_METRIC_MAPPING({ commit }, { index, metric_mapping }) {
      commit('addMetricMapping', { index, metric_mapping })
    },
  },
  getters: {
    getTemplate: (state) => {
      return state.template
    },
    getTemplateFields: (state) => {
      return state.template.template_configuration.fields
    },
    getTemplateConfiguration: (state) => {
      return state.template.template_configuration
    },
    getCustomerConnector: (state) => {
      return state.customer_connector
    },
    getModulesTemplate: (state) => {
      return state.template.template_configuration.modules
    },
    getModules: (state) => {
      return state.customer_connector.modules ? state.customer_connector.modules : []
    },
    isMultiModule: (state) => {
      return state.customer_connector.modules.length > 1
    },
    getCurrentModuleIndex: (state) => {
      return state.current_module
    },
    getCurrentModuleTemplate: (state) => {
      if (
        state.template.template_configuration &&
        state.template.template_configuration.modules &&
        state.template.template_configuration.modules.length > 0
      ) {
        return state.template.template_configuration.modules[state.current_module]
      }
    },
    getCurrentModuleByIndex: (state) => (index) => {
      return state.customer_connector.modules[index]
    },
    getCurrentModule: (state) => {
      if (!state.customer_connector) {
        return
      }

      const modules = state.customer_connector.modules
      if (modules && modules.length > 0) {
        return modules[state.current_module]
      }
    },
    getGlobalConfig: (state) => {
      return state.customer_connector && state.customer_connector.configuration
        ? state.customer_connector.configuration
        : {}
    },
    getCurrentModuleConfig: (state) => (index) => {
      const module = state.customer_connector.modules[index]
      return module && module.configuration ? module.configuration : {}
    },
    getModuleAttribute: (state) => (index, attribute) => {
      const module = state.customer_connector.modules[index]
      return module && module[attribute] ? module[attribute] : null
    },
    getModuleConfiguration: (state) => (index) => {
      const module = state.customer_connector.modules[index]
      return module && module.configuration ? module.configuration : {}
    },
    getModule: (state) => (module_identifier) => {
      for (let i = 0; state.customer_connector.modules[i]; i++) {
        if (state.customer_connector.modules[i].identifier === module_identifier) {
          return state.customer_connector.modules[i]
        }
      }
      return null
    },
    getTemplateModule: (state) => (module_identifier) => {
      return state.template.template_configuration.modules.find((m) => m.identifier === module_identifier)
    },
    getModuleFields: (state) => (module_identifier) => {
      const module = state.template.template_configuration.modules.find((m) => m.identifier === module_identifier)
      return module && module.fields ? module.fields : []
    },
    getCurrentTemplateConfigMetrics: (state) => {
      return state.template.template_configuration.modules
        ? state.template.template_configuration.modules[state.current_module].conf_metrics_mapping
        : []
    },
    getCurrentConfigMetrics: (state) => {
      const module = state.customer_connector.modules[state.current_module]
      return module && module.config_metrics_mapping ? module.config_metrics_mapping : []
    },
  },
}

export default connectorStore
