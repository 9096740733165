// intercomService.js
import Intercom from '@intercom/messenger-js-sdk'
import { useStore } from '@/store'
import { config } from '@/config.settings'
import { getInstanceServerName } from '@/services/Intercom/utils'

const store = useStore()

export const init = async (hash) => {
  const intercomAppId = config.intercom_app_id
  Intercom({
    app_id: intercomAppId,
    region: 'eu',
    user_id: store.getters.email,
    name: store.getters.name,
    email: store.getters.email,
    created_at: new Date(),
    user_hash: hash,
    Role: store.getters.instance_role,
    Tenant_name: store.getters.customer,
    Rattachement: store.getters.user_tag ? store.getters.user_tag : store.getters.user_perimeter,
    Authentification: store.getters.has_saml ? 'SAML' : 'PASSWORD',
    Instance_server_name: getInstanceServerName(store.getters.instance),
    Instance_server_type: store.getters.instance,
    Url_server: window.location.origin,
  })
}

export default { init }
