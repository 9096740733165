<template>
  <b-button
    @click.prevent.stop="emitClick"
    :class="filter_active ? 'tenacy-btn-filter-active' : 'tenacy-btn-filter'"
    :disabled="disabled_condition"
    variant="outline-primary"
  >
    <i
      class="fas fa-filter tenacy-btn-filter-icon"
      :class="filter_active ? 'tenacy-btn-filter-icon-active' : ''"
    ></i>
    <span :class="filter_active ? 'filter-active-circle' : 'circle'"></span>
  </b-button>
</template>

<script>
export default {
  name: 'app-button-filter',
  props: {
    disabled_condition: {
      type: Boolean,
      default: false,
    },
    filter_active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {}
  },
  methods: {
    emitClick(ev) {
      if (!this.disabled) {
        this.$emit('click', ev)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.circle {
  border: 2px solid #c6c6c6;
  height: 15px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 15px;
  display: inline-block;
  background-color: #ffffff;
  vertical-align: middle;
}

.filter-active-circle {
  background-color: #0ca30a;
  border: 2px solid #ffffff;
  height: 15px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 15px;
  display: inline-block;
  vertical-align: middle;
}

.tenacy-btn-filter {
  height: 40px;
  width: 64px;
  padding: 4px 8px 4px 8px;
}

.tenacy-btn-filter-active {
  height: 40px;
  background-color: #0a4650;
  border-color: #0a4650;
  width: 64px;
  padding: 4px 8px 4px 8px;
}

.tenacy-btn-filter-icon {
  margin-right: 8px;
  vertical-align: middle;
}

.tenacy-btn-filter-icon-active {
  color: #ffffff;
}
</style>
