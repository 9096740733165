import { _, allRoles, isAuthorized, lazyLoadView, piloteAndAuditor, requiresInternal, setNavL2 } from '@/router/routing'
import type { Route } from 'vue-router'

export const exemptionsRoutes = {
  path: '/exemptions',
  name: 'exemptions',
  component: () => lazyLoadView(import('@/contexts/exemptions/ExemptionRegistersListView.vue')),
  icon: 'unlock',
  meta: {
    title: _('Exemptions'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_role: piloteAndAuditor,
    requires_auth: true,
    requires_tenant: true,
    doc_id: 'exemption_register',
    feature_id: 'EXEMPTIONS',
  },
  children: [
    {
      path: ':id',
      name: 'exemption',
      component: () => lazyLoadView(import('@/contexts/exemptions/ExemptionRegisterView.vue')),
      icon: 'unlock',
      meta: {
        title: null,
        is_authorized: (route: Route) => isAuthorized(route),
        requires_role: piloteAndAuditor,
        requires_auth: true,
        requires_tenant: true,
        doc_id: 'exemption',
      },
    },
  ],
}

export const exemptionsContributionRoutes = {
  path: 'exemptions',
  name: 'myexemptions',
  icon: 'unlock',
  component: () => lazyLoadView(import('@/contexts/exemptions/ExemptionsContributionView.vue')),
  beforeEnter: setNavL2,
  meta: {
    title: _('Exemptions'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: allRoles,
    requires_internal: () => requiresInternal(),
    doc_id: 'my_exemptions',
    nb_contribution: 'nb_exemptions',
    feature_id: 'EXEMPTIONS',
  },
}
