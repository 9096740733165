import SignIn from '@/components/unauth-pages/sign-in.vue'
import ForgotPassword from '@/components/unauth-pages/forgot-password.vue'
import PasswordReset from '@/components/unauth-pages/password-reset.vue'
import { lazyLoadView, _ } from '@/router/routing'

// login, forgot_password, password_reset and sso don't need to be login

export const authRoutes = [
  {
    path: '/login',
    name: 'login',
    component: SignIn,
    meta: {
      title: _('Sign in'),
      requires_auth: false,
    },
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: ForgotPassword,
    meta: {
      title: _('Forgot password'),
      requires_auth: false,
    },
  },
  {
    path: '/password_reset/:token',
    name: 'password_reset',
    component: PasswordReset,
    meta: {
      title: _('Password reset'),
      requires_auth: false,
    },
  },
  {
    path: '/sso',
    name: 'sso',
    component: () => lazyLoadView(import('@/components/unauth-pages/sso.vue')),
    meta: {
      title: _('Single Sign On'),
      requires_auth: false,
    },
  },
]
