<template>
  <div>
    <h1>
      <i class="fa fa-exclamation-circle text-warning mr-2"> </i>
      <span class="">
        <translate>Application was updated</translate>
      </span>
    </h1>
    <div class="ml-3 text-muted text-lg">
      <p>
        <i>
          <translate>The page will automatically reload</translate>
        </i>
      </p>
      <b-button
        variant="outline-primary"
        @click="reload"
        ><translate>Reload now</translate></b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'error-reload',
  components: {},
  methods: {
    reload() {
      this.$log.debug(this.$route.name)
      if (this.$route.name === 'reload') {
        window.location = '/'
      } else {
        window.location.reload()
      }
    },
  },
  mounted() {
    this.reload()
  },
}
</script>
