/**
 * Simple caching class.
 * Use with caution : caching usage could have side effects
 */
export class CustomCache {
  constructor() {
    this._cache = new Map()
  }

  /**
   * Clear the cache
   */
  clear() {
    this._cache.clear()
  }

  /**
   * Has key in cache
   * @param {string} key The key to check in cache
   * @returns {boolean}
   */
  has(key) {
    return this._cache.has(key)
  }

  /**
   * Get value in cache
   * @param {string} key The key to get in cache
   * @returns {*}
   */
  get(key) {
    return this._cache.get(key)
  }

  /**
   * Set key / value in cache
   * @param {string} key The key to set in cache
   * @param {*} value The value to set in cache
   * @returns {*} Returns the value which was set
   */
  set(key, value) {
    this._cache.set(key, value)

    return this._cache.get(key)
  }

  /**
   * Get key in cache
   * @param {string} key The key to get in cache
   * @param {function} setterFunction A function that returns the value to set if the key does not exist in cache
   * @returns {*}
   */
  getOrSet(key, setterFunction) {
    if (this._cache.has(key)) {
      return this.get(key)
    }

    return this.set(key, setterFunction())
  }
}
