export const getInstanceServerName = (instance) => {
  const mappings = {
    'Serveur dédié Monaco': (inst) => inst.includes('mnc'),
    'Serveur mutualisé SNC': (inst) => inst === 'fb-prod-snc',
    'Serveur dédié SNC': (inst) => inst.includes('snc') && inst !== 'fb-prod-snc',
    'Serveur mutualisé': (inst) => inst === 'prod',
    'Serveur dédié': (inst) => inst.startsWith('fb-'),
  }

  for (const [serverName, condition] of Object.entries(mappings)) {
    if (condition(instance)) {
      return serverName
    }
  }

  return 'Serveur mutualisé'
}
