import Vue, { ref } from 'vue'
import { useStore } from '@/store'

export interface UseOptionsMixinData {
  id: string
  name: string
  prefix?: string
  description?: string
}

export const langOptions: UseOptionsMixinData[] = [
  { id: 'en', name: 'English' },
  { id: 'fr', name: 'Français' },
]

export const remediation_template_type_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._
  const _tr = Vue.prototype._tr

  return [
    { id: 'INCIDENT', name: _('Incident') },
    { id: 'GAP', name: _('Gap') },
    { id: 'EXEMPTION', name: _('Exemption') },
    { id: 'BUILD', name: _tr('Build', 'Construction') },
    { id: 'UPGRADE', name: _tr('Improve', 'Amélioration') },
  ]
}

export const remediation_type_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._
  const _tr = Vue.prototype._tr

  return [
    { id: 'BUILD', name: _tr('Build', 'Construction') },
    { id: 'UPGRADE', name: _tr('Improve', 'Amélioration') },
    { id: 'SIMPLE', name: _('Simple') },
  ]
}

export const remediation_source_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'UPGRADE', name: _('Improve') },
    { id: 'RISK', name: _('Risk') },
    { id: 'EXEMPTION', name: _('Exemption') },
    { id: 'GAP', name: _('Gap') },
    { id: 'PROJECT', name: _('Project') },
    { id: 'EVAL', name: _('Evaluation') },
    { id: 'BUILD', name: _('Build') },
    { id: 'INCIDENT', name: _('Incident') },
    { id: 'SIMPLE', name: _('Simple') },
  ]
}

export const exemption_status_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'REQUESTED', name: _('Requested') },
    { id: 'GRANTED', name: _('Granted') },
    { id: 'REJECTED', name: _('Rejected') },
    { id: 'CLOSED', name: _('Closed') },
  ]
}

export const incident_status_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'OPENED', name: _('Opened') },
    { id: 'ANALYSIS', name: _('Analysis') },
    { id: 'CONTAINMENT', name: _('Containment') },
    { id: 'ERADICATION', name: _('Eradication') },
    { id: 'RECOVERY', name: _('Recovery') },
    { id: 'LEARNING', name: _('Learning') },
    { id: 'CLOSED', name: _('Closed') },
  ]
}

export const risk_treatment_status_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'OPEN', name: _('Open') },
    { id: 'ACCEPTED', name: _('Accepted') },
    { id: 'TRANSFERRED', name: _('Transferred') },
    { id: 'CLOSED', name: _('Closed') },
  ]
}

export const indicator_type_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'PERFORMANCE', name: _('Performance') },
    { id: 'ACTIVITY', name: _('Activity') },
  ]
}

export const perimeter_agg_type_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'SUM', name: _('Sum') },
    { id: 'AVERAGE', name: _('Average') },
    { id: 'MIN', name: _('Minimum') },
    { id: 'MAX', name: _('Maximum') },
    { id: 'MEDIAN', name: _('Median') },
  ]
}

export const time_agg_type_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'SUM', name: _('Sum') },
    { id: 'AVERAGE', name: _('Average') },
    { id: 'LATEST', name: _('Latest value') },
    { id: 'MIN', name: _('Minimum') },
    { id: 'MAX', name: _('Maximum') },
    { id: 'MEDIAN', name: _('Median') },
  ]
}

export const perimeter_score_type_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'DECLARATIVE', name: _('Declarative') },
    { id: 'COVERAGE', name: _('Coverage') },
    { id: 'MEASURED', name: _('Measured') },
    { id: 'AUTO', name: _('Auto') },
  ]
}

export const mfa_mode_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'USER_CHOICE', name: _('User choice') },
    { id: 'ENFORCED', name: _('Enforce') },
    { id: 'DISABLED', name: _('Disable') },
  ]
}

export const periodicity_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'DAILY', name: _('Daily') },
    { id: 'WEEKLY', name: _('Weekly') },
    { id: 'MONTHLY', name: _('Monthly') },
    { id: 'QUARTERLY', name: _('Quarterly') },
    { id: 'SEMESTERLY', name: _('Semesterly') },
    { id: 'YEARLY', name: _('Yearly') },
  ]
}

export const periodicity_options_dashboard = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'WEEKLY', name: _('Weeks') },
    { id: 'MONTHLY', name: _('Months') },
  ]
}

export const periodicity_options_runtask = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'DAILY', name: _('Daily') },
    { id: 'WEEKLY', name: _('Weekly') },
    { id: 'MONTHLY', name: _('Monthly') },
    { id: 'QUARTERLY', name: _('Quarterly') },
    { id: 'SEMESTERLY', name: _('Semesterly') },
    { id: 'YEARLY', name: _('Yearly') },
  ]
}

export const run_task_types = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'OPERATION', name: _('Operation') },
    { id: 'CONTROL', name: _('Control░').replace('░', '') },
  ]
}

export const eval_scale_remediation_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'NON_APPLICABLE', name: _('Not allowed') },
    { id: 'APPLICABLE', name: _('Allowed') },
    { id: 'REQUIRED', name: _('Required') },
  ]
}

export const perimeter_type_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'INTERNAL', name: _('Internal'), prefix: 'i' },
    { id: 'PROVIDER', name: _('Provider'), prefix: 'p' },
    { id: 'APPLICATION', name: _('Application'), prefix: 'ap' },
  ]
}

export const import_status_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'INIT', name: _('Initiated') },
    { id: 'STARTED', name: _('Started') },
    { id: 'COMPLETE', name: _('Completed') },
    { id: 'ERROR', name: _('Error') },
  ]
}

export const dot_placement_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'START', name: _('Start'), description: _('At the beginning of period') },
    { id: 'MIDDLE', name: _('Middle'), description: _('In the middle of the period') },
    { id: 'END', name: _('End'), description: _('At the end of the period') },
  ]
}

export const business_type_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'B2B', name: _('B2B'), description: _('Business to business') },
    { id: 'B2C', name: _('B2C'), description: _('Business to consumer') },
    { id: 'PUBLIC', name: _('Public'), description: _('Public sector') },
  ]
}

export const cyber_maturity_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'LOW', name: _('Low'), description: _('Not mature') },
    { id: 'MEDIUM', name: _('Medium'), description: _('Mature') },
    { id: 'HIGH', name: _('High'), description: _('Very mature') },
  ]
}

export const is_public_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'PUBLIC', name: _('Public') },
    { id: 'PRIVATE', name: _('Private') },
  ]
}

export const metadata_property_type_options = (): UseOptionsMixinData[] => {
  const _ = Vue.prototype._

  return [
    { id: 'SECTOR_HIGH', name: _('Sector high') },
    { id: 'SECTOR_LOW', name: _('Sector low') },
    { id: 'COUNTRY', name: _('Country') },
  ]
}

export const useOptionsMixin = () => {
  const _ = Vue.prototype._
  const store = useStore()

  const visibilityOptions = ref<UseOptionsMixinData[]>([
    { id: 'RESTRICTED', name: _('Restricted') },
    { id: 'UNRESTRICTED', name: _('Unrestricted') },
  ])

  const operatorsOptions = ref<UseOptionsMixinData[]>([
    { id: '=', name: '=' },
    { id: '<', name: '<' },
    { id: '>', name: '>' },
    { id: '<=', name: '<=' },
    { id: '>=', name: '>=' },
    { id: 'IS_SET', name: _('is set') },
    { id: 'ISNT_SET', name: _("isn't set") },
    { id: 'INCLUDES', name: _('Includes') },
    { id: 'CONTAINS', name: _('Matches') },
  ])

  const flagOptions = ref<UseOptionsMixinData[]>([
    { id: 'YES', name: _('Yes') },
    { id: 'NO', name: _('No') },
    { id: 'IS_SET', name: _('is set') },
    { id: 'ISNT_SET', name: _("isn't set") },
  ])

  const dataInputOptions = ref<UseOptionsMixinData[]>([
    { id: 'ROOT', name: _('Root') },
    { id: 'LOCAL', name: _('Local') },
  ])

  const dataTypeOptions = ref<UseOptionsMixinData[]>([
    { id: 'TEXT', name: _('Text') },
    { id: 'NUMBER', name: _('Number') },
    { id: 'SINGLE_CHOICE', name: _('Single choice') },
    { id: 'MULTIPLE_CHOICE', name: _('Multiple choice') },
    { id: 'FLAG', name: _('Flag') },
    { id: 'SCALE', name: _('Scale') },
    { id: 'URL', name: _('URL') },
    { id: 'DATE', name: _('Date') },
    { id: 'SUBNET', name: _('Subnets') },
    { id: 'DOMAIN', name: _('Domains') },
  ])

  const propertyPerimeterTypeOptions = ref<UseOptionsMixinData[]>([
    { id: 'APPLICATION', name: _('Application') },
    { id: 'INTERNAL', name: _('Internal') },
    { id: 'PROVIDER', name: _('Provider') },
    { id: 'ALL', name: _('All') },
  ])

  const featuresOptions = ref<UseOptionsMixinData[]>([
    { id: 'SETTINGS', name: _('Settings') },
    { id: 'USER_GROUP', name: _('Users and groups') },
    { id: 'INTEGRATION', name: _('Integration') },
    { id: 'SECURITY_BASE', name: _('Security bases') },
    { id: 'CATALOG', name: _('Catalog') },
    { id: 'RISKS', name: _('Risks') },
    { id: 'POLICIES', name: _('Policies') },
    { id: 'RUNTASKS', name: _('Runtasks') },
    { id: 'CAMPAIGNS', name: _('Evaluation campaigns') },
    { id: 'INCIDENTS', name: _('Incidents') },
    { id: 'EXEMPTIONS', name: _('Exemptions') },
    { id: 'GAPS', name: _('Gaps') },
    { id: 'ISP', name: _('Projects') },
    { id: 'DASHBOARDS', name: _('Dashboards') },
  ])

  const publicRolesOptions = ref<UseOptionsMixinData[]>([
    { id: 'support', name: _('Support') },
    {
      id: 'staff+',
      name: store.getters.instance_type !== 'education' ? _('Manager') : _('Teacher'),
    },
    { id: 'admin', name: _('Admin') },
    { id: 'staff', name: store.getters.instance_type !== 'education' ? _('Staff') : _('Student') },
  ])

  const rolesOptions = ref<UseOptionsMixinData[]>([
    { id: 'global_ro', name: _('Auditor') },
    { id: 'global_rw', name: _('Pilot') },
    { id: 'local_usr', name: _('Contributor') },
  ])

  const engagementTypeOptions = ref<UseOptionsMixinData[]>([
    { id: 'MONTHLY', name: _('Monthly') },
    { id: 'YEARLY', name: _('Yearly') },
  ])

  const remediationStatusOptions = ref<UseOptionsMixinData[]>([
    { id: 'TO_PLAN', name: _('To plan') },
    { id: 'PLANNED', name: _('Planned') },
    { id: 'ONGOING', name: _('Ongoing') },
  ])

  const remediationStatusOptionsEdit = ref<UseOptionsMixinData[]>([
    { id: 'TO_PLAN', name: _('To plan') },
    { id: 'PLANNED', name: _('Planned') },
    { id: 'STANDBY', name: _('On hold') },
    { id: 'ONGOING', name: _('Ongoing') },
    { id: 'FINISHED', name: _('Finished') },
    { id: 'CANCELLED', name: _('Cancelled') },
  ])

  return {
    visibilityOptions,
    operatorsOptions,
    flagOptions,
    dataInputOptions,
    dataTypeOptions,
    propertyPerimeterTypeOptions,
    featuresOptions,
    publicRolesOptions,
    rolesOptions,
    engagementTypeOptions,
    remediationStatusOptions,
    remediationStatusOptionsEdit,
  }
}
