<template>
  <div>
    <h5 class="feedback-question mb-4">
      {{ question_ }}
    </h5>
    <b-row align-v="center">
      <b-col
        class="icon-center"
        :key="index"
        v-for="(vote, index) in scale_vote"
      >
        <a
          href="#"
          @click="sendVote(vote.value)"
          :title="_(vote.label)"
          v-b-tooltip
          @mouseover="vote.hover = true"
          @mouseleave="vote.hover = false"
        >
          <i
            class="fa-2x icon-style text-primary"
            :class="classes(vote)"
          ></i>
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'feedback',
  data: function () {
    return {
      question_: '',
    }
  },
  props: {
    question: {
      type: String,
      default: '',
    },
    current_rating: {
      type: Number,
      default: null,
    },
    scale_vote: {
      default: () => [
        {
          label: 'Not really',
          value: -1,
          icon: 'fa-frown',
          hover: false,
        },
        {
          label: 'Kind of',
          value: 0,
          icon: 'fa-meh',
          hover: false,
        },
        {
          label: 'Yes',
          value: 1,
          icon: 'fa-smile',
          hover: false,
        },
      ],
    },
  },
  methods: {
    sendVote(vote) {
      const _this = this
      _this.$emit('sendVote', vote)
    },
    classes: function (vote) {
      const ret = []
      ret.push(vote.icon)
      if (vote.hover) {
        ret.push('far')
      } else {
        ret.push('fal')
      }
      if (this.current_rating !== null && parseInt(vote.value) === this.current_rating) {
        ret.push('text-secondary')
      }
      return ret
    },
  },
  computed: {},
  mounted() {
    if (this.question !== '' && this.question) {
      this.question_ = this.question
    } else {
      this.question_ = this._('Is this information helpful?')
    }
  },
}
</script>

<style scoped>
.feedback-question {
  text-align: center;
  color: #0c5460;
}

.icon-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
