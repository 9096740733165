<template>
  <aciso-form
    :ok_label="_('Create')"
    :panel_id="modal_id"
    :panel_title="modal_add_title"
    @cancel="$emit('cancelled')"
    @submit="doSelect"
    :hide_footer="!ready"
    modal
    ref="form"
    size="lg"
  >
    <div
      class="box-body"
      data-select2-id="6"
    >
      <form
        data-vv-scope="solution_fork_import"
        v-if="ready"
      >
        <form-field-select
          :allow_empty="false"
          :close-on-select="true"
          :error="errors.first('solution_fork_import' + '.perimeter')"
          :label="_('Perimeter')"
          :options="perimeter_options"
          :placeholder="_('Select perimeter')"
          name="perimeter"
          options_label="name"
          trackby="id"
          v-if="!has_init_perimeter"
          v-model="current_obj.perimeter"
          v-validate="'required'"
        >
          <template
            slot="option"
            slot-scope="props"
          >
            <b-badge
              variant="primary"
              class="d-inline"
              v-if="props.option.identifier"
              >{{ props.option.identifier }}</b-badge
            >&nbsp;
            <span class="option__title">{{ props.option.name }}</span>
          </template>
        </form-field-select>

        <div v-if="current_obj.perimeter">
          <form-field-select
            :allow_empty="true"
            :custom-label="usersWithGroups"
            :disabled="current_operation === 'view'"
            :error="errors.first(scope + '.groups_users')"
            :help="
              type === 'run'
                ? _('The name of the person or the group in charge of this measure')
                : _('The name of the person or the group in charge of this action')
            "
            :label="_('Manager')"
            :options="groups_users_f"
            :placeholder="_('Select group or user')"
            name="groups_users"
            options_label="name"
            trackby="id"
            v-model="current_obj.group_user"
            v-validate="{ required: false }"
            @change="setUserGroupDirtyFlag"
          >
            <template
              slot="option"
              slot-scope="props"
            >
              <span
                class="d-inline"
                v-if="props.option.is_group"
                ><i class="far fa-users"></i>&nbsp;
                <span class="option__title">{{ props.option.name }}</span>
              </span>
              <span
                class="d-inline"
                v-else
                ><i class="far fa-user"></i>&nbsp;
                <span class="option__title">{{ props.option.name }}</span>
              </span>
              <span class="ms-times pull-right"><translate>Remove</translate></span>
            </template>
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <span
                class="d-inline"
                v-if="props.option.is_group"
                ><i class="far fa-users"></i>&nbsp;
                <span class="option__title">{{ props.option.name }}</span>
              </span>
              <span
                class="d-inline"
                v-else
                ><i class="far fa-user"></i>&nbsp;
                <span class="option__title">{{ props.option.name }}</span>
              </span>
            </template>
          </form-field-select>

          <form-field-select
            :allow_empty="false"
            :disabled="current_operation === 'view'"
            :error="errors.first(scope + '.program')"
            :label="_('Action plans')"
            :options="program_options"
            :placeholder="_('Select register')"
            name="program"
            options_label="name"
            trackby="id"
            v-if="type === 'build' && program_options.length > 0"
            v-model="current_obj.program"
          >
          </form-field-select>

          <form-field-select
            :allow_empty="false"
            :disabled="current_operation === 'view'"
            :error="errors.first(scope + '.runbook')"
            :label="_('Run book')"
            :options="runbook_options"
            :placeholder="_('Select run book')"
            name="runbook"
            options_label="name"
            trackby="id"
            v-if="type === 'run' && runtasks && runtasks.length > 0"
            v-model="current_obj.runbook"
          >
          </form-field-select>

          <form-field
            :close-on-select="true"
            :error="errors.first('solution_fork_import' + '.manager')"
            :label="_('Manager')"
            :options="perimeter_options"
            :placeholder="_('John')"
            name="manager"
            v-if="false"
            v-model="current_obj.manager"
          >
          </form-field>

          <b-row v-if="type === 'run'">
            <b-col
              cols="6"
              v-if="current_solution_ && runtasks && runtasks.length > 0"
            >
              <h5>
                <translate>Select runtasks</translate>
              </h5>
              <b-checkbox-group v-model="current_obj.selected_runtasks">
                <b-checkbox
                  :key="runtask.id"
                  :value="runtask.id"
                  class="w-100"
                  v-for="runtask in runtasks"
                >
                  <b-badge v-if="runtask.identifier">{{ runtask.identifier }}</b-badge>
                  {{ runtask.name }}
                </b-checkbox>
                <br />
              </b-checkbox-group>
              <form-field-date
                :disabled="current_operation === 'view'"
                :label="_('Start date')"
                name="start_date"
                ref="start_date"
                v-model="current_obj.runtasks_start_date"
                v-if="current_obj.selected_runtasks && current_obj.selected_runtasks.length > 0"
                v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
              ></form-field-date>
            </b-col>

            <b-col
              cols="6"
              v-if="current_solution_ && indicators_f && indicators_f.length > 0"
            >
              <h5>
                <translate>Select indicators</translate>
              </h5>
              <b-checkbox-group v-model="current_obj.selected_indicators">
                <b-checkbox
                  :key="indicator.id"
                  :value="indicator.id"
                  class="w-100"
                  v-for="indicator in indicators_f"
                >
                  <b-badge v-if="indicator.identifier">{{ indicator.identifier }}</b-badge>
                  {{ indicator.name }}
                </b-checkbox>
                <br />
              </b-checkbox-group>
            </b-col>
          </b-row>
        </div>
      </form>
      <p
        class="text-center"
        v-else
      >
        <i class="far fa-circle-notch fa-spin fa-2x"></i>
      </p>
    </div>
  </aciso-form>
</template>

<script>
import AcisoForm from '../layout/aciso-form.vue'
import FormFieldSelect from '../ui/form-field-select.vue'
import EditMixin from '../mixins/edit-mixin'
import FormField from '../ui/form-field.vue'
import FormFieldDate from '../ui/form-field-date.vue'
import { getOptionsMixin } from '@/services/GetOptionsMixin.services'

export default {
  name: 'solution-fork-select',
  components: { FormFieldDate, FormField, FormFieldSelect, AcisoForm },
  props: {
    type: {
      type: String,
      default: '',
    },
    do_post: {
      type: Boolean,
      default: false,
    },
    current_solution_: Object,
  },
  data: function () {
    return {
      ready: false,
      runtasks: [],
      indicators: [],
      perimeter_options: [],
      runtasks_start_date: this.$moment().format('DD/MM/YYYY'),
      has_init_perimeter: false,
      api_endpoint: 'remediation',
      program_options: [],
      runbook_options: [],
    }
  },
  mixins: [EditMixin],
  methods: {
    usersWithGroups({ groups_users }) {
      return `${groups_users.name}`
    },
    preFormSubmit(obj) {
      this.setUserGroupSubmit(obj)
      if (!this.current_obj.perimeter) {
        this.$toast.alert(this._('Missing perimeter'))
        return
      }
      if (this.current_solution_.solution && this.current_solution_.solution.id) {
        obj.id = this.current_solution_.solution.id
      } else if (this.current_solution_.solution_id) {
        obj.id = this.current_solution_.solution_id
      } else if (this.current_solution_.id) {
        obj.id = this.current_solution_.id
      }
      obj.remediation_id = this.current_solution_.remediation_id
      obj.runtasks = this.current_obj.selected_runtasks
      obj.indicators = this.current_obj.selected_indicators
      obj.perimeter_id = this.current_obj.perimeter.id
      obj.runbook_id = this.current_obj.runbook ? this.current_obj.runbook.id : -1
      obj.runtasks_start_date = this.current_obj.runtasks_start_date
      delete obj.selected_runtasks
      delete obj.selected_indicators
      delete obj.perimeter
      delete obj.runbook
      delete obj.group_user
      if (this.current_obj.user) {
        obj.user = this.current_obj.user
      } else if (this.current_obj.group) {
        obj.group = this.current_obj.group
      }
      this.ready = false
      return obj
    },
    postSubmit() {
      this.ready = true
    },
    doSelect() {
      const _this = this
      if (this.do_post) {
        this.submit()
      } else {
        if (this.current_obj.perimeter && this.current_obj.perimeter.id) {
          this.setUserGroupSubmit(this.current_obj)
          this.$emit('selected', this.current_obj)
          this.$refs.form.hide()
        } else {
          this.$validator.validateAll('solution_fork_import').then((res) => {
            if (!res) {
              _this.$log.debug(this.$validator.errors)
            }
          })
        }
      }
    },
    preFormHandler() {
      const _this = this
      this.ready = false
      this.runtasks = []
      this.indicators = []
      this.$log.debug(this.current_solution_)
      if (!this.current_solution_) {
        this.$log.warn('No solution...')
        return
      }
      if (this.current_solution_ && this.current_solution_.id && this.type === 'run') {
        this.$http
          .get('/solution/' + this.current_solution_.id + '/rti')
          .then((resp) => {
            if (resp.data.ok) {
              _this.runtasks = resp.data.object.runtasks
              _this.indicators = resp.data.object.indicators
              _this.ready = true
            } else {
              _this.ready = true
              _this.$log.debug(resp.data)
            }
          })
          .catch((err) => {
            _this.ready = true
            this.apiError(err, false)
          })
      } else {
        _this.ready = true
      }
      if (this.current_solution_.solution && this.current_solution_.solution.id) {
        this.$set(this.current_obj, 'id', this.current_solution_.solution.id)
      } else if (this.current_solution_.solution_id) {
        this.$set(this.current_obj, 'id', this.current_solution_.solution_id)
      } else if (this.current_solution_.id) {
        this.$set(this.current_obj, 'id', this.current_solution_.id)
      }
      this.$set(this.current_obj, 'selected_indicators', [])
      this.$set(this.current_obj, 'selected_runtasks', [])
      this.$set(this.current_obj, 'runtasks_start_date', this.$moment().format('DD/MM/YYYY'))
      if (this.type === 'build') {
        if (this.program_options.length > 0) {
          this.$set(this.current_obj, 'program', this.program_options[0])
        } else {
          this.$set(this.current_obj, 'program', null)
        }
      } else if (this.type === 'run') {
        const default_prog = _this.runbook_options.find((opt) => opt.identifier === '_DEF')
        if (default_prog) {
          this.$set(_this.current_obj, 'runbook', default_prog)
        } else {
          this.$set(_this.current_obj, 'runbook', _this.runbook_options[0])
        }
      }
      if (this.current_obj.perimeter && this.current_obj.perimeter.id) {
        this.has_init_perimeter = true
      } else {
        this.has_init_perimeter = false
      }
    },
    async getFormOptions() {
      const [{ options: perimeterOptions }, { options: programOptions }, { options: runbookOptions }] =
        await Promise.all([
          getOptionsMixin('user/perimeters'),
          getOptionsMixin('remediation/programs'),
          getOptionsMixin('runtask/runbooks'),
        ])

      this.perimeter_options = perimeterOptions
      this.program_options = programOptions
      this.runbook_options = runbookOptions
    },
  },
  computed: {
    modal_add_title: function () {
      let t
      if (this.type === 'build') {
        t = this._('Operate the remediation')
      } else {
        t = this._('Operate the measure')
      }
      if (this.current_solution_) {
        t += ' ' + this.current_solution_.name
      }
      return t
    },
    indicators_f: function () {
      if (this.current_solution_) {
        if (this.current_solution_.public === true) {
          return this.indicators.filter((e) => e.public === true)
        } else {
          return this.indicators
        }
      }
      return []
    },
  },
  mounted() {
    this.sortGroups()
  },
}
</script>
