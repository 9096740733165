import { _, allRoles, isAuthorized, lazyLoadView, piloteAndAuditor, piloteOnly, setNavL2 } from '@/router/routing'
import type { Route } from 'vue-router'

export const actionPlansRoutes = {
  path: '/actions',
  name: 'actions',
  component: () => lazyLoadView(import('@/contexts/action-plans/ActionPlansListView.vue')),
  icon: 'stream',
  meta: {
    title: _('Action plans'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    doc_id: 'action_register',
  },
  children: [
    {
      path: ':id',
      name: 'actionlog',
      component: () => lazyLoadView(import('@/contexts/action-plans/ActionPlanView.vue')),
      icon: 'stream',
      meta: {
        title: null,
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'action_register_details',
      },
      children: [
        {
          path: 'mapping',
          name: 'actionRegistersMapping',
          component: () => lazyLoadView(import('@/contexts/action-plans/ActionPlanMappingView.vue')),
          meta: {
            title: null,
            is_authorized: (route: Route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteOnly,
            doc_id: 'action-registers-mapping',
          },
        },
      ],
    },
    {
      path: 'label/list',
      name: 'actionlog_label',
      component: () => lazyLoadView(import('@/contexts/action-plans/ActionPlanView.vue')),
      icon: 'stream',
      meta: {
        title: null,
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'action_register_details',
      },
    },
    {
      path: 'filtered/list',
      name: 'filteredaction',
      component: () => lazyLoadView(import('@/contexts/action-plans/ActionsFilterView.vue')),
      icon: 'stream',
      meta: {
        title: _('Advanced search'),
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'action_filtered_details',
      },
    },
  ],
}

export const actionPlansCatalogRoutes = {
  path: 'actions',
  name: 'actions_catalog',
  component: () => lazyLoadView(import('@/contexts/action-plans/ActionsCatalogView.vue')),
  meta: {
    title: _('Actions'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    doc_id: 'catalog',
    feature_id: 'CATALOG',
  },
}

export const actionPlansContributionRoutes = {
  path: 'local_actions',
  name: 'local_actions',
  component: () => lazyLoadView(import('@/contexts/action-plans/ActionPlansListContributionView.vue')),
  beforeEnter: setNavL2,
  icon: 'stream',
  meta: {
    title: _('Actions'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: allRoles,
    doc_id: 'local_action_register',
    nb_contribution: 'nb_actions',
  },
  children: [
    {
      path: ':id',
      name: 'local_action_registers',
      component: () => lazyLoadView(import('@/contexts/action-plans/ActionPlanContributionView.vue')),
      meta: {
        title: null,
        is_authorized: (route: Route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: allRoles,
        doc_id: 'local_action_registers_details',
      },
    },
  ],
}
