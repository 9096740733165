import { _, allRoles, isAuthorized, lazyLoadView, piloteAndAuditor, requiresInternal, setNavL2 } from '@/router/routing'
import type { Route } from 'vue-router'

export const recurringTasksRoutes = {
  path: '/runbooks',
  name: 'runbook',
  component: () => lazyLoadView(import('@/contexts/recurring-tasks/RunbooksListView.vue')),
  icon: 'clipboard-check',
  meta: {
    title: _('Recurring tasks'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    hidden_for_fp_assessment: true,
    doc_id: 'runbook',
    feature_id: 'RUNTASKS',
  },
  children: [
    {
      path: ':id',
      name: 'runtask',
      component: () => lazyLoadView(import('@/contexts/recurring-tasks/RunbookView.vue')),
      icon: 'clipboard-check',
      meta: {
        title: null,
        is_authorized: (route: Route) => isAuthorized(route),
        hidden_for_fp_assessment: true,
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'my_runtasks',
      },
    },
    {
      path: 'label/list',
      name: 'runtask_label',
      component: () => lazyLoadView(import('@/contexts/recurring-tasks/RunbookView.vue')),
      icon: 'clipboard-check',
      meta: {
        title: null,
        is_authorized: (route: Route) => isAuthorized(route),
        hidden_for_fp_assessment: true,
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'my_runtasks',
      },
    },
  ],
}

export const recurringTasksCatalogRoutes = {
  path: 'runtasks',
  name: 'runtasks',
  component: () => lazyLoadView(import('@/contexts/recurring-tasks/RecurringTasksCatalogView.vue')),
  meta: {
    title: _('Recurring tasks'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: false,
    requires_role: piloteAndAuditor,
    doc_id: 'catalog',
    feature_id: 'CATALOG',
  },
}

export const recurringTasksContributionRoutes = {
  path: 'runtasks',
  name: 'glrunbook',
  component: () => lazyLoadView(import('@/contexts/recurring-tasks/RunbookView.vue')),
  beforeEnter: setNavL2,
  icon: 'clipboard-check',
  meta: {
    title: _('Recurring tasks'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: allRoles,
    requires_internal: () => requiresInternal(),
    hidden_for_fp_assessment: true,
    doc_id: 'my_runtasks',
    nb_contribution: 'nb_runtasks',
  },
}
