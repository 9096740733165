import MainContent from '@/components/layout/main-content.vue'
import { _, allRoles, isAuthorized, lazyLoadView, piloteAndAuditor, piloteOnly } from '@/router/routing'
import { passThroughComponent } from '@/plugins/pass-through-component'
import { recurringTasksRoutes } from '@/contexts/recurring-tasks/RecurringTasks.routes'
import { incidentsRoutes } from '@/contexts/incidents/Incidents.routes'
import { gapsRoutes } from '@/contexts/gaps/Gaps.routes'
import { exemptionsRoutes } from '@/contexts/exemptions/Exemptions.routes'
import { projectsSecurityRoutes } from '@/contexts/projects-security/ProjectsSecurity.routes'
import { actionPlansRoutes } from '@/contexts/action-plans/ActionPlans.routes'

export const operationRoutes = {
  path: '/ops',
  name: 'compliance',
  component: MainContent,
  meta: {
    title: _('operations'),
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    is_authorized: (route) => isAuthorized(route),
  },
  children: [
    {
      path: '/campaigns',
      name: 'campaign',
      component: () => lazyLoadView(import('@/components/pages/campaigns.vue')),
      icon: 'check-square',
      meta: {
        title: _('Evaluations'),
        is_authorized: (route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'campaign',
        feature_id: 'CAMPAIGNS',
      },
      children: [
        {
          path: 'results/:campaign_id/:perimeter_id?',
          name: 'gcampaign_details',
          component: () => lazyLoadView(import('@/components/pages/campaign-details.vue')),
          meta: {
            title: null,
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'campaign_details',
          },
          children: [
            {
              path: 'eval-details',
              name: 'campaign_eval_details',
              component: () => lazyLoadView(import('@/components/pages/campaign-eval.vue')),
              meta: {
                title: null,
                is_authorized: (route) => isAuthorized(route),
                requires_auth: true,
                requires_tenant: true,
                requires_role: allRoles,
                challenge_mode: true,
                doc_id: 'campaign_eval_details',
              },
            },
          ],
        },
      ],
    },
    actionPlansRoutes,
    recurringTasksRoutes,
    gapsRoutes,
    exemptionsRoutes,
    incidentsRoutes,
    projectsSecurityRoutes,
    {
      path: '/suppliers',
      component: {
        render: passThroughComponent,
      },
      icon: 'people-arrows',
      meta: {
        title: _('Suppliers'),
        alt_title: _('Supplier management'),
        requires_auth: true,
        requires_role: piloteOnly,
        is_authorized: (route) => isAuthorized(route),
        requires_feature_flag: 'supplier_management',
      },

      children: [
        {
          path: '',
          name: 'suppliers',
          component: () => lazyLoadView(import('@/views/Suppliers/SuppliersListView.vue')),
          icon: 'users',

          meta: {
            requires_auth: true,
            requires_role: piloteOnly,
            is_authorized: (route) => isAuthorized(route),
            doc_id: 'suppliers',
          },
        },
        {
          path: 'results/:id',
          name: 'suppliers-results',
          component: () => lazyLoadView(import('@/views/Suppliers/SuppliersResultsView.vue')),
          icon: 'users',

          meta: {
            title: null,
            requires_auth: true,
            requires_role: piloteOnly,
            is_authorized: (route) => isAuthorized(route),
          },
        },
        {
          path: ':id',
          name: 'supplier-validated',
          component: () => lazyLoadView(import('@/views/Suppliers/SupplierValidatedView.vue')),
          icon: 'users',

          meta: {
            title: null,
            requires_auth: true,
            requires_role: piloteOnly,
            is_authorized: (route) => isAuthorized(route),
          },
        },
        {
          path: ':id/details',
          name: 'supplier-details',
          component: () => lazyLoadView(import('@/views/Suppliers/SupplierDetailsView.vue')),
          icon: 'users',

          meta: {
            title: null,
            requires_auth: true,
            requires_role: piloteOnly,
            is_authorized: (route) => isAuthorized(route),
          },
        },
      ],
    },
  ],
}
