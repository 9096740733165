import MainContent from '@/components/layout/main-content.vue'
import {
  _,
  allRoles,
  isAuthorized,
  lazyLoadView,
  piloteAndAuditor,
  piloteAndContributor,
  piloteOnly,
  requiresInternal,
  setComponentByRole,
  setNavL2,
} from '@/router/routing'
import { incidentsContributionRoutes } from '@/contexts/incidents/Incidents.routes'
import { recurringTasksContributionRoutes } from '@/contexts/recurring-tasks/RecurringTasks.routes'
import { exemptionsContributionRoutes } from '@/contexts/exemptions/Exemptions.routes'
import { projectsSecurityContributionRoutes } from '@/contexts/projects-security/ProjectsSecurity.routes'
import { actionPlansContributionRoutes } from '@/contexts/action-plans/ActionPlans.routes'
import { risksContributionRoutes } from '@/contexts/risks/Risks.routes'

export const myRoutes = {
  path: '/my',
  name: 'myspace',
  component: MainContent,
  meta: {
    title: _('my space'),
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: allRoles,
    is_authorized: (route) => isAuthorized(route),
  },
  children: [
    {
      path: '/ui-kit',
      component: () => lazyLoadView(import('@/components/ui-kit/ui-kit.vue')),
      icon: 'toolbox',
      meta: {
        title: _('UI kit'),
        is_authorized: (route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: allRoles,
      },
      children: [
        {
          path: '',
          // name: 'ui-kit-home',
          name: 'ui-kit',
          component: () => lazyLoadView(import('@/components/ui-kit/ui-kit-home.vue')),
          icon: 'toolbox',
          meta: {
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'home',
          },
        },
        {
          path: 'ui-scale',
          name: 'ui-scale',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-scale.vue')),
          icon: 'toolbox',
          meta: {
            title: 'Scales',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-scale',
          },
        },
        {
          path: 'ui-slider',
          name: 'ui-slider',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-slider.vue')),
          icon: 'toolbox',
          meta: {
            title: 'Slider',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-slider',
          },
        },
        {
          path: 'ui-form-field',
          name: 'ui-form-field',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-form-field.vue')),
          icon: 'toolbox',
          meta: {
            title: 'Form Field',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-form-field',
          },
        },
        {
          path: 'ui-nds-button',
          name: 'ui-nds-button',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-button.vue')),
          meta: {
            title: 'NdsButton',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-button',
          },
        },
        {
          path: 'ui-nds-table',
          name: 'ui-nds-table',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-table.vue')),
          meta: {
            title: 'NdsTable',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-table',
          },
        },
        {
          path: 'ui-nds-icon',
          name: 'ui-nds-icon',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-icon.vue')),
          meta: {
            title: 'NdsIcon',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-icon',
          },
        },
        {
          path: 'ui-nds-modal',
          name: 'ui-nds-modal',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-modal.vue')),
          meta: {
            title: 'NdsModal',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-modal',
          },
        },
        {
          path: 'ui-nds-spinner',
          name: 'ui-nds-spinner',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-spinner.vue')),
          meta: {
            title: 'NdsSpinner',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-spinner',
          },
        },
        {
          path: 'ui-nds-input-radio',
          name: 'ui-nds-input-radio',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-input-radio.vue')),
          meta: {
            title: 'NdsInputRadio',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-input-radio',
          },
        },
        {
          path: 'ui-nds-drop-down',
          name: 'ui-nds-drop-down',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-drop-down.vue')),
          meta: {
            title: 'NdsDropDown',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-drop-down',
          },
        },
        {
          path: 'ui-nds-input',
          name: 'ui-nds-input',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-input.vue')),
          meta: {
            title: 'NdsInput',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-input',
          },
        },
        {
          path: 'ui-nds-input-label',
          name: 'ui-nds-input-label',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-input-label.vue')),
          meta: {
            title: 'NdsInputLabel',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-input-label',
          },
        },
        {
          path: 'ui-nds-date-picker',
          name: 'ui-nds-date-picker',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-date-picker.vue')),
          meta: {
            title: 'NdsDatePicker',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-date-picker',
          },
        },
        {
          path: 'ui-nds-pagination',
          name: 'ui-nds-pagination',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-pagination.vue')),
          meta: {
            title: 'NdsPagination',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-pagination',
          },
        },
        {
          path: 'ui-nds-toast',
          name: 'ui-nds-toast',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-toast.vue')),
          meta: {
            title: 'Toast plugin',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-toast',
          },
        },
        {
          path: 'ui-nds-card',
          name: 'ui-nds-card',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-card.vue')),
          meta: {
            title: 'NdsCard',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-card',
          },
        },
        {
          path: 'ui-nds-progress-bar',
          name: 'ui-nds-progress-bar',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-progress-bar.vue')),
          meta: {
            title: 'NdsProgressBar',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-progress-bar',
          },
        },
        {
          path: 'ui-nds-stepper',
          name: 'ui-nds-stepper',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-stepper.vue')),
          meta: {
            title: 'NdsStepper',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-stepper',
          },
        },
        {
          path: 'ui-nds-input-checkbox',
          name: 'ui-nds-input-checkbox',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-input-checkbox.vue')),
          meta: {
            title: 'NdsInputCheckbox',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-input-checkbox',
          },
        },
        {
          path: 'ui-nds-textarea',
          name: 'ui-nds-textarea',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-textarea.vue')),
          meta: {
            title: 'NdsTextarea',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-textarea',
          },
        },
        {
          path: 'ui-nds-answer-button',
          name: 'ui-nds-answer-button',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-answer-button.vue')),
          meta: {
            title: 'NdsAnswerButton',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-answer-button',
          },
        },
        {
          path: 'ui-nds-tree-view',
          name: 'ui-nds-tree-view',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-tree-view.vue')),
          meta: {
            title: 'NdsTreeView',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-tree-view',
          },
        },
        {
          path: 'ui-nds-alert',
          name: 'ui-nds-alert',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-alert.vue')),
          meta: {
            title: 'NdsAlert',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-alert',
          },
        },
        {
          path: 'ui-nds-comment',
          name: 'ui-nds-comment',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-comment.vue')),
          meta: {
            title: 'NdsComment',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-comment',
          },
        },
        {
          path: 'ui-nds-score-badge',
          name: 'ui-nds-score-badge',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-score-badge.vue')),
          meta: {
            title: 'NdsScoreBadge',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-score-badge',
          },
        },
        {
          path: 'ui-nds-score-answer-badge',
          name: 'ui-nds-score-answer-badge',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-score-answer-badge.vue')),
          meta: {
            title: 'NdsScoreAnswerBadge',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-score-answer-badge',
          },
        },
        {
          path: 'ui-nds-score',
          name: 'ui-nds-score',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-score.vue')),
          meta: {
            title: 'NdsScore',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-score',
          },
        },
        {
          path: 'ui-nds-score-info',
          name: 'ui-nds-score-info',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-score-info.vue')),
          meta: {
            title: 'NdsScoreInfo',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-score-info',
          },
        },
        {
          path: 'ui-nds-tooltip',
          name: 'ui-nds-tooltip',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-tooltip.vue')),
          meta: {
            title: 'NdsTooltip',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-tooltip',
          },
        },
        {
          path: 'ui-nds-option',
          name: 'ui-nds-option',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-option.vue')),
          meta: {
            title: 'NdsOption',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-option',
          },
        },
        {
          path: 'ui-nds-upload-file',
          name: 'ui-nds-upload-file',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-upload-file.vue')),
          meta: {
            title: 'NdsUploadFile',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-upload-file',
          },
        },
        {
          path: 'ui-nds-panel',
          name: 'ui-nds-panel',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-panel.vue')),
          meta: {
            title: 'NdsPanel',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-panel',
          },
        },
        {
          path: 'ui-nds-tag',
          name: 'ui-nds-tag',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-tag.vue')),
          meta: {
            title: 'NdsTag',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-tag',
          },
        },
        {
          path: 'ui-nds-form-input',
          name: 'ui-nds-form-input',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-nds-form-input.vue')),
          meta: {
            title: 'NdsFormInput',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-nds-form-input',
          },
        },
        {
          path: 'ui-i18n',
          name: 'ui-i18n',
          component: () => lazyLoadView(import('@/components/ui-kit/pages/ui-i18n.vue')),
          meta: {
            title: 'I18n',
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'ui-i18n',
          },
        },
      ],
    },
    {
      path: '/home',
      name: 'home',
      component: () => lazyLoadView(setComponentByRole('home')),
      icon: 'home',
      meta: {
        title: _('Cockpit'),
        onboarding_page: true,
        is_authorized: (route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: allRoles,
        doc_id: 'home',
      },
      children: [
        {
          path: 'name',
          name: 'onb-customer-name',
          component: () => lazyLoadView(import('@/components/pages/onb-customer-name.vue')),
          icon: 'signal',
          meta: {
            title: _("1.1 - Organisation's name"),
            do_not_show: true,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'access_control',
          name: 'onb-access-control',
          component: () => lazyLoadView(import('@/components/pages/onb-access-control.vue')),
          icon: 'signal',
          meta: {
            do_not_show: true,
            title: _('Access control'),
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'policy-industry',
          name: 'onb-policy-industry',
          component: () => lazyLoadView(import('@/components/pages/onb-policy-industry.vue')),
          icon: 'signal',
          meta: {
            title: _('Onboarding - Proposed policies based on industry and activity sector'),
            do_not_show: true,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'policy-regulations',
          name: 'onb-policy-regulations',
          component: () => lazyLoadView(import('@/components/pages/onb-policy-regulation.vue')),
          icon: 'signal',
          meta: {
            title: _('Onboarding - Regulatory and legal requirements'),
            do_not_show: true,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'policy-certifications',
          name: 'onb-policy-certifications',
          component: () => lazyLoadView(import('@/components/pages/onb-policy-certifications.vue')),
          icon: 'signal',
          meta: {
            title: _('Onboarding - Certifications'),
            do_not_show: true,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'perimeter-mapping',
          name: 'onb-perimeter-mapping',
          component: () => lazyLoadView(import('@/components/pages/onb-perimeter-mapping.vue')),
          icon: 'signal',
          meta: {
            title: _('Onboarding - Perimeter mapping'),
            do_not_show: true,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'action-plans',
          name: 'onb-action-plans',
          component: () => lazyLoadView(import('@/components/pages/onb-action-plans.vue')),
          icon: 'signal',
          meta: {
            title: _('Onboarding - Action plans'),
            do_not_show: true,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_action_plans',
          },
        },
        {
          path: ':id/action-plans-mapping',
          name: 'onb-action-plans-mapping',
          component: () => lazyLoadView(import('@/contexts/action-plans/ActionPlanMappingView.vue')),
          meta: {
            do_not_show: true,
            title: null,
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteOnly,
            doc_id: 'action-registers-mapping',
          },
        },
        {
          path: 'policy-import-mapping',
          name: 'onb-policy-import-mapping',
          component: () => lazyLoadView(import('@/components/pages/onb-policy-import-mapping.vue')),
          icon: 'signal',
          meta: {
            title: _('Onboarding - Import existing policies'),
            do_not_show: true,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'policy-import-mapping/:id',
          name: 'onb_policy_version_catalog',
          component: () => lazyLoadView(import('@/components/pages/policy-version.vue')),
          meta: {
            title: null,
            requires_auth: true,
            do_not_show: true,
            requires_tenant: false,
            sidebar: false,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteAndAuditor,
            doc_id: 'policy_details',
          },
        },
        {
          path: 'security-objectives',
          name: 'onb-security-objectives',
          component: () => lazyLoadView(import('@/components/pages/onb-security-objectives.vue')),
          icon: 'signal',
          meta: {
            title: _('Onboarding - Security objectives'),
            do_not_show: true,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_access_control',
          },
        },
        {
          path: 'connectors',
          name: 'onb-connectors',
          component: () => lazyLoadView(import('@/components/pages/onb-connectors.vue')),
          icon: 'signal',
          meta: {
            title: _('Onboarding - Connectors'),
            do_not_show: true,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_connectors',
          },
        },
        {
          path: 'security-bases',
          name: 'onb-security-bases',
          component: () => lazyLoadView(import('@/components/pages/onb-security-bases.vue')),
          icon: 'signal',
          meta: {
            title: _('Onboarding - Security bases'),
            do_not_show: true,
            is_authorized: (route) => isAuthorized(route),
            requires_role: piloteOnly,
            requires_auth: true,
            requires_tenant: true,
            doc_id: 'ob_security_bases',
          },
        },
      ],
    },
    {
      path: '/cockpit',
      name: 'cockpit',
      component: () => lazyLoadView(setComponentByRole('cockpit')),
      icon: 'home-alt',
      meta: {
        sidebar: false,
        title: _('Cockpit'),
        is_authorized: (route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: allRoles,
        doc_id: 'home',
      },
    },
    {
      path: '/contributions',
      name: 'contributions',
      component: MainContent,
      icon: 'comment-alt-plus',
      meta: {
        title: _('Contributions'),
        dummy: true,
        multi: true,
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndContributor,
        is_authorized: (route) => isAuthorized(route),
      },
      children: [
        {
          path: 'evaluations',
          name: 'evaluation',
          component: () => lazyLoadView(import('@/components/pages/campaigns-local.vue')),
          beforeEnter: setNavL2,
          icon: 'check-square',
          meta: {
            title: _('Evaluations'),
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            doc_id: 'my_evaluations',
            nb_contribution: 'nb_evaluations',
          },
          children: [
            {
              path: 'eval/:campaign_id/:perimeter_id?/:control_id?/:questionnaire_id?',
              name: 'campaign_eval',
              component: () => lazyLoadView(import('@/components/pages/campaign-eval.vue')),
              meta: {
                title: null,
                is_authorized: (route) => isAuthorized(route),
                requires_auth: true,
                requires_tenant: true,
                requires_role: allRoles,
                doc_id: 'campaign_eval',
              },
            },
            {
              path: 'results/:campaign_id/:perimeter_id?',
              name: 'campaign_details',
              component: () => lazyLoadView(import('@/components/pages/campaign-details.vue')),
              meta: {
                title: null,
                is_authorized: (route) => isAuthorized(route),
                requires_auth: true,
                requires_tenant: true,
                requires_role: allRoles,
                doc_id: 'campaign_details',
              },
            },
          ],
        },
        actionPlansContributionRoutes,
        recurringTasksContributionRoutes,
        {
          path: 'metrics',
          name: 'gmetrics_entry',
          component: () => lazyLoadView(import('@/contexts/metrics/MetricEntryView.vue')),
          beforeEnter: setNavL2,
          icon: 'chart-scatter',
          meta: {
            title: _('Metrics'),
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: allRoles,
            requires_internal: () => requiresInternal(),
            doc_id: 'my_metrics',
            nb_contribution: 'nb_metrics',
          },
        },
        exemptionsContributionRoutes,
        risksContributionRoutes,
        incidentsContributionRoutes,
        projectsSecurityContributionRoutes,
      ],
    },
  ],
}
