<template>
  <td
    class="valign-middle text-center no-row-click w-actions-1"
    :class="{ 'bg-light': rows_selected > 0 && !radio_button }"
    v-on="!rowsSelectable ? { click: (event) => selectCheckbox(event) } : {}"
  >
    <span
      v-if="disabled"
      :title="_('Object cannot be selected')"
      v-b-tooltip
    >
      <p-check
        :disabled="disabled"
        class="p-icon"
        :class="class_button"
        color="primary"
        v-model="checkboxSelected"
      >
        <i
          class="icon far fa-check"
          slot="extra"
        ></i>
      </p-check>
    </span>
    <p-check
      v-else
      :disabled="disabled"
      class="p-icon"
      :class="class_button"
      color="primary"
      v-model="checkboxSelected"
    >
      <i
        class="icon far fa-check"
        slot="extra"
      ></i>
    </p-check>
  </td>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    rowsSelectable: {
      type: Boolean,
      default: false,
    },
    rowSelected: {
      type: Boolean,
      required: true,
    },
    radio_button: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    rows_selected: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data: function () {
    return {
      checkboxSelected: false,
      class_button: '',
    }
  },
  methods: {
    selectCheckbox(event) {
      event.stopPropagation()
      if (this.disabled) {
        return
      }
      if (this.checkboxSelected) {
        this.$emit('remove-row', event.shiftKey)
      } else {
        this.$emit('add-row', event.shiftKey)
      }
    },
  },
  mounted() {
    this.class_button = this.radio_button ? 'p-round' : ''
    this.checkboxSelected = this.rowSelected
  },
  watch: {
    rowSelected(newVal) {
      if (!this.disabled) {
        this.$log.debug('ROW SELECCTED CHANGED', newVal)
        this.checkboxSelected = newVal
      }
    },
  },
}
</script>
