<template>
  <b-modal
    hide-footer
    title-class="upsell-risk"
    :title="_('Confirm your free trial')"
    id="confirm-trial-modal"
    no-close-on-backdrop
    size="m"
    name="confirm-trial-modal"
    no-close-on-esc
    @close="closeModal"
  >
    <b-container>
      <b-row class="mb-4">
        <span class="title"> <translate>Your free trial will end on</translate>&nbsp; </span>
        <span class="text-secondary text-bold">
          {{ trialEndDate() }}
        </span>
        .
      </b-row>
      <b-row class="mb-4">
        <form-field-checkbox
          :value="send_reminder"
          class="mb-0"
          name="checkbox_trial"
          v-model="send_reminder"
          data-testid="checkbox-trial"
        >
        </form-field-checkbox>
        <span class="title">
          <translate>Receive notification before end ?</translate>
        </span>
      </b-row>

      <b-row class="justify-content-end mr-1">
        <b-button
          class="wizard-btn mt-button mr-2"
          @click="closeModal"
        >
          <span>
            <translate key="add">Cancel</translate>
          </span>
        </b-button>
        <app-button
          :custom_icon="''"
          @click="startFreeTrial"
        >
          <span>
            <translate key="add">Confirm</translate>
          </span>
        </app-button>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import AppButton from '../ui/app-button.vue'
import FormFieldCheckbox from '@/components/ui/form-field-checkbox.vue'
export default {
  name: 'confirm-trial-modal',
  props: {
    option_type: {
      type: String,
      default: null,
    },
  },
  components: { FormFieldCheckbox, AppButton },
  data() {
    return {
      send_reminder: true,
      trial_start_date: null,
    }
  },
  methods: {
    closeModal: function () {
      this.$bvModal.hide('confirm-trial-modal')
    },
    startFreeTrial: function () {
      if (
        this.$store.getters.getTrialOption(this.option_type) &&
        this.$store.getters.getTrialOption(this.option_type).id
      ) {
        this.$api
          .put('customer/customer_option/' + this.$store.getters.getTrialOption(this.option_type).id, {
            date_start: this.trial_start_date,
            type: this.option_type,
          })
          .then((resp) => {
            this.$log.debug('PUT TRIAL')
            if (this.option_type === 'RISK_ANALYSIS') {
              this.$store.commit('set_risk_analysis_enable', true)
              this.$store.commit('set_customer_options', resp.data.object)
            }
            if (this.option_type === 'ISP') {
              this.$store.commit('set_isp_enable', true)
              this.$store.commit('set_customer_options', resp.data.object)
              this.$router.push({ path: '/projects' })
            }
            this.closeModal()
            this.$emit('closeAnalysisModal')
          })
          .catch((err) => {
            this.$log.debug(err)
          })
      } else {
        this.$api
          .post('customer/customer_option', {
            send_reminder: this.send_reminder,
            type: this.option_type,
          })
          .then((resp) => {
            this.$log.debug('START TRIAL')
            if (this.option_type === 'RISK_ANALYSIS') {
              this.$store.commit('set_risk_analysis_enable', true)
              this.$store.commit('set_customer_options', resp.data.object)
            }
            if (this.option_type === 'ISP') {
              this.$store.commit('set_isp_enable', true)
              this.$store.commit('set_customer_options', resp.data.object)
              this.$router.push({ path: '/projects' })
            }
            this.closeModal()
            this.$emit('closeAnalysisModal')
          })
          .catch((err) => {
            this.$log.debug(err)
          })
      }
    },
    trialEndDate() {
      const today = new Date()
      today.setDate(today.getDate() + 15)

      if (this.$store.getters.lang === 'fr') {
        return today.toLocaleString('fr-FR', { month: 'long', day: 'numeric', year: 'numeric' })
      } else {
        return today.toLocaleString('en-GB', { month: 'long', day: 'numeric', year: 'numeric' })
      }
    },
  },
  mounted() {
    const today = new Date()
    today.setDate(today.getDate())
    this.trial_start_date = today.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })
  },
}
</script>
