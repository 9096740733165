import {
  business_type_options,
  cyber_maturity_options,
  dot_placement_options,
  eval_scale_remediation_options,
  exemption_status_options,
  import_status_options,
  incident_status_options,
  indicator_type_options,
  is_public_options,
  metadata_property_type_options,
  mfa_mode_options,
  perimeter_agg_type_options,
  perimeter_score_type_options,
  perimeter_type_options,
  periodicity_options,
  periodicity_options_dashboard,
  periodicity_options_runtask,
  remediation_source_options,
  remediation_template_type_options,
  remediation_type_options,
  risk_treatment_status_options,
  run_task_types,
  time_agg_type_options,
} from '@/composables/UseOptionsMixin'

/**
 * This is a mixin used to fetch options for selects from the backend.
 * It also contains the options of the listed types used in the platform (authentication type for example)
 * @public
 */
export default {
  data: function () {
    return {
      remediation_template_type_options: remediation_template_type_options(),
      remediation_type_options: remediation_type_options(),
      remediation_source_options: remediation_source_options(),
      exemption_status_options: exemption_status_options(),
      incident_status_options: incident_status_options(),
      risk_treatment_status_options: risk_treatment_status_options(),
      indicator_type_options: indicator_type_options(),
      perimeter_agg_type_options: perimeter_agg_type_options(),
      time_agg_type_options: time_agg_type_options(),
      perimeter_score_type_options: perimeter_score_type_options(),
      mfa_mode_options: mfa_mode_options(),
      periodicity_options: periodicity_options(),
      periodicity_options_dashboard: periodicity_options_dashboard(),
      periodicity_options_runtask: periodicity_options_runtask(),
      run_task_types: run_task_types(),
      eval_scale_remediation_options: eval_scale_remediation_options(),
      perimeter_type_options: perimeter_type_options(),
      import_status_options: import_status_options(),
      dot_placement_options: dot_placement_options(),
      business_type_options: business_type_options(),
      cyber_maturity_options: cyber_maturity_options(),
      is_public_options: is_public_options(),
      metadata_property_type_options: metadata_property_type_options(),
    }
  },
}
