import Vue from 'vue'
import router from '../router/router'
import { config } from '@/config.settings'
import * as Sentry from '@sentry/vue'

/**
 * sentry_init_param() Initializes the SDK and optionally integrations.
 *
 * dsn: The DSN tells the SDK where to send the events to.
 * environment: Sets the environment. This string is freeform
 *
 * If you use Vue-router explicitly in your project you can enable the sentry.vueRouterInstrumentation
 * replayIntegration(): enable session replay.
 */
export const sentry_init_param = {
  Vue,
  dsn: config.sentry_dsn,
  // Environments tell you where an error occurred, whether that's in your production system, your staging server, or elsewhere.
  environment: config.dev === true ? config.app_name + '.dev' : config.app_name + '.prod',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  //tracePropagationTargets: ["localhost", /^https:\/\/your_server\.io\/api/],
}
