import apiMethod from '../api_call'
import Vue from 'vue'
import { sortOrder } from '@/helpers/sort-helper'

function initialState() {
  return {
    lang: '',
    id: null,
    identifier: null,
    status_message: null,
    name: null,
    name_fr: null,
    name_en: null,
    edit_right: null,
    questionnaires: [],
    results: [],
    text_right: null,
    questionnaire_right: null,
    is_catalog: false,
    questionnaire: {
      accumulators: [],
      sections: [],
    },
    show_accumulator_modal: false,
  }
}

const questionnaireStore = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetQuestionnaireStore(state) {
      const initState = initialState()
      Object.keys(initState).forEach((key) => {
        if (key !== 'questionnaires') {
          state[key] = initState[key]
        }
      })
    },
    questionnaires(state, questionnaires) {
      state.questionnaires = questionnaires
    },
    setLang(state, value) {
      state.lang = value
    },
    setId(state, newId) {
      state.id = newId
    },
    setIdentifier(state, newIdentifier) {
      state.identifier = newIdentifier
    },
    setName(state, newName) {
      state.name = newName
    },
    setNameFr(state, newNameFR) {
      state.name_fr = newNameFR
    },
    setNameEN(state, newNameEN) {
      state.name_en = newNameEN
    },
    setQuestionnaireRight(state, newQuestionnaireRight) {
      state.questionnaire_right = newQuestionnaireRight
    },
    setTextRight(state, newTextRight) {
      state.text_right = newTextRight
    },
    setIsCatalog(state, newIsCatalog) {
      state.is_catalog = newIsCatalog
    },
    setQuestionnaire(state, newQuestionnaire) {
      state.questionnaire = newQuestionnaire
    },

    setSections(state, sections) {
      state.questionnaire.sections = sections
    },
    setSection(state, { index_section, new_section }) {
      if (index_section === -1) {
        state.questionnaire.sections.push(new_section)
      } else {
        state.questionnaire.sections[index_section] = new_section
      }
    },
    setAccumulator(state, { new_accumulator }) {
      state.questionnaire.accumulators.push(new_accumulator)
    },
    setAccumulators(state, { accumulators }) {
      state.questionnaire.accumulators = accumulators
    },
    setResults(state, results) {
      state.results = results
    },
    deleteSection(state, index_accumulator) {
      state.questionnaire.sections.splice(index_accumulator, 1)
    },
    deleteSectionCondition(state, index_section) {
      state.questionnaire.sections[index_section].condition = {
        question: null,
        operator: null,
        value: null,
        answer: null,
      }
    },
    deleteQuestionCondition(state, { index_section, index_question }) {
      state.questionnaire.sections[index_section].questions[index_question].condition = {
        question: null,
        operator: null,
        value: null,
        answer: null,
      }
    },
    deleteAccumulator(state, index_section) {
      state.questionnaire.accumulators.splice(index_section, 1)
    },
    deleteQuestion(state, { index_section, index_question }) {
      state.questionnaire.sections[index_section].questions.splice(index_question, 1)
    },
    deleteItem(state, { index_section, index_question, index_item }) {
      state.questionnaire.sections[index_section].questions[index_question].items.splice(index_item, 1)
    },
    deleteItems(state, { index_section, index_question }) {
      state.questionnaire.sections[index_section].questions[index_question].items.splice(
        0,
        state.questionnaire.sections[index_section].questions[index_question].items.length
      )
    },
    setQuestions(state, { section_id, new_questions }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      state.questionnaire.sections[index_section].questions = new_questions
    },
    setItems(state, { section_id, question_id, new_items }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      state.questionnaire.sections[index_section].questions[index_question].items = new_items
    },
    setQuestion(state, { index_section, index_question, new_question }) {
      if (index_question === -1) {
        state.questionnaire.sections[index_section].questions.push(new_question)
      } else {
        state.questionnaire.sections[index_section].questions[index_question] = new_question
      }
    },

    addQuestionItem(state, { index_section, index_question, new_item }) {
      state.questionnaire.sections[index_section].questions[index_question].items.push(new_item)
    },
    setQuestionItem(state, { index_section, index_question, index_item, new_item }) {
      state.questionnaire.sections[index_section].questions[index_question].items[index_item] = new_item
    },

    toggleAccumulatorModal(state) {
      state.show_accumulator_modal = !state.show_accumulator_modal
    },

    updateSectionLabel(state, { section_id, label }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const label_lang = state.lang == 'fr' ? 'label_fr' : 'label_en'
      Vue.set(state.questionnaire.sections[index_section], label_lang, label)
    },
    updateSectionIdentifier(state, { section_id, identifier }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      Vue.set(state.questionnaire.sections[index_section], 'identifier', identifier)
    },
    updateQuestionIdentifier(state, { section_id, question_id, identifier }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      Vue.set(state.questionnaire.sections[index_section].questions[index_question], 'identifier', identifier)
    },
    updateQuestionItemLabel(state, { section_id, question_id, item_id, label }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      const index_answer = state.questionnaire.sections[index_section].questions[index_question].items.findIndex(
        (old_item) => old_item.id == item_id
      )
      const label_lang = state.lang == 'fr' ? 'label_fr' : 'label_en'
      Vue.set(
        state.questionnaire.sections[index_section].questions[index_question].items[index_answer],
        label_lang,
        label
      )
    },
    updateQuestionItemDescription(state, { section_id, question_id, item_id, description }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      const index_answer = state.questionnaire.sections[index_section].questions[index_question].items.findIndex(
        (old_item) => old_item.id == item_id
      )
      const label_lang = state.lang == 'fr' ? 'description_fr' : 'description_en'
      Vue.set(
        state.questionnaire.sections[index_section].questions[index_question].items[index_answer],
        label_lang,
        description
      )
    },
    updateQuestionItemAccumulator(state, { section_id, question_id, item_id, accumulator_value }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      const index_answer = state.questionnaire.sections[index_section].questions[index_question].items.findIndex(
        (old_item) => old_item.id == item_id
      )
      Vue.set(
        state.questionnaire.sections[index_section].questions[index_question].items[index_answer],
        'accumulator_value',
        accumulator_value
      )
    },

    updateQuestionLabel(state, { section_id, question_id, label }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      const label_lang = state.lang == 'fr' ? 'label_fr' : 'label_en'
      Vue.set(state.questionnaire.sections[index_section].questions[index_question], label_lang, label)
    },
    updateQuestionRequired(state, { section_id, question_id, required }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      Vue.set(state.questionnaire.sections[index_section].questions[index_question], 'required', required)
    },
    updateQuestionType(state, { section_id, question_id, type }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      Vue.set(state.questionnaire.sections[index_section].questions[index_question], 'type', type)
    },
    updateQuestionCondition(state, { section_id, question_id, condition }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      Vue.set(state.questionnaire.sections[index_section].questions[index_question], 'condition', condition)
    },
    updateSectionCondition(state, { section_id, condition }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      Vue.set(state.questionnaire.sections[index_section], 'condition', condition)
    },
    updateQuestionDescription(state, { section_id, question_id, label }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      const label_lang = state.lang == 'fr' ? 'description_fr' : 'description_en'
      Vue.set(state.questionnaire.sections[index_section].questions[index_question], label_lang, label)
    },
  },
  actions: {
    SET_QUESTIONNAIRES({ commit }, { questionnaires }) {
      commit('questionnaires', questionnaires)
    },
    SET_LANG({ commit }, value) {
      commit('setLang', value)
    },
    SET_QUESTIONS_ITEMS_LABEL({ commit }, { section_id, question_id, item_id, label }) {
      commit('updateQuestionItemLabel', { section_id, question_id, item_id, label })
    },
    SET_QUESTIONS_ITEMS_DESCRIPTION({ commit }, { section_id, question_id, item_id, description }) {
      commit('updateQuestionItemDescription', { section_id, question_id, item_id, description })
    },
    SET_CONDITION_SECTION({ commit, dispatch }, { section_id, condition }) {
      commit('updateSectionCondition', { section_id, condition })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    SET_CONDITION_QUESTION({ commit, dispatch }, { section_id, question_id, condition }) {
      commit('updateQuestionCondition', { section_id, question_id, condition })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    SET_QUESTIONS_ITEMS_ACCUMULATOR({ commit }, { section_id, question_id, item_id, accumulator_value }) {
      commit('updateQuestionItemAccumulator', { section_id, question_id, item_id, accumulator_value })
    },
    SET_QUESTIONS_LABEL({ commit }, { section_id, question_id, label }) {
      commit('updateQuestionLabel', { section_id, question_id, label })
    },
    SET_QUESTIONS_REQUIRED({ commit, dispatch }, { section_id, question_id, required }) {
      commit('updateQuestionRequired', { section_id, question_id, required })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    SET_TYPE_QUESTION({ commit }, { section_id, question_id, type }) {
      commit('updateQuestionType', { section_id, question_id, type })
    },
    SET_QUESTIONS_DESCRIPTION({ commit }, { section_id, question_id, label }) {
      commit('updateQuestionDescription', { section_id, question_id, label })
    },
    PUSH_QUESTIONNAIRE({ state }) {
      state.status_message = 'PROGRESS'
      // method check intégrité JSON
      let isOk = true
      let questions = []
      state.questionnaire.sections.every((section) => {
        isOk =
          Vue.prototype.checkIdentifier(section.identifier, 'SECTION', state.questionnaire, section.id) &&
          Vue.prototype.checkLabel(section.label_en, section.label_fr, 'SECTION', state.questionnaire, section.id)
        if (!isOk) {
          state.status_message = 'ERRORS'
          return isOk
        }
        if (section.questions && section.questions.length > 0) {
          questions = questions.concat(section.questions)
        }
        return isOk
      })
      if (isOk) {
        questions.every((question) => {
          isOk =
            Vue.prototype.checkIdentifier(question.identifier, 'QUESTION', state.questionnaire, question.id) &&
            Vue.prototype.checkLabel(question.label_en, question.label_fr, 'QUESTION', state.questionnaire, question.id)
          Vue.prototype.$log.debug(isOk)
          if (!isOk) {
            state.status_message = 'ERRORS'
          }
          if (isOk && question.items && question.items.length > 0) {
            question.items.every((item) => {
              Vue.prototype.checkLabel(item.label_en, item.label_fr, 'ITEM', state.questionnaire, item.id)
            })
          }
          return isOk
        })
      }
      if (isOk) {
        void apiMethod
          .put(
            'questionnaire/' + state.id + '/config',
            {
              id: state.id,
              configuration: state.questionnaire,
            },
            false
          )
          .then(() => {
            state.status_message = 'SAVED'
            setTimeout(() => (state.status_message = null), 2000)
          })
      }
    },
    LOAD_QUESTIONNAIRE({ commit }, { questionnaire_id, loading = true }) {
      // Reset store before setting new values
      commit('resetQuestionnaireStore')
      // call backend to get questionnaire config
      apiMethod.get('questionnaire/' + questionnaire_id + '/config', {}, loading).then((response) => {
        if (response.data.object) {
          commit('setId', response.data.object.id)
          commit('setIdentifier', response.data.object.identifier)
          commit('setName', response.data.object.name)
          commit('setNameFr', response.data.object.name_fr)
          commit('setNameEN', response.data.object.name_en)
          commit('setQuestionnaireRight', response.data.object.questionnaire_right)
          commit('setTextRight', response.data.object.text_right)
          commit('setIsCatalog', response.data.object.is_catalog)
          if (response.data.object.configuration != null) {
            commit('setQuestionnaire', response.data.object.configuration)
          }
        }
      })
    },
    LOAD_QUESTIONNAIRE_RESULTS({ commit }, { questionnaire_id }) {
      // Reset store before setting new values
      commit('setResults', [])
      // call backend to get questionnaire config
      apiMethod.get('questionnaire/' + questionnaire_id + '/results', {}).then((response) => {
        if (response.data.objects) {
          commit('setResults', response.data.objects)
        }
      })
    },
    SET_SECTIONS({ dispatch, commit }, new_sections) {
      new_sections.forEach((section, i) => {
        section.order = i + 1
      })
      commit('setSections', new_sections)
      dispatch('PUSH_QUESTIONNAIRE')
    },
    SET_SECTION({ state, commit, dispatch }, new_section) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == new_section.id)
      commit('setSection', { index_section, new_section })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    SET_ACCUMULATOR({ commit, dispatch }, new_accumulator) {
      commit('setAccumulator', new_accumulator)
      dispatch('PUSH_QUESTIONNAIRE')
    },
    SET_ACCUMULATORS({ commit, dispatch }, new_accumulators) {
      commit('setAccumulators', new_accumulators)
      dispatch('PUSH_QUESTIONNAIRE')
    },
    DELETE_SECTION({ state, commit, dispatch }, section_id) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      commit('deleteSection', index_section)
      // delete condition using this section
      state.questionnaire.sections.forEach((section, index_section) => {
        // check if section.condition use this section
        if (section.condition && section.condition.type === 'ANSWER' && section.condition.section === section_id) {
          commit('deleteSectionCondition', index_section)
        }
        section.questions.forEach((question, index_question) => {
          if (
            question.condition &&
            question.condition.type === 'ANSWER' &&
            question.condition.accumulator === section_id
          ) {
            commit('deleteQuestionCondition', { index_section, index_question })
          }
        })
      })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    DELETE_ACCUMULATOR({ state, commit, dispatch }, accumulator_id) {
      const index_accumulator = state.questionnaire.accumulators.findIndex(
        (old_accumulator) => old_accumulator.id === accumulator_id
      )
      commit('deleteAccumulator', index_accumulator)
      // delete condition using this accumulator
      state.questionnaire.sections.forEach((section, index_section) => {
        // check if section.condition use accumulator
        if (
          section.condition &&
          section.condition.type === 'ACCUMULATOR' &&
          section.condition.accumulator === accumulator_id
        ) {
          commit('deleteSectionCondition', index_section)
        }
        section.questions.forEach((question, index_question) => {
          if (
            question.condition &&
            question.condition.type === 'ACCUMULATOR' &&
            question.condition.accumulator === accumulator_id
          ) {
            commit('deleteQuestionCondition', { index_section, index_question })
          }
        })
      })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    DELETE_QUESTION({ state, commit, dispatch }, { section_id, question_id }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      commit('deleteQuestion', { index_section, index_question })
      // delete condition using this question
      state.questionnaire.sections.forEach((section, index_section) => {
        // check if section.condition use this question
        if (section.condition && section.condition.type === 'ANSWER' && section.condition.question === question_id) {
          commit('deleteSectionCondition', index_section)
        }
        section.questions.forEach((question, index_question) => {
          if (
            question.condition &&
            question.condition.type === 'ANSWER' &&
            question.condition.question === question_id
          ) {
            commit('deleteQuestionCondition', { index_section, index_question })
          }
        })
      })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    DELETE_ITEM({ state, commit, dispatch }, { section_id, question_id, item_id }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      const index_item = state.questionnaire.sections[index_section].questions[index_question].items.findIndex(
        (old_item) => old_item.id == item_id
      )
      commit('deleteItem', { index_section, index_question, index_item })
      // delete condition using this item
      state.questionnaire.sections.forEach((section, index_section) => {
        // check if section.condition use this item
        if (section.condition && section.condition.type === 'ANSWER' && section.condition.answer === item_id) {
          commit('deleteSectionCondition', index_section)
        }
        section.questions.forEach((question, index_question) => {
          if (question.condition && question.condition.type === 'ANSWER' && question.condition.answer === item_id) {
            commit('deleteQuestionCondition', { index_section, index_question })
          }
        })
      })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    DELETE_ITEMS({ state, commit, dispatch }, { section_id, question_id }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      const index_question = state.questionnaire.sections[index_section].questions.findIndex(
        (old_question) => old_question.id == question_id
      )
      commit('deleteItems', { index_section, index_question })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    SET_SECTIONS_LABEL({ commit }, { section_id, label }) {
      commit('updateSectionLabel', { section_id, label })
    },
    SET_SECTION_IDENTIFIER({ commit }, { section_id, identifier }) {
      commit('updateSectionIdentifier', { section_id, identifier })
    },
    SET_QUESTION_IDENTIFIER({ commit }, { section_id, question_id, identifier }) {
      commit('updateQuestionIdentifier', { section_id, question_id, identifier })
    },
    SET_QUESTIONS({ commit, dispatch }, { section_id, new_questions }) {
      new_questions.forEach((question, i) => {
        question.order = i + 1
      })
      commit('setQuestions', { section_id, new_questions })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    SET_QUESTION({ state, commit, dispatch }, { section_id, new_question }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      if (index_section >= 0) {
        const index_question = state.questionnaire.sections[index_section].questions.findIndex(
          (old_question) => old_question.id == new_question.id
        )
        commit('setQuestion', { index_section, index_question, new_question })
        dispatch('PUSH_QUESTIONNAIRE')
      }
    },
    SET_QUESTION_ITEMS({ commit, dispatch }, { section_id, question_id, new_items }) {
      new_items.forEach((item, i) => {
        item.order = i + 1
      })
      commit('setItems', { section_id, question_id, new_items })
      dispatch('PUSH_QUESTIONNAIRE')
    },
    SET_QUESTION_ITEM({ state, commit, dispatch }, { section_id, question_id, new_item }) {
      const index_section = state.questionnaire.sections.findIndex((old_section) => old_section.id == section_id)
      if (index_section >= 0) {
        const index_question = state.questionnaire.sections[index_section].questions.findIndex(
          (old_question) => old_question.id == question_id
        )
        if (index_question >= 0) {
          const index_item = state.questionnaire.sections[index_section].questions[index_question].items.findIndex(
            (old_item) => old_item.id == new_item.id
          )
          if (index_item == -1) {
            commit('addQuestionItem', { index_section, index_question, new_item })
          } else if (index_question >= 0) {
            commit('setQuestionItem', { index_section, index_question, index_item, new_item })
            dispatch('PUSH_QUESTIONNAIRE')
          }
        }
      }
    },
    TOGGLE_ACCUMULATOR_MODAL({ commit }) {
      commit('toggleAccumulatorModal')
    },
  },
  getters: {
    getLang: (state) => state.lang,
    getId: (state) => state.id,
    getIdentifier: (state) => state.identifier,
    getNameQuestionnaire: (state) => state.name,
    questionnaires: (state) => state.questionnaires,
    getName: (state) => {
      // if name_fr and name_en are not null : display name in the user language.
      if (state.name_fr && state.name_en) {
        return state.lang == 'fr' ? state.name_fr : state.name_en
      }
      // if name_fr or name_en are not null : display the one that is not null
      else if (state.name_fr || state.name_en) {
        return state.name_fr ? state.name_fr : state.name_en
      }
      return ''
    },
    getQuestionnaireRight: (state) => state.questionnaire_right,
    getTextRight: (state) => state.text_right,
    isCatalog: (state) => state.is_catalog,
    getStatusMessage: (state) => state.status_message,
    getAccumulators: (state) => {
      return state.questionnaire ? state.questionnaire.accumulators : []
    },
    getResults: (state) => {
      return state.results
    },
    getAccumulatorsOption:
      (state) =>
      (with_none = false) => {
        const options = state.questionnaire.accumulators.map((e) => {
          return {
            text: e.label,
            value: e.id,
            id: e.id,
          }
        })
        if (with_none) {
          options.unshift({
            text: state.lang === 'en' ? 'No accumulator' : "Pas d'accumulateur",
            value: null,
            id: -1,
          })
        }
        return options
      },
    getSections: (state) => {
      const sections = state.questionnaire ? state.questionnaire.sections : []
      return sections.sort(sortOrder)
    },
    getSection: (state) => (id) => {
      return state.questionnaire.sections.find((section) => section.id === id)
    },
    getQuestionnaire: (state) => {
      return state.questionnaire
    },
    getSectionIdentifier: (state) => (id) => {
      return state.questionnaire.sections.find((section) => section.id === id).identifier
    },
    getSectionLabel: (state) => (id) => {
      const section = state.questionnaire.sections.find((section) => section.id === id)
      const label_lang = state.lang == 'fr' ? 'label_fr' : 'label_en'
      return section[label_lang]
    },
    getQuestionnaireLabelTooltip: (state) => (questionnaire_id) => {
      if (state.questionnaires && state.questionnaires.length > 0) {
        const questionnaire = state.questionnaires.find((questionnaire) => questionnaire.id === questionnaire_id)
        if (questionnaire) {
          return questionnaire['name']
        }
      }
      return 'No questionnaire name'
    },
    getQuestionnaireLabelIdentifier: (state) => (questionnaire_id) => {
      if (state.questionnaires && state.questionnaires.length > 0) {
        const questionnaire = state.questionnaires.find((questionnaire) => questionnaire.id === questionnaire_id)
        if (questionnaire) {
          return questionnaire['identifier']
        }
      }
      return 'No questionnaire identifier'
    },
    getAccumulatorLabel: (state) => (questionnaire_id, accumulator_id) => {
      if (state.questionnaires && state.questionnaires.length > 0) {
        const questionnaire = state.questionnaires.find((questionnaire) => questionnaire.id === questionnaire_id)
        if (questionnaire) {
          const accumulator = questionnaire.configuration.accumulators.find(
            (accumulator) => accumulator.id === accumulator_id
          )
          return accumulator['label']
        }
      }
      return 'No accumulator label'
    },
    getSectionLabelTooltip: (state) => (questionnaire_id, section_id) => {
      if (state.questionnaires && state.questionnaires.length > 0) {
        const questionnaire = state.questionnaires.find((questionnaire) => questionnaire.id === questionnaire_id)
        if (questionnaire) {
          const section = questionnaire.configuration.sections.find((section) => section.id === section_id)
          if (section) {
            const label_lang = state.lang == 'fr' ? 'label_fr' : 'label_en'
            return section[label_lang]
          }
        }
      }
      return 'No section label'
    },
    getSectionLabelIdentifier: (state) => (questionnaire_id, section_id) => {
      if (state.questionnaires && state.questionnaires.length > 0) {
        const questionnaire = state.questionnaires.find((questionnaire) => questionnaire.id === questionnaire_id)
        if (questionnaire) {
          const section = questionnaire.configuration.sections.find((section) => section.id === section_id)
          if (section) {
            return section['identifier']
          }
        }
      }
      return 'No section identifier'
    },
    getQuestionLabelTooltip: (state) => (questionnaire_id, section_id, question_id) => {
      if (state.questionnaires && state.questionnaires.length > 0) {
        const questionnaire = state.questionnaires.find((questionnaire) => questionnaire.id === questionnaire_id)
        if (questionnaire) {
          const section = questionnaire.configuration.sections.find((section) => section.id === section_id)
          if (section) {
            const question = section.questions.find((question) => question.id === question_id)
            if (question) {
              const label_lang = state.lang == 'fr' ? 'label_fr' : 'label_en'
              return question[label_lang]
            }
          }
        }
      }
      return 'No question label'
    },
    getQuestionLabelIdentifier: (state) => (questionnaire_id, section_id, question_id) => {
      if (state.questionnaires && state.questionnaires.length > 0) {
        const questionnaire = state.questionnaires.find((questionnaire) => questionnaire.id === questionnaire_id)
        if (questionnaire) {
          const section = questionnaire.configuration.sections.find((section) => section.id === section_id)
          if (section) {
            const question = section.questions.find((question) => question.id === question_id)
            if (question) {
              return question['identifier']
            }
          }
        }
      }
      return 'No question identifier'
    },
    getAnswerLabelTooltip: (state) => (questionnaire_id, section_id, question_id, answer_id) => {
      if (state.questionnaires && state.questionnaires.length > 0) {
        const questionnaire = state.questionnaires.find((questionnaire) => questionnaire.id === questionnaire_id)
        if (questionnaire) {
          const section = questionnaire.configuration.sections.find((section) => section.id === section_id)
          if (section) {
            const question = section.questions.find((question) => question.id === question_id)
            if (question) {
              const answer = question.items.find((item) => item.id === answer_id)
              if (answer) {
                const label_lang = state.lang == 'fr' ? 'label_fr' : 'label_en'
                return answer[label_lang]
              }
            }
          }
        }
      }
      return 'No answer label'
    },
    getSectionLabels: (state) => (id) => {
      const section = state.questionnaire.sections.find((section) => section.id === id)
      return (
        (section['label_fr'] !== null || section['label_en'] !== null) &&
        ((section['label_fr'] && section['label_fr'].trim().length !== 0) ||
          (section['label_en'] && section['label_en'].trim().length !== 0))
      )
    },
    getQuestionIdentifier: (state) => (id, question_id) => {
      const section = state.questionnaire.sections.find((section) => section.id === id)
      const question = section.questions.find((question) => question.id === question_id)
      return question.identifier
    },
    getQuestions: (state) => (section_id) => {
      const section = state.questionnaire.sections.find((section) => section.id === section_id)
      return section.questions && section.questions.length > 0 ? section.questions.sort(sortOrder) : []
    },
    getQuestion: (state) => (section_id, question_id) => {
      const section = state.questionnaire.sections.find((section) => section.id === section_id)
      return section.questions.find((question) => question.id === question_id)
    },
    getQuestionItem: (state) => (section_id, question_id, item_id) => {
      const section = state.questionnaire.sections.find((section) => section.id === section_id)
      const question = section.questions.find((question) => question.id === question_id)
      return question.items.find((item) => item.id === item_id)
    },
    getQuestionsOption: (state) => (section_id, question_id) => {
      if (section_id) {
        const section = state.questionnaire.sections.find((section) => section.id === section_id)
        const questions =
          section.questions && section.questions.length > 0
            ? section.questions
                .filter(
                  (q) =>
                    (q.id !== question_id && q.type !== 'TEXT' && q.items && q.items.length > 0) ||
                    (q.id !== question_id && q.type === 'NUMERIC')
                )
                .sort(sortOrder)
            : []
        if (questions && questions.length > 0) {
          const label_lang = state.lang === 'fr' ? 'label_fr' : 'label_en'
          return questions.map((e) => {
            return {
              text: e[label_lang] !== null ? e[label_lang] : state.lang === 'fr' ? 'Pas de titre' : 'No title',
              value: e.id,
              id: e.id,
            }
          })
        }
      }
      return []
    },
    getQuestionsItemsOption: (state) => (section_id, question_id) => {
      if (section_id && question_id) {
        const section = state.questionnaire.sections.find((section) => section.id === section_id)
        const question = section.questions.find((question) => question.id === question_id)
        const items = question.items && question.items.length > 0 ? question.items : []
        if (items && items.length > 0) {
          const label_lang = state.lang === 'fr' ? 'label_fr' : 'label_en'
          return items.map((e) => {
            return {
              text: e[label_lang] !== null ? e[label_lang] : state.lang === 'fr' ? 'Pas de titre' : 'No title',
              value: e.id,
              id: e.id,
            }
          })
        }
      }
      return []
    },
    getSectionCondition: (state) => (section_id) => {
      const section = state.questionnaire.sections.find((section) => section.id === section_id)
      return section.condition ? section.condition : {}
    },
    getQuestionCondition: (state) => (section_id, question_id) => {
      const section = state.questionnaire.sections.find((section) => section.id === section_id)
      const question = section.questions.find((question) => question.id === question_id)
      return question.condition ? question.condition : {}
    },
    getQuestionType: (state) => (section_id, question_id) => {
      const section = state.questionnaire.sections.find((section) => section.id === section_id)
      const question = section.questions.find((question) => question.id === question_id)
      return question.type
    },
    getQuestionLabel: (state) => (section_id, question_id) => {
      const index_section = state.questionnaire.sections.findIndex((section) => section.id === section_id)
      if (index_section >= 0) {
        const question = state.questionnaire.sections[index_section].questions.find(
          (question) => question.id === question_id
        )
        const label_lang = state.lang == 'fr' ? 'label_fr' : 'label_en'
        return question[label_lang]
      }
    },
    getQuestionLabels: (state) => (section_id, question_id) => {
      const index_section = state.questionnaire.sections.findIndex((section) => section.id === section_id)
      if (index_section >= 0) {
        const question = state.questionnaire.sections[index_section].questions.find(
          (question) => question.id === question_id
        )
        return (
          (question['label_fr'] !== null || question['label_en'] !== null) &&
          ((question['label_fr'] && question['label_fr'].trim().length !== 0) ||
            (question['label_en'] && question['label_en'].trim().length !== 0))
        )
      }
    },
    getQuestionRequired: (state) => (section_id, question_id) => {
      const index_section = state.questionnaire.sections.findIndex((section) => section.id === section_id)
      if (index_section >= 0) {
        const question = state.questionnaire.sections[index_section].questions.find(
          (question) => question.id === question_id
        )
        return question['required']
      }
    },

    isAccumulatorModal: (state) => state.show_accumulator_modal,
  },
}

export default questionnaireStore
