import { _, allRoles, isAuthorized, lazyLoadView, piloteAndAuditor, requiresInternal, setNavL2 } from '@/router/routing'
import type { Route } from 'vue-router'

export const incidentsRoutes = {
  path: '/incidents',
  name: 'incidents',
  component: () => lazyLoadView(import('@/contexts/incidents/IncidentsView.vue')),
  icon: 'fire-alt',
  meta: {
    title: _('Incidents'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    hidden_for_fp_assessment: true,
    doc_id: 'incident',
    feature_id: 'INCIDENTS',
  },
}

export const incidentsContributionRoutes = {
  path: 'incidents',
  name: 'myincidents',
  icon: 'fire-alt',
  component: () => lazyLoadView(import('@/contexts/incidents/IncidentsContributionView.vue')),
  beforeEnter: setNavL2,
  meta: {
    title: _('Incidents'),
    is_authorized: (route: Route) => isAuthorized(route),
    requires_auth: true,
    requires_tenant: true,
    hidden_for_fp_assessment: true,
    requires_role: allRoles,
    requires_internal: () => requiresInternal(),
    doc_id: 'my_incidents',
    nb_contribution: 'nb_incidents',
    feature_id: 'INCIDENTS',
  },
}
