import Vue from 'vue'
import OptionsMixin from './options-mixin'
import throttle from 'lodash/throttle'
import { sortName } from '@/helpers/sort-helper'

/**
 * This is a mixin.
 * @public
 */
export default {
  props: {
    /**
     * Here follows the list of the most important props:
     *
     * - `current_obj_`: Object retrieved from the backend before any change
     * - `api_endpoint_post`: Api endpoint used in http request
     * - `do_get`: Get object data at initData if set to true
     * - `use_mixin_submit`: Use submit method below if set to true
     * @public
     */
    modal_id: String,
    current_obj_: {
      type: Object | null,
      required: false,
    },
    current_operation: String,
    api_endpoint_post: String,
    do_get: {
      type: Boolean,
      default: false,
    },
    is_modal: {
      type: Boolean,
      default: false,
    },
    use_mixin_submit: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [OptionsMixin],
  data: function () {
    /**
     * Here follows the list of the most important data:
     *
     * - `show_backend_error`: Flag to return backend error in a toast alert if set to true
     * - `do_notify_success`: Flag to return backend success message in a toast if set to true
     * - `current_obj_child`: Current_obj subset, usually the subset we want to update
     * - `current_operation_child`: current_operation on the subset defined above
     * - `selected_tab`: Data used to go back at index tab defined at each initData
     * - `hide_footer_on`: List of tab index (start at 0) to hide the form footer
     * - `opts_loaded_`: Flag indicated its not loaded
     * @public
     */
    return {
      max_focus__: 0,
      show_backend_error: false,
      do_not_show_toast: false,
      scope: 'form',
      disable_dirty_check: true,
      do_notify_success: false,
      current_obj: {},
      modal_title: '',
      api_endpoint_post_data: '',
      current_obj_child: {},
      current_operation_child: '',
      modal_id_edit: 'add_edit_child',
      name: '',
      selected_tab: 0,
      hide_footer_on: [],
      opts_loaded_: false,
      show_save_modal_if_cancel: false,
      hidden_registers_count: 0,
      hidden_registers: [],
      custom_error_message: '',
      selected_registers: [],
      error_dict: {
        PROOF_REQUIRED_ACTION: this._('Missing files or link to close the action'),
        PROOF_REQUIRED_TASK: this._('Missing files or link in task to close the action'),
      },
    }
  },
  computed: {
    /**
     * This computed returns the list of groups to which the user has access
     * @public
     */
    groups_users: function () {
      return this.$store.getters.groupsusers
    },
    modal_edit_title: function () {
      return this._('Edit')
    },
    modal_add_title: function () {
      return this._('Add')
    },
    modal_view_title: function () {
      return this._('View')
    },
    isNameRequired: function () {
      const name_fr_unset = !this.current_obj.name_fr || this.current_obj.name_fr === ''
      const name_en_unset = !this.current_obj.name_en || this.current_obj.name_en === ''
      return name_fr_unset && name_en_unset
    },
    isOneNameRequired: function () {
      const name_fr_unset = !this.current_obj.name_fr || this.current_obj.name_fr === ''
      const name_en_unset = !this.current_obj.name_en || this.current_obj.name_en === ''
      const name_unset = !this.name || this.name === ''
      if (this.$store.getters.lang === 'fr') {
        if (name_en_unset && name_unset) {
          return true
        }
      } else if (this.$store.getters.lang === 'en') {
        if (name_fr_unset && name_unset) {
          return true
        }
      }
      return false
    },
    /**
     * This computed returns the computed groups_users filtered by the current_obj perimeter
     * @public
     */
    groups_users_f: function () {
      const p = this.getPerimeter()
      let result = []
      if (this.groups_users && p && p.id) {
        result = this.groups_users
          .filter((e) => {
            return e.perimeters.includes(p.id) || e.is_staff
          })
          .sort((a, b) => (a.is_group === b.is_group ? sortName(a, b) : a.is_group ? 1 : -1))
      } else if (this.groups_users) {
        result = this.groups_users
          .slice()
          .sort((a, b) => (a.is_group === b.is_group ? sortName(a, b) : a.is_group ? 1 : -1))
      }
      return result
    },
    /**
     * This computed returns the computed groups_users sorted
     * @public
     */
    groups_users_s: function () {
      return this.groups_users
        .slice()
        .sort((a, b) => (a.is_group === b.is_group ? sortName(a, b) : a.is_group ? 1 : -1))
    },
  },
  methods: {
    /**
     * This function returns the computed groups_users filtered by the perimeter
     * if no collect is passed otherwise filtered by the collect perimeter
     *
     * @param perimeter_id the id of the perimeter
     * @param collect_perimeter the collect perimeter object
     * @public
     */
    groupsUsersPerimeter(perimeter_id, collect_perimeter = null) {
      if (!collect_perimeter) {
        return this.groups_users
          .filter((e) => e.perimeters.includes(perimeter_id))
          .sort((a, b) => (a.is_group === b.is_group ? sortName(a, b) : a.is_group ? 1 : -1))
      } else {
        return this.groups_users
          .filter((e) => e.perimeters.includes(collect_perimeter.id))
          .sort((a, b) => (a.is_group === b.is_group ? sortName(a, b) : a.is_group ? 1 : -1))
      }
    },
    /**
     * This function returns the current_obj perimeter
     *
     * @public
     */
    getPerimeter() {
      if (this.current_obj) {
        return this.current_obj.perimeter
      }
    },
    /**
     * This function returns the groups_users name
     *
     * @public
     */
    usersWithGroups({ groups_users }) {
      return `${groups_users.name}`
    },
    /**
     * This function returns the user_group list updated by if they have access to the perimeter or not
     *
     * @public
     */
    updatePerimeterUser() {
      const p = this.getPerimeter()
      let keep_user_group = true
      if (!p) {
        keep_user_group = false
      } else {
        if (
          (this.current_obj.group_user && this.current_obj.group_user.is_group) ||
          (this.current_obj.group_user_approver && this.current_obj.group_user_approver.is_group)
        ) {
          if (
            this.current_obj.group_user.perimeter !== p.id ||
            this.current_obj.group_user_approver.perimeter !== p.id
          ) {
            keep_user_group = false
          }
        } else {
          if (
            (this.current_obj.group_user && !this.current_obj.group_user.perimeters.includes(p.id)) ||
            (this.current_obj.group_user_approver && this.current_obj.group_user_approver.perimeters.includes(p.id))
          ) {
            keep_user_group = false
          }
        }
      }
      if (!keep_user_group) {
        if (this.current_obj.group_user) {
          this.current_obj.group_user = null
        }
        if (this.current_obj.group_user_approver) {
          this.current_obj.group_user_approver = null
        }
      }
      if (this.current_obj.measure && !this.current_obj.group_user) {
        this.current_obj.group_user = this.current_obj.measure.user
          ? this.groups_users.find((gu) => gu.obj_id === this.current_obj.measure.user.id && !gu.is_group)
          : this.current_obj.measure.user
          ? this.groups_users.find((gu) => gu.obj_id === this.current_obj.measure.group.id && gu.is_group)
          : null
      }
    },
    /**
     * This function returns the row data
     *
     * @param id the row id
     * @public
     */
    getRow: function (id) {
      const index = this.rowData.findIndex((e) => e.id == id)
      if (index >= 0) {
        return { row: this.rowData[index], index: index }
      } else {
        return { row: null, index: -1 }
      }
    },
    /**
     * This function opens the modal with he modal id defined in the data
     *
     * @public
     */
    show() {
      if (this.is_modal) {
        this.$bvModal.show(this.modal_id)
      } else {
        if (this.$refs[this.modal_id]) {
          this.$refs[this.modal_id].show()
        }
      }
    },
    /**
     * This function closes the modal with he modal id defined in the data
     *
     * @public
     */
    hide() {
      if (this.is_modal) {
        this.$bvModal.hide(this.modal_id)
      } else {
        if (this.$refs[this.modal_id]) {
          this.$refs[this.modal_id].hide()
        }
      }
    },
    /**
     * This function deletes the object with id specified
     *
     * @param id: the id of the row to delete
     * @public
     */
    deleteRow: throttle(
      function (id) {
        const _this = this
        this.confirm().then(() => {
          const to_delete = _this.getRow(id).index
          if (to_delete !== undefined) {
            _this.rowData.splice(to_delete, 1)
          }
          _this.$http.delete(`${_this.api_endpoint_post}/${id}`).catch(() => {
            _this.$toast.alert('An error occured.')
          })
        })
      },
      1000,
      { leading: true, trailing: false }
    ),
    /**
     * This function adds the obj param to the rowDataafter obj have been added
     *
     * @param obj the object to add
     * @public
     */
    addedRow: function (obj) {
      this.rowData.push(obj.object)
      if (this.is_modal) {
        this.$bvModal.hide(this.modal_id_edit)
      }
      this.$validator.reset()
    },
    /**
     * This function updates the obj param in the rowData after obj have been edited
     *
     * @param obj the object to update
     * @public
     */
    editedRow: function (obj) {
      const _this = this
      const index = this.getRow(obj.id).index
      Vue.set(this.rowData, index, obj)
      if (_this.is_modal) {
        this.$bvModal.hide(_this.modal_id_edit)
      } else {
        if (_this.$refs[_this.modal_id_edit]) {
          _this.$refs[_this.modal_id_edit].hide()
        }
      }
      this.$validator.reset()
    },
    /**
     * This function detects if changes have been done in the form
     *
     * @public
     */
    isDirty: function () {
      return this.$validator.fields.items.filter((e) => e.scope === this.scope).some((e) => e.flags.dirty)
    },
    /**
     * This function trigger the edition of an object, showing the form in edit mode
     *
     * @param id the id of the row to edit
     * @public
     */
    editRow: function (id) {
      this.current_obj_child = this.getRow(id).row
      this.current_operation_child = 'edit'
    },
    /**
     * This function trigger the edition of an object, showing the form in view mode
     *
     * @param id the id of the row to view
     * @public
     */
    viewRow: function (id) {
      this.current_obj_child = this.getRow(id).row
      this.current_operation_child = 'view'
    },
    /**
     * This function shows the form in add mode
     *
     * @public
     */
    addRow: function () {
      this.current_obj_child = {}
    },
    /**
     * This function manages the dirty flag for the group_user select
     *
     * @public
     */
    setUserGroupDirtyFlag: function (event) {
      this.$validator.fields.items.find((i) => i.name === 'groups_users').flags.dirty = !!(
        this.current_obj_ &&
        event &&
        ((this.current_obj_.group === null && event.is_group === true) ||
          (this.current_obj_.user === null && event.is_group === false) ||
          (event.is_group === false && this.current_obj_.group) ||
          (event.is_group === true && this.current_obj_.user) ||
          (this.current_obj_.group && event.is_group === true && this.current_obj_.group.id !== event.obj_id) ||
          (this.current_obj_.user && event.is_group === false && this.current_obj_.user.id !== event.obj_id))
      )
    },
    /**
     * This function trigger the cancel of a form, showing a pop-up to ask if we want to save the modification or not
     * only if changes have been made in the form
     *
     * @public
     */
    cancelled: function () {
      const _this = this
      if (this.to_add) {
        this.to_add = []
      }
      if (!this.is_modal && this.current_operation !== 'view') {
        if (this.isDirty() || this.show_save_modal_if_cancel) {
          this.confirm(
            ' ',
            this._('Save changes'),
            this._('Discard changes'),
            false,
            null,
            'outline-primary',
            this._('Would you like to save your modifications?'),
            false,
            'confirm-header-modal'
          )
            .then(() => {
              _this.submit()
            })
            .catch((ok) => {
              if (ok === false) {
                if (!_this.is_modal) {
                  _this.$refs[_this.modal_id].hide()
                }
                _this.postCancel()
                _this.$emit('cancelled')
              }
            })
        } else {
          if (!_this.is_modal) {
            _this.$refs[this.modal_id].hide()
          }
          _this.postCancel()
          _this.$emit('cancelled')
        }
      } else {
        if (!_this.is_modal) {
          _this.$refs[_this.modal_id].hide()
        }
        _this.postCancel()
        _this.$emit('cancelled')
      }
      this.$validator.reset()
    },
    /**
     * This function filters the options passed in param on the given id
     *
     * @param e the id to filter on
     * @param options the options to filter
     * @public
     */
    getOptionName: function (e, options) {
      const res = this[options].filter((o) => o.id == e)
      if (res.length > 0) {
        return res[0].name
      } else {
        return null
      }
    },
    setSelectOption: function (attr, options, default_index = null) {
      if (this.current_obj[attr] !== undefined && this.current_obj[attr] !== null) {
        this.current_obj[attr] = {
          id: this.current_obj[attr],
          name: this.getOptionName(this.current_obj[attr], options),
        }
        return
      }
      if (default_index !== null) {
        this.setDefaultOption(attr, options, default_index)
      }
    },
    /**
     * This function sets the value passed in param to the given attribute if it does not already or if it is empty
     *
     * @param attr the attribute to set
     * @param value the value wanted for the attribute
     * @public
     */
    setDefaultAttr(attr, value) {
      if (!this.current_obj[attr] || this.current_obj[attr] === '') {
        this.$set(this.current_obj, attr, value)
      }
    },
    setDefaultOption: function (attr, options, index = 0) {
      if ((this.current_obj[attr] === undefined || this.current_obj[attr] === null) && this[options].length > 0) {
        Vue.set(this.current_obj, attr, {
          id: this[options][index].id,
          name: this[options][index].name,
        })
      }
    },
    setSelectObj: function (obj, attr) {
      if (obj[attr] !== undefined && obj[attr] !== null) {
        obj[attr] = obj[attr].id
      }
    },
    /**
     * This function updates the fields of the attribute passed in param in the posted_atr param with eventually
     * an additional object
     *
     * @param attr the attribute wherein copy the values
     * @param posted_attr the attribute to post
     * @param fields the fields wherein copy the values
     * @param additional_nested_object the additional object to post
     * @public
     */
    updateRef(attr, posted_attr, fields = ['id'], additional_nested_object) {
      const _this = this
      let id = this.current_obj.id
      if (typeof id === 'string' || id instanceof String) {
        id = +id.substring(1)
      }
      if (isNaN(id)) {
        return
      }
      const obj_post = {
        id: id,
      }
      if (additional_nested_object) {
        obj_post[additional_nested_object] = this.current_obj[additional_nested_object]
      }
      obj_post[posted_attr] = this.copyNestedObjects(
        this[attr].map((e) => {
          const o = {}
          fields.forEach((f) => {
            o[f] = e[f]
          })
          return o
        })
      )
      const api_endpoint = this.api_endpoint_post ? this.api_endpoint_post : this.api_endpoint_post_data
      this.$http
        .put(api_endpoint + '/' + id, obj_post)
        .then(function (resp) {
          if (resp.data.ok) {
            if (_this.postEditedRef) {
              _this.postEditedRef(posted_attr, id)
            }
            _this.$emit('editedRef', resp.data)
          } else {
            throw new Error()
          }
        })
        .catch(() => {
          _this.$toast.alert(_this._('An error occured'))
        })
    },
    /**
     * @param id
     * @param files the files to download
     * @public
     */
    filesDownloaded(id, files) {
      const _this = this
      if (!_this.current_obj.files) {
        _this.$set(_this.current_obj, 'files', [])
      }
      files.forEach((f) => {
        _this.current_obj.files.push(f)
      })
      if (files.length > 0) {
        this.$toast.info(files.length + ' ' + this.$ngettext('file uploaded', 'files uploaded', files.length))
      }
      if (this.current_operation === 'add') {
        this.$emit('added', { object: _this.current_obj })
      } else if (this.current_operation === 'edit') {
        this.$emit('edited', { object: _this.current_obj })
      }
      this.$store.commit('unloading')
    },
    /**
     * @param id the id of the file to delete
     * @public
     */
    fileDeleted(id) {
      const index = this.current_obj.files.findIndex((f) => f.id === id)
      if (index >= 0) {
        this.current_obj.files.splice(index, 1)
        this.$emit('updateNbFiles', this.current_obj)
      }
    },
    /**
     * This is an important function. Its handles the different types of request when submitting a row
     *
     * @public
     */
    async submitRow() {
      const _this = this
      _this.$store.commit('loading')
      const obj_post = this.copyNestedObjects(_this.current_obj)
      for (const prop_name in obj_post) {
        if (obj_post[prop_name] === null || obj_post[prop_name] === undefined) {
          delete obj_post[prop_name]
        }
      }
      if (this.preFormSubmit) {
        await this.preFormSubmit(obj_post)
      }
      delete obj_post.tactions_
      if (this.to_add) {
        this.to_add = []
      }

      if (this.current_operation === 'add') {
        this.$http
          .post(`${this.api_endpoint_post}`, obj_post)
          .then(function (resp) {
            if (resp.data.ok) {
              _this.postSubmit()
              _this.postSubmitData(resp.data)
              if (_this.do_notify_success) {
                if (resp.data.message !== '') {
                  _this.$toast.success(resp.data.message)
                }
              }
              if (_this.$refs['dz']) {
                _this.current_obj = resp.data.object
                _this.$refs['dz'].process(resp.data.object.id)
              } else {
                _this.$emit('added', resp.data)
              }
              _this.$root.$emit('data:submit')
              if (_this.is_modal) {
                _this.$bvModal.hide(_this.modal_id)
              } else {
                if (_this.$refs[_this.modal_id]) {
                  _this.$refs[_this.modal_id].hide()
                }
              }
              _this.$validator.reset()
              _this.$store.commit('unloading')
            } else {
              if (resp.data.message) {
                throw new Error(resp.data.message)
              } else {
                throw new Error()
              }
            }
          })
          .catch((err) => {
            if (this.show_backend_error) {
              _this.$toast.alert(err.message)
            } else {
              _this.$toast.alert(_this._('An error occured. Please check your inputs'))
            }
            _this.$store.commit('unloading')
          })
      } else if (this.current_operation === 'edit') {
        let url = _this.api_endpoint_post
        if (obj_post.id) {
          url += '/' + obj_post.id
        }
        this.$http
          .put(url, obj_post)
          .then(function (resp) {
            if (resp.data.ok) {
              if (_this.do_notify_success) {
                if (resp.data.message !== '') {
                  _this.$toast.success(resp.data.message)
                } else {
                  _this.$toast.success('Success')
                }
              }
              _this.postSubmitData(resp.data)
              if (_this.$refs.dz) {
                _this.current_obj = resp.data.object
                _this.$refs.dz.process(resp.data.object.id)
              } else {
                _this.$emit('edited', resp.data)
              }
              _this.$root.$emit('data:submit')
              if (_this.is_modal) {
                _this.$bvModal.hide(_this.modal_id)
              } else {
                if (_this.$refs[_this.modal_id]) {
                  _this.$refs[_this.modal_id].hide()
                }
              }
              _this.postSubmit()
              _this.$validator.reset()
              _this.$store.commit('unloading')
            } else {
              if (resp.data.message) {
                _this.postErrorSubmit(resp.data)
                throw new Error(resp.data.message)
              } else {
                throw new Error()
              }
            }
          })
          .catch((error) => {
            _this.$store.commit('unloading')
            if (!this.do_not_show_toast) {
              if (this.show_backend_error) {
                if (this.error_dict[error.message]) {
                  error.message = this._(this.error_dict[error.message])
                }
                _this.$toast.alert(error.message)
              } else {
                _this.$toast.alert(_this._('An error occured. Please check your inputs'))
              }
            }
          })
      } else {
        this.$toast.warning(_this._('Internal error'))
        this.$store.commit('unloading')
      }
    },
    async submitRowNoAcisoForm() {
      const _this = this
      if (this.preSubmitValidate) {
        if (this.preSubmitValidate() === false) {
          return
        }
      }
      _this.$store.commit('loading')
      const obj_post = this.copyNestedObjects(_this.current_obj)
      for (const prop_name in obj_post) {
        if (obj_post[prop_name] === null || obj_post[prop_name] === undefined) {
          delete obj_post[prop_name]
        }
      }
      if (this.preFormSubmit) {
        await this.preFormSubmit(obj_post)
      }
      delete obj_post.tactions_
      if (this.to_add) {
        this.to_add = []
      }
      let url = _this.api_endpoint_post_data
      if (obj_post.id) {
        url += '/' + obj_post.id
      }
      this.$http
        .put(url, obj_post)
        .then(function (resp) {
          if (resp.data.ok) {
            if (_this.do_notify_success) {
              if (resp.data.message !== '') {
                _this.$toast.success(resp.data.message)
              } else {
                _this.$toast.success('Success')
              }
            }
            if (_this.$refs.dz) {
              _this.current_obj = resp.data.object
              _this.$refs.dz.process(resp.data.object.id)
            } else {
              _this.$emit('edited', resp.data)
            }
            _this.$root.$emit('data:submit')
            if (_this.is_modal) {
              _this.$bvModal.hide(_this.modal_id)
            } else {
              if (_this.$refs[_this.modal_id]) {
                _this.$refs[_this.modal_id].hide()
              }
            }
            _this.postSubmit()
            _this.$validator.reset()
            _this.$store.commit('unloading')
          } else {
            if (resp.data.message) {
              throw new Error(resp.data.message)
            } else {
              throw new Error()
            }
          }
        })
        .catch((error) => {
          _this.$store.commit('unloading')
          if (this.show_backend_error) {
            _this.$toast.alert(error.message)
          } else {
            _this.$toast.alert(_this._('An error occured. Please check your inputs'))
          }
        })
    },
    postSubmit: function () {},
    postErrorSubmit: function () {},
    postSubmitData: function () {},
    postCancel: function () {},
    /**
     * This is an important function. Firstly, it validates the fields in the scope and then executes the submit method
     *
     * @public
     */
    submit: throttle(
      function () {
        if (this.current_operation === 'view') {
          return
        }
        if (this.preSubmitValidate) {
          if (this.preSubmitValidate() === false) {
            return
          }
        }
        const _this = this
        this.$validator
          .validateAll(this.scope)
          .then((res) => {
            if (res) {
              if (this.use_mixin_submit) {
                _this.submitRow()
              } else {
                _this.submitRowOverride()
              }
            } else {
              this.$root.$emit('panel:reopen', this.modal_id)
            }
          })
          .catch(() => {
            _this.$toast.alert('Error while validating')
            _this.$store.commit('unloading')
          })

        _this.$validator.fields.items.forEach((item) => {
          item.reset()
          _this.$validator.errors.remove(item.name, item.scope)
        })
      },
      500,
      { leading: true, trailing: false }
    ),
    /**
     * This function gets an obj with the api_endpoint_post data to fills the current_obj
     *
     * @public
     */
    getObject: function () {
      const _this = this
      let url = `/${_this.api_endpoint_post}`
      if (this.current_obj.id) {
        url += '/' + this.current_obj.id
      }
      return new Promise(function (resolve, reject) {
        _this.$store.commit('loading')
        _this.$http
          .get(url)
          .then((resp) => {
            if (resp.data.ok) {
              _this.current_obj = resp.data.object
              _this.$store.commit('unloading')
              if (_this.postGet) {
                _this.postGet()
              }
              resolve()
            } else {
              throw new Error()
            }
          })
          .catch((err) => {
            _this.apiError(err)
            reject()
          })
      })
    },
    /**
     * This function sets the group_user_approver attribute to the obj passed in param with the obj.user_approver or
     * obj.group_approver value if me_as_default param is false, otherwise it's me
     *
     * @param obj the object on which we want to set group_user_approver
     * @param me_as_default boolean to know if set group_user_approver to me by default
     * @public
     */
    setUserGroupApproverAttr(obj, me_as_default = false) {
      this.$set(obj, 'group_user_approver', null)
      if (obj.user_approver) {
        obj.group_user_approver = this.groups_users.find(
          (e) => e.is_group === false && e.obj_id === obj.user_approver.id
        )
      } else if (obj.group_approver) {
        obj.group_user_approver = this.groups_users.find(
          (e) => e.is_group === true && e.obj_id === obj.group_approver.id
        )
      } else if (me_as_default) {
        obj.group_user_approver = this.groups_users.find(
          (e) => e.is_group === false && e.email === this.$store.getters.email
        )
      }
    },
    /**
     * This function sets the group_user_approver attribute to the current_obj
     *
     * @param me_as_default boolean to know if set group_user_approver to me by default
     * @public
     */
    setUserGroupApprover(me_as_default = false) {
      this.setUserGroupApproverAttr(this.current_obj, me_as_default)
    },
    /**
     * This function formats the group_user_approver attribute to be post
     *
     * @param obj the object where we take the attributes
     * @param obj_post the object to post
     * @public
     */
    setUserGroupApproverSubmitAttr(obj, obj_post) {
      delete obj_post.user_approver_id
      delete obj_post.group_approver_id

      if (obj.group_user_approver) {
        if (obj.group_user_approver.is_group) {
          obj_post.user_approver = null
          obj_post.group_approver = { id: obj.group_user_approver.obj_id }
        } else {
          obj_post.group_approver = null
          obj_post.user_approver = { id: obj.group_user_approver.obj_id }
        }
      } else {
        obj_post.group_approver = null
        obj_post.user_approver = null
      }
      delete obj_post.group_user_approver
      delete obj_post.ug_approver
      return obj_post
    },
    /**
     * This function formats the group_user_approver attribute to be post with the current_obj one
     *
     * @param obj_post the object to post
     * @public
     */
    setUserGroupApproverSubmit(obj_post) {
      return this.setUserGroupApproverSubmitAttr(this.current_obj, obj_post)
    },
    /**
     * This function sets the group_user attribute to the obj passed in param with the obj.user or obj.group value
     * if me_as_default param is false, otherwise it's me
     *
     * @param obj the object on which we want to set group_user
     * @param me_as_default boolean to know if set group_user to me by default
     * @param assign
     * @public
     */
    setUserGroupAttr(obj, me_as_default = false, assign = false) {
      this.$set(obj, 'group_user', null)
      if (obj.group) {
        obj.group_user = this.groups_users.find((e) => e.is_group === true && e.obj_id === obj.group.id)
        if (obj.user && obj.user.email === this.$store.getters.email && assign === true) {
          this.$set(obj, 'assign_to_user', true)
        } else {
          this.$set(obj, 'assign_to_user', false)
        }
      } else if (obj.user) {
        obj.group_user = this.groups_users.find((e) => e.is_group === false && e.obj_id === obj.user.id)
      } else if (me_as_default) {
        obj.group_user = this.groups_users.find((e) => e.is_group === false && e.email === this.$store.getters.email)
      }
    },
    /**
     * This function sets the groupd_userd attribute to the obj passed in param with the obj.users or obj.groups values
     * if me_as_default param is false, otherwise it's me
     *
     * @param obj the object on which we want to set group_user
     * @param me_as_default boolean to know if set group_user to me by default
     * @public
     */
    setUsersGroupsAttr(obj, me_as_default = false) {
      this.$set(obj, 'groups_users', [])
      if (obj.groups && obj.groups.length > 0) {
        obj.groups_users = obj.groups_users.concat(
          this.groups_users.filter((e) => e.is_group === true && obj.groups.find((g) => g.id === e.obj_id))
        )
      }
      if (obj.users && obj.users.length > 0) {
        obj.groups_users = obj.groups_users.concat(
          this.groups_users.filter((e) => e.is_group === false && obj.users.find((u) => u.id === e.obj_id))
        )
      }
      if (me_as_default && obj.groups_users.length === 0) {
        obj.groups_users.push(
          this.groups_users.find((e) => e.is_group === false && e.email === this.$store.getters.email)
        )
      }
    },
    /**
     * This function sets the group_user attribute to the current_obj
     *
     * @param me_as_default boolean to know if set group_user to me by default
     * @param assign
     * @public
     */
    setUserGroup(me_as_default = false, assign = false) {
      this.setUserGroupAttr(this.current_obj, me_as_default, assign)
    },
    /**
     * This function formats the group_user attribute to be post
     *
     * @param obj the object where we take the attributes
     * @param obj_post the object to post
     * @public
     */
    setUserGroupSubmitAttr(obj, obj_post, assign) {
      delete obj_post.user_id
      delete obj_post.group_id
      if (obj.group_user) {
        if (obj.group_user.is_group) {
          if (assign === true) {
            if (obj.user) {
              if (obj.user.obj_id) {
                obj_post.user = { id: obj.user.obj_id }
              } else if (obj.user.id) {
                obj_post.user = { id: obj.user.id }
              }
            } else {
              obj_post.user = null
            }
            obj_post.group = { id: obj.group_user.obj_id }
          } else {
            obj_post.user = null
            obj_post.group = { id: obj.group_user.obj_id }
          }
        } else {
          obj_post.group = null
          obj_post.user = { id: obj.group_user.obj_id }
        }
      } else {
        obj_post.group = null
        obj_post.user = null
      }
      delete obj_post.group_user
      delete obj_post.ug
      return obj_post
    },
    /**
     * This function formats the groups_users attribute to be post
     *
     * @param obj the object where we take the attributes
     * @param obj_post the object to post
     * @public
     */
    setUsersGroupsSubmitAttr(obj, obj_post) {
      obj_post.groups = []
      obj_post.users = []
      if (obj.groups_users && obj.groups_users.length > 0) {
        obj_post.groups = obj.groups_users
          .filter((gu) => gu.is_group)
          .map((g) => {
            g.id = g.obj_id ? g.obj_id : g.id
            return g
          })
        obj_post.users = obj.groups_users
          .filter((gu) => !gu.is_group)
          .map((u) => {
            u.id = u.obj_id ? u.obj_id : u.id
            return u
          })
      }
      delete obj_post.groups_users
      return obj_post
    },
    /**
     * This function formats the group_user attribute to be post with the current_obj one
     *
     * @param obj_post the object to post
     * @param assign
     * @public
     */
    setUserGroupSubmit(obj_post, assign = false) {
      return this.setUserGroupSubmitAttr(this.current_obj, obj_post, assign)
    },
    /**
     * This function returns the computed groups_users sorted by name
     *
     * @public
     */
    sortGroups() {
      if (this.groups_users) {
        this.groups_users.sort(sortName)
      }
    },
    /**
     * This function does the focus on the input with the name specified in param
     *
     * @param name the name of the inout
     * @public
     */
    doFocus(name) {
      const el = this.$el.querySelector("input[name='" + name + "']")
      if (el) {
        el.focus()
      } else {
        if (this.max_focus__ < 10) {
          this.max_focus__ += 1
          setTimeout(this.doFocus, 200)
        }
      }
    },
    /**
     * This function resets the form validators, gets the form options or if there is none, it does the preFormHandler
     *
     * @public
     */
    initData() {
      const _this = this
      this.showFooter()
      this.opts_loaded_ = false
      this.$validator.reset()
      this.selected_tab = 0
      if (this.$refs.dz) {
        this.$refs.dz.reset()
        if (!this.current_obj.files) {
          this.$set(_this.current_obj, 'files', [])
        }
      }
      if (this.preFormHandler) {
        if (this.getFormOptions) {
          this.getFormOptions()
            .then(() => {
              this.opts_loaded_ = true
              _this.preFormHandler()
              _this.$validator.reset()
            })
            .catch(() => {
              _this.preFormHandler()
              _this.$validator.reset()
            })
        } else {
          this.opts_loaded_ = true
          this.preFormHandler()
          this.$validator.reset()
        }
      }
      if (this.current_operation === 'add') {
        this.max_focus__ = 0
        if (
          this.fields['$' + this.scope] &&
          Object.prototype.hasOwnProperty.call(this.fields['$' + this.scope], 'name')
        ) {
          this.doFocus('name')
        }
        if (
          this.fields['$' + this.scope] &&
          Object.prototype.hasOwnProperty.call(this.fields['$' + this.scope], 'name_' + this.$store.getters.lang)
        ) {
          this.doFocus('name_' + this.$store.getters.lang)
        }
      }
      this.resetTitle()
      if (this.is_modal) {
        this.$bvModal.show(this.modal_id)
      } else {
        if (this.$refs[this.modal_id]) {
          this.$refs[this.modal_id].show()
        }
      }
      this.$validator.resume()
      setTimeout(() => {
        _this.$validator.reset()
      }, 200)
    },
    /**
     * This function rename the modal title
     *
     * @public
     */
    resetTitle() {
      if (this.current_operation === 'add') {
        this.modal_title = this.modal_add_title
      } else if (this.current_operation === 'edit') {
        if (this.current_obj.name) {
          this.modal_title = this.current_obj.name
        } else {
          this.modal_title = this.modal_edit_title
        }
      } else if (this.current_operation === 'view') {
        if (this.current_obj.name) {
          this.modal_title = this.current_obj.name
        } else {
          this.modal_title = this.modal_view_title
        }
      }
    },
    /**
     * This function is mostly used to check an object's identifier, the vname param is also used as a
     * validator in a form
     *
     * @param vname the validator name
     * @param endpoint the endpoint to post
     * @param additional_value
     * @public
     */
    registerValidator(vname, endpoint, additional_value = null) {
      const _this = this
      this.$validator.extend(vname, {
        getMessage: (field, params, data) => {
          return data.message
        },
        validate: (value) => {
          let __this = _this
          if (!__this._isVue && this.$vm) {
            __this = this.$vm
          }
          if (!__this || __this.current_operation === 'view') {
            return {
              valid: true,
              data: {
                message: '',
              },
            }
          }
          let object_id = null
          if (__this.current_obj_) {
            object_id = __this.current_obj_.id
          } else if (__this.current_obj) {
            object_id = __this.current_obj.id
          }
          const to_post = {
            value: value,
            additional_value: additional_value ? __this.current_obj[additional_value] : null,
            token: this.$route.params.token,
            object: object_id,
            perimeter: __this.current_obj && __this.current_obj.perimeter ? __this.current_obj.perimeter.id : null,
            parent: __this.current_obj ? __this.current_obj.parent : null,
          }
          if (__this.is_blueprint) {
            to_post.bp = '1'
          }
          if (__this.current_obj.is_auto) {
            to_post.is_auto = true
          }
          if (__this.current_obj.periodicity && __this.current_obj.periodicity.id) {
            to_post.periodicity = __this.current_obj.periodicity.id
          }
          return this.$http
            .post(__this.aciso_config.base_url + `/${endpoint}`, to_post)
            .then((data) => {
              if (data) {
                if (__this.getValidatorObject) {
                  __this.getValidatorObject(data.data)
                }
                return {
                  valid: data.data.ok,
                  data: {
                    message: data.data.message,
                  },
                }
              } else {
                return {
                  valid: false,
                  data: {
                    message: __this._('internal error'),
                  },
                }
              }
            })
            .catch(() => {
              return false
            })
        },
      })
    },
    /**
     * This function returns the validator errors for the specified fields
     *
     * @param fields the form fields name list
     * @public
     */
    hasError: function (fields) {
      const _this = this
      return this.$validator.errors.items.some((item) =>
        fields.some((f) => f === item.field && (!_this.scope || item.scope === _this.scope))
      )
    },
    /**
     * This function shows or not the footer
     *
     * @public
     */
    showFooter() {
      if (this.hide_footer_on.includes(this.selected_tab)) {
        $('#' + this.modal_id)
          .find('.panel-save-container')
          .hide()
      } else {
        $('#' + this.modal_id)
          .find('.panel-save-container')
          .show()
      }
    },
    /**
     * This function initiates initData and sets expected proofs
     *
     * @param newVal the new expected proofs to set
     * @public
     */
    doInitData_(newVal) {
      const _this = this
      this.current_obj = this.copyNestedObjects(newVal)
      if (newVal && newVal.expected_proofs) {
        this.current_obj.expected_proofs = []
        this.current_obj.expected_proofs = newVal.expected_proofs
      }
      if (this.do_get) {
        void this.getObject().then(() => {
          _this.initData(newVal)
        })
      } else {
        _this.initData(newVal)
      }
    },
    getHiddenRegisters(obj_registers) {
      this.hidden_registers = obj_registers.filter((option) => !option.edit_right)
      this.hidden_registers_count = this.hidden_registers.length
    },
    addHiddenCountBadge() {
      //   add hidden count to register options
      if (this.hidden_registers_count !== 0) {
        this.selected_registers.push({
          edit_right: false,
          id: -1,
          identifier: 'HIDDEN',
          name: this._('Hidden') + ' (' + this.hidden_registers_count + ')',
          view_right: false,
        })
      }
    },
  },
  mounted() {},
  watch: {
    selected_tab: function () {
      this.showFooter()
    },
    hide_footer_on: {
      deep: true,
      handler: function () {
        this.showFooter()
      },
    },
    current_obj_: function (newVal, oldVal) {
      if (newVal === null) {
        this.current_obj = {}
        return
      }
      if (newVal && oldVal && newVal.id && oldVal.id && newVal.id === oldVal.id) {
        return
      }
      if (this.current_operation === 'view_graphs') {
        return
      }
      const _this = this
      if (this.current_operation !== 'add') {
        _this.doInitData_(newVal)
      } else {
        if (newVal) {
          this.current_obj = this.copyNestedObjects(newVal)
        }
        _this.initData(newVal)
      }
    },
  },
}
