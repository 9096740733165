import { allRoles, isAuthorized, lazyLoadView, redirectHome, _ } from '@/router/routing'
import MainApp from '@/components/main-app.vue'

// /error/... are code error routes

export const errorRoutes = [
  {
    path: '/errors',
    name: 'errors',
    component: MainApp,
    redirect: () => redirectHome(),
    meta: {
      dummy: true,
    },
    children: [
      {
        path: '/403',
        name: '403',
        component: () => lazyLoadView(import('@/components/unauth-pages/error-403.vue')),
        meta: {
          title: _('Unauthorized'),
          is_authorized: () => isAuthorized('403'),
          requires_auth: true,
          requires_tenant: false,
          requires_role: allRoles,
        },
      },
      {
        path: '/404',
        name: '404',
        component: () => lazyLoadView(import('@/components/unauth-pages/error-404.vue')),
        meta: {
          title: _('Not found'),
          is_authorized: (route) => isAuthorized(route),
          requires_auth: true,
          requires_tenant: false,
          requires_role: allRoles,
        },
      },
      {
        path: '/500',
        name: '500',
        component: () => lazyLoadView(import('@/components/unauth-pages/error-500.vue')),
        meta: {
          title: _('Internal error'),
          is_authorized: (route) => isAuthorized(route),
          requires_auth: true,
          requires_tenant: false,
          requires_role: allRoles,
        },
      },
      {
        path: '/reload',
        name: 'reload',
        component: () => lazyLoadView(import('@/components/unauth-pages/error-reload.vue')),
        meta: {
          title: _('Application updated'),
          is_authorized: (route) => isAuthorized(route),
          requires_auth: true,
          requires_tenant: false,
          requires_role: allRoles,
        },
      },
    ],
  },
]
