import { useStore } from '@/store'

const store = useStore()

export const setUserEmail = () => {
  // @ts-expect-error : Survicate is not recognized in window
  if (window._sva) {
    // @ts-expect-error : Survicate is not recognized in window
    window._sva.setVisitorTraits({
      email: store.getters.email,
    })
  }
}
