const label_regex = new RegExp('^[0-9A-Za-zÀ-ÖØ-öø-ÿ ()\\-_\\[\\]\\/\\\\]{1,32}$', 'i')
const name_regex = new RegExp('^[0-9A-Za-zÀ-ÖØ-öø-ÿ ()\\-_\\[\\]\\/\\\\]{4,128}$', 'i')
const email_regex = new RegExp(/^[\w-+\\.]+@([\w-]+\.)+[\w-]{2,24}$/, 'i')
const link_regex = new RegExp(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
  'i'
)
export default {
  label_regex: label_regex,
  name_regex: name_regex,
  email_regex: email_regex,
  link_regex: link_regex,
}
