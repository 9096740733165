import Vue, { computed } from 'vue'

type UseEnumMixinData = Record<string, string>

export const remediationStatusVariant: UseEnumMixinData = {
  TO_PLAN: 'warning',
  PLANNED: 'primary',
  ONGOING: 'primary',
  STANDBY: 'light-gray',
  CANCELLED: 'light-gray',
  FINISHED: 'success',
  OPEN: 'primary',
  PENDING: 'primary',
  IN_PROGRESS: 'primary',
  RESOLVED: 'primary',
  CLOSED: 'primary',
}

export const connectorStatusColor: UseEnumMixinData = {
  IN_PROGRESS: 'primary',
  PENDING: 'light',
  SUCCESS: 'success',
  WARNING: 'warning',
  FAILED: 'danger',
}

export const integrationSourceColor: UseEnumMixinData = {
  MANUAL: 'primary',
  SCHEDULE: 'tertiary',
}

export const importReportStatusColor: UseEnumMixinData = {
  INIT: 'light',
  STARTED: 'primary',
  COMPLETE: 'success',
  ERROR: 'danger',
}

export const useEnumMixin = () => {
  const _ = Vue.prototype._

  const remediationStatusStr = computed<UseEnumMixinData>(() => ({
    TO_PLAN: _('To plan'),
    PLANNED: _('Planned'),
    ONGOING: _('Ongoing'),
    STANDBY: _('On hold'),
    CANCELLED: _('Cancelled'),
    FINISHED: _('Finished'),
    OPEN: _('Open'),
    PENDING: _('Pending client'),
    IN_PROGRESS: _('In progress'),
    RESOLVED: _('Resolved'),
    CLOSED: _('Closed'),
  }))
  const typeActionStr = computed<UseEnumMixinData>(() => ({
    t: _('Task'),
    r: _('Action'),
  }))
  const exemptionStatusStr = computed<UseEnumMixinData>(() => ({
    REQUESTED: _('Requested'),
    GRANTED: _('Granted'),
    REJECTED: _('Rejected'),
    CLOSED: _('Closed'),
  }))
  const catalogTypeStr = computed<UseEnumMixinData>(() => ({
    PUBLIC: _('Public'),
    PRIVATE: _('Private'),
    INSTANCE: _('Instance'),
  }))
  const riskStatusStr = computed<UseEnumMixinData>(() => ({
    NOT_REQUESTED: _('Not requested'),
    TO_BE_APPROVED: _('To be approved'),
    APPROVED: _('Approved'),
    REJECTED: _('Rejected'),
  }))
  const reviewStatusStr = computed<UseEnumMixinData>(() => ({
    OPEN: _('Open'),
    CLOSED: _('Closed'),
  }))
  const conditionTypesStr = computed<UseEnumMixinData>(() => ({
    ANSWER: _('Answer'),
    ACCUMULATOR: _('Accumulator'),
  }))
  const reviewTargetStr = computed<UseEnumMixinData>(() => ({
    REMEDIATION: _('Action'),
    INCIDENT: _('Incident'),
    GAP: _('Gap'),
    EXEMPTION: _('Exemption'),
    EVAL: _('Evaluation'),
    PROJECT: _('Project'),
    UPGRADE: _('Improve measure'),
    BUILD: _('Build measure'),
    USER: _('User'),
  }))
  const incidentStatusStr = computed<UseEnumMixinData>(() => ({
    OPENED: _('Opened'),
    ANALYSIS: _('Analysis'),
    CONTAINMENT: _('Containment'),
    ERADICATION: _('Eradication'),
    RECOVERY: _('Recovery'),
    LEARNING: _('Learning'),
    CLOSED: _('Closed'),
  }))
  const riskTreatmentStatusStr = computed<UseEnumMixinData>(() => ({
    OPEN: _('Open'),
    ACCEPTED: _('Accepted'),
    TRANSFERRED: _('Transferred'),
    CLOSED: _('Closed'),
  }))
  const gapStatusStr = computed<UseEnumMixinData>(() => ({
    TO_HANDLE: _('To handle'),
    IN_PROGRESS: _('In progress'),
    CLOSE: _('Closed'),
    TO_BE_CONFIRMED: _('To be confirmed'),
  }))
  const gapResolutionStatusStr = computed<UseEnumMixinData>(() => ({
    REJECTED: _('Rejected'),
    IGNORED: _('Ignored'),
    RESOLVED: _('Resolved'),
  }))
  const actionTypeStr = computed<UseEnumMixinData>(() => ({
    BUILD: _('Being built'),
    UPGRADE: _('Improving'),
    SIMPLE: _('Simple'),
  }))
  const sourceTypeStr = computed<UseEnumMixinData>(() => ({
    BUILD: _('Being built'),
    UPGRADE: _('Improvement'),
    PROJECT: _('Project'),
    EVAL: _('Compliance'),
    CONTROL: _('Compliance'),
    RISK: _('Risk'),
    EXEMPTION: _('Exemption'),
    INCIDENT: _('Incident'),
    GAP: _('Gap'),
  }))
  const booleanFilterStr = computed<UseEnumMixinData>(() => ({
    true: _('Yes'),
    false: _('No'),
  }))
  const perimeterAggName = computed<UseEnumMixinData>(() => ({
    SUM: _('Sum'),
    AVERAGE: _('Average'),
    MIN: _('Minimum'),
    MAX: _('Maximum'),
    MEDIAN: _('Median'),
  }))
  const timeAggName = computed<UseEnumMixinData>(() => ({
    SUM: _('Sum'),
    AVERAGE: _('Average'),
    LATEST: _('Latest value'),
    MIN: _('Minimum'),
    MAX: _('Maximum'),
    MEDIAN: _('Median'),
  }))
  const periodicityName = computed<UseEnumMixinData>(() => ({
    DAILY: _('Daily'),
    WEEKLY: _('Weekly'),
    MONTHLY: _('Monthly'),
    QUARTERLY: _('Quarterly'),
    SEMESTERLY: _('Semesterly'),
    YEARLY: _('Yearly'),
  }))
  const actionSourceStr = computed<UseEnumMixinData>(() => ({
    PROJECT: _('Project'),
    EVAL: _('Compliance'),
    RISK: _('Risk'),
    UPGRADE: _('Improve'),
    BUILD: _('Build'),
    SIMPLE: _('Simple'),
    EXEMPTION: _('Exemption'),
    INCIDENT: _('Incident'),
    GAP: _('Gap'),
    CONTROL: _('Control'),
  }))
  const indicatorType = computed<UseEnumMixinData>(() => ({
    PERFORMANCE: _('Performance'),
    ACTIVITY: _('Activity'),
  }))
  const evalScaleRemediation = computed<UseEnumMixinData>(() => ({
    NON_APPLICABLE: _('Not allowed'),
    APPLICABLE: _('Allowed'),
    REQUIRED: _('Required'),
  }))
  const connectorStatusName = computed<UseEnumMixinData>(() => ({
    IN_PROGRESS: _('In progress'),
    PENDING: _('Pending worker'),
    SUCCESS: _('Success'),
    WARNING: _('Warning'),
    FAILED: _('Error'),
  }))
  const connectorSourceName = computed<UseEnumMixinData>(() => ({
    MANUAL: _('Manual'),
    SCHEDULE: _('Scheduled'),
  }))
  const importReportStatusName = computed<UseEnumMixinData>(() => ({
    INIT: _('Initiated'),
    STARTED: _('Started'),
    COMPLETE: _('Complete'),
    ERROR: _('Error'),
  }))
  const metadataPropertyTypeStr = computed<UseEnumMixinData>(() => ({
    SECTOR_HIGH: _('Sector high'),
    SECTOR_LOW: _('Sector low'),
    COUNTRY: _('Country'),
  }))

  return {
    remediationStatusStr,
    typeActionStr,
    exemptionStatusStr,
    catalogTypeStr,
    riskStatusStr,
    reviewStatusStr,
    conditionTypesStr,
    reviewTargetStr,
    incidentStatusStr,
    riskTreatmentStatusStr,
    gapStatusStr,
    gapResolutionStatusStr,
    actionTypeStr,
    sourceTypeStr,
    booleanFilterStr,
    perimeterAggName,
    timeAggName,
    periodicityName,
    actionSourceStr,
    indicatorType,
    evalScaleRemediation,
    connectorStatusName,
    connectorSourceName,
    importReportStatusName,
    metadataPropertyTypeStr,
  }
}
