const config = {
  app_name: window.__env.api.app_name,
  base_url: window.__env.api.base_url,
  url: window.__env.api.url,
  api_v2_url: window.__env.api.api_v2_url,
  log_level: window.__env.api.log_level,
  dev: window.__env.api.dev,
  is_test: window.__env.api.is_test,
  enable_hubspot: window.__env.api.enable_hubspot,
  enable_sentry: window.__env.api.enable_sentry,
  sentry_dsn: window.__env.api.sentry_dsn,
  recaptcha_sitekey: window.__env.api.recaptcha_sitekey,
  hubspot_url: 'https://meetings-eu1.hubspot.com/',
  enable_survicate: window.__env.api.enable_survicate,
  survicate_key: window.__env.api.survicate_key,
  enable_matomo: window.__env.api.enable_matomo,
  matomo_host: window.__env.api.matomo_host,
  matomo_container_id: window.__env.api.matomo_container_id,
  apm_breakdown_metrics: window.__env.api.apm_breakdown_metrics,
  apm_environment: window.__env.api.apm_environment,
  apm_url: window.__env.api.apm_url,
  apm_service_name: window.__env.api.apm_service_name,
  apm_service_version: window.__env.api.apm_service_version,
  apm_enabled: window.__env.api.apm_enabled,
  apm_log_level: window.__env.api.apm_log_level,
  intercom_app_id: window.__env.api.intercom_app_id,
  posthog_token: window.__env.api.posthog_token,
  posthog_host: window.__env.api.posthog_host,
}

export { config }
