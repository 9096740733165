<template>
  <aciso-form
    :modal="false"
    :panel_id="modal_id"
    :panel_title="modal_title"
    :read_only="true"
    :ref="modal_id"
    :size="size"
    @cancel="cancelled"
  >
    <b-row>
      <b-col cols="8">
        <h2 v-if="current_obj_">{{ current_obj_.title }}</h2>
      </b-col>
      <b-col
        cols="4"
        v-if="isDocumentation"
      >
        <b-button
          :style="{ width: '170px' }"
          @click="loadDocumentation()"
          class="mr-1 mb-1 pull-right"
          variant="outline-primary"
        >
          <i class="fa far fa-external-link"></i>&nbsp; {{ _('Documentation') }}
        </b-button>
        <b-button
          v-if="history && history.length > 1 && showPreviousButton"
          @click="previousPage()"
          class="mr-1 mb-1 pull-right"
          variant="outline-primary"
          v-b-tooltip
          :title="_('Go to previous page')"
        >
          <i class="far fa-hand-point-left"></i>
        </b-button>
      </b-col>
    </b-row>
    <div v-if="!hasChild">
      <b-col cols="12">
        <viewer-sanitize
          :sanitize="false"
          :value="content_with_video"
          v-if="current_obj_ && current_obj_.content"
        ></viewer-sanitize>
        <b-card
          class="mt-3"
          v-else
        >
          <span class="text-muted">
            {{ doc_msg }}
          </span>
        </b-card>
      </b-col>
    </div>
    <div v-else>
      <b-col
        cols="12"
        v-if="current_obj_ && current_obj_.content"
      >
        <viewer-sanitize
          :sanitize="false"
          :value="content_with_video"
        ></viewer-sanitize>
        <hr v-if="current_obj_.children.length > 0" />
        <ul v-if="current_obj_.children.length > 0">
          <li
            :key="c.identifier"
            v-for="c in current_obj_.children"
          >
            <a
              @click="navigatePage(c)"
              href="#"
            >
              {{ c.title }}
            </a>
          </li>
        </ul>
        <div
          class="mt-2"
          v-if="!$store.getters.isAdmin && !$store.getters.isStaffPlus"
        >
          <div v-if="current_obj_ && current_obj_.content">
            <b-card
              v-show="!has_voted"
              class="mt-4"
            >
              <feedback
                @sendVote="sendVote"
                :current_rating="current_obj_.current_rating"
              ></feedback>
            </b-card>
            <b-card
              v-show="has_voted"
              class="mt-4"
            >
              <h5 class="panel-title mb-2">
                <translate>Thank you for your feedback!</translate>
              </h5>
            </b-card>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        v-else
      >
        <b-card class="mt-3">
          <span class="text-muted">
            <translate>This page has no information yet.</translate>
          </span>
        </b-card>
      </b-col>
    </div>
  </aciso-form>
</template>

<script>
import AcisoForm from '../layout/aciso-form.vue'
import EditMixin from '../mixins/edit-mixin'
import ViewerSanitize from '@/components/ui/viewer-sanitize.vue'
import Feedback from '../forms/feedback.vue'
import { sendWikiVoteMixin } from '@/services/WikiMixin.services'

export default {
  name: 'wiki',
  mixins: [EditMixin],
  props: {
    history: Array,
    hasChild: {
      type: Boolean,
      default: false,
    },
    isDocumentation: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  computed: {
    showPreviousButton: function () {
      if (this.history) {
        if (
          this.history[this.history.length - 2] === 'no-page' &&
          this.history[this.history.length - 1] === 'no-page'
        ) {
          return false
        } else {
          return true
        }
      }
      return false
    },
    content_with_video: function () {
      if (this.current_obj_ && this.current_obj_.content) {
        let content = this.current_obj_.content
        if (!this.current_obj_.link_id || !this.current_obj_.provider) {
          return content
        } else {
          const shortcode = '!video!'
          const video_id = this.current_obj_.link_id
          if (this.current_obj_.provider === 'VIMEO') {
            const video_vimeo =
              '<br><iframe src="https://player.vimeo.com/video/' +
              video_id +
              '" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe><br>'
            if (content.includes(shortcode)) {
              content = content.replace(shortcode, video_vimeo)
            } else {
              content = video_vimeo + this.current_obj_.content
            }
          } else if (this.current_obj_.provider === 'YOUTUBE') {
            const video_youtube =
              '<br><iframe src="https://www.youtube.com/embed/' +
              video_id +
              '" width="640" height="360" title="YouTube video player" frameborder="0" allow="autoplay; picture-in-picture" allowfullscreen></iframe><br>'
            if (content.includes(shortcode)) {
              content = content.replace(shortcode, video_youtube)
            } else {
              content = video_youtube + this.current_obj_.content
            }
          }
          return content
        }
      } else {
        return null
      }
    },
    modal_view_title: function () {
      if (this.isDocumentation) {
        return this._('Contextual help')
      } else {
        return this._('Tenacy')
      }
    },
  },
  data: function () {
    return {
      modal_title: 'Contextual Help',
      current_page: null,
      hide_footer_on: [0],
      current_node_page: null,
      api_endpoint: 'wiki',
      doc_msg: this._(
        'This page has no contextual documentation yet, but you can browse the documentation using the button above.'
      ),
      has_voted: false,
    }
  },
  components: {
    ViewerSanitize,
    AcisoForm,
    Feedback,
  },
  methods: {
    preFormHandler() {
      this.has_voted = false
    },
    previousPage() {
      this.$emit('previousPage')
    },
    loadDocumentation() {
      const routeData = this.$router.resolve({ name: 'documentation' })
      window.open(routeData.href, '_blank')
    },
    navigatePage(node) {
      this.$emit('childNav', node)
    },
    async sendVote(rating) {
      if (!this.current_obj_.identifier) {
        return
      }

      try {
        await sendWikiVoteMixin(this.current_obj_.identifier, rating)
        this.has_voted = true
      } catch (e) {
        this.$toast.alert(e)
      }
    },
  },
  watch: {},
}
</script>
