<template>
  <div>
    <aciso-form
      :ref="modal_id"
      :modal="false"
      :panel_id="modal_id"
      :panel_title="modal_title"
      :read_only="true"
      :size="'lg'"
      @cancel="cancelled"
    >
      <div v-if="!is_children && !loading_values">
        <div class="p-0 mb-4">
          <b-row class="d-flex justify-content-between align-items-center mb-4 mt-4">
            <b-col>
              <h5 class="panel-title">
                <i class="fal fa-bullhorn mr-2"></i>
                <translate>News</translate>
              </h5>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <app-button
                class="news-btn"
                data-testid="btn-marl-all-dyk-read"
                :custom_icon="'envelope-open'"
                :is_outlined="true"
                :disabled="!has_news"
                @click="markAllRead('news')"
              >
                {{ _('Mark all as read') }}
              </app-button>
              <app-button-nav
                class="news-btn ml-2"
                data-testid="btn-see-all-dyk"
                @click="gotoPage('/doc/news')"
              >
                {{ _('See all') }}
              </app-button-nav>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="current_obj_"
              cols="12"
            >
              <div v-if="current_obj_.news && current_obj_.news.length > 0">
                <b-card
                  v-for="c in current_obj_.news"
                  :key="c.id"
                  class="mb-0 hover-card"
                  @click="navigatePage(c)"
                >
                  <b-row>
                    <b-col cols="11">
                      <span
                        v-if="c.read_date === null"
                        class="text-secondary d-inline-block read_icon mr-2"
                        ><i class="fa fa-circle"></i
                      ></span>
                      <span class="info-title text-primary d-inline">
                        {{ c.title }}
                      </span>
                    </b-col>
                    <b-col
                      cols="1"
                      style="align-self: center"
                    >
                      <i class="fal fa-arrow-circle-right ml-2 pull-right color"></i>
                    </b-col>
                  </b-row>
                </b-card>
              </div>
              <span
                v-else
                class="text-muted text-primary"
              >
                <translate>No news today</translate>
              </span>
            </b-col>
          </b-row>
        </div>
        <div class="p-0">
          <b-row class="d-flex justify-content-between align-items-center mb-4">
            <b-col>
              <h5 class="panel-title">
                <i class="fal fa-lightbulb-on mr-2"></i>
                <translate>Did you know ?</translate>
              </h5>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <app-button
                class="news-btn"
                data-testid="btn-mark-all-news-read"
                :custom_icon="'envelope-open'"
                :is_outlined="true"
                :disabled="!has_dyk"
                @click="markAllRead('dyk')"
              >
                {{ _('Mark all as read') }}
              </app-button>
              <app-button-nav
                class="news-btn ml-2"
                data-testid="btn-see-all-news"
                @click="gotoPage('/doc/dyk')"
              >
                {{ _('See all') }}
              </app-button-nav>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-if="current_obj_"
              cols="12"
            >
              <div v-if="current_obj_.dyk && current_obj_.dyk.length > 0">
                <b-card
                  v-for="c in current_obj_.dyk"
                  :key="c.id"
                  class="mb-0 hover-card"
                  @click="navigatePage(c)"
                >
                  <b-row>
                    <b-col cols="11">
                      <span
                        v-if="c.read_date === null"
                        class="text-secondary d-inline-block read_icon mr-2"
                        ><i class="fa fa-circle"></i
                      ></span>
                      <span class="info-title text-primary d-inline">{{ c.title }} </span>
                    </b-col>
                    <b-col
                      cols="1"
                      style="align-self: center"
                    >
                      <i class="fal fa-arrow-circle-right ml-2 pull-right color"></i>
                    </b-col>
                  </b-row>
                </b-card>
              </div>
              <span
                v-else
                class="text-muted text-primary"
              >
                <translate>No advice today</translate>
              </span>
            </b-col>
          </b-row>
        </div>
      </div>

      <span
        v-show="loading_values"
        key="aa"
      >
        <i class="far fa-circle-notch fa-spin fa-2x icon-center"></i>
      </span>

      <div v-if="is_children && !loading_values">
        <b-row class="mb-4">
          <b-col cols="10">
            <h2 v-if="current_obj_.title">{{ current_obj_.title }}</h2>
          </b-col>
          <b-col cols="2">
            <b-button
              v-if="history && history.length > 1 && showPreviousButton"
              @click="goPrevious()"
              class="mr-1 mb-1 pull-right"
              variant="outline-primary"
              v-b-tooltip
              :title="_('Go to previous page')"
            >
              <i class="far fa-hand-point-left"></i>
            </b-button>
          </b-col>
        </b-row>
        <b-col cols="12">
          <div v-if="current_obj_.content">
            <viewer :value="current_obj_.content"></viewer>
            <div v-if="current_obj_.children && current_obj_.children.length > 0">
              <ul>
                <li
                  :key="c.identifier"
                  v-for="c in current_obj_.children"
                >
                  <a
                    @click="navigatePage(c)"
                    href="#"
                  >
                    {{ c.title }}
                  </a>
                </li>
              </ul>
            </div>
            <b-card
              v-show="!has_voted"
              class="mt-4"
            >
              <feedback
                @sendVote="sendVote"
                :current_rating="current_rating"
              ></feedback>
            </b-card>
            <b-card
              v-show="has_voted"
              class="mt-4"
            >
              <h5 class="panel-title mb-2">
                <translate>Thank you for your feedback!</translate>
              </h5>
            </b-card>
          </div>
          <b-card
            v-else
            class="mt-3"
          >
            <span class="text-muted">
              <translate>No information available yet.</translate>
            </span>
          </b-card>
        </b-col>
      </div>
    </aciso-form>
  </div>
</template>

<script>
import AcisoForm from '../layout/aciso-form.vue'
import EditMixin from '../mixins/edit-mixin'
import Feedback from '../forms/feedback.vue'
import AppButtonNav from '../ui/app-button-nav.vue'
import AppButton from '../ui/app-button.vue'
import { Viewer } from '@toast-ui/vue-editor'
import { sendWikiVoteMixin } from '@/services/WikiMixin.services'

export default {
  name: 'news-panel',
  mixins: [EditMixin],
  props: {
    history: Array,
    size: {
      type: String,
      default: 'lg',
    },
    is_children: {
      type: Boolean,
      default: false,
    },
    loading_values: {
      type: Boolean,
      default: false,
    },
    finish_loading: {
      type: Boolean,
      default: false,
    },
    current_rating: {
      type: Number,
      default: null,
    },
    has_news: {
      type: Boolean,
      default: false,
    },
    has_dyk: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showPreviousButton: function () {
      if (this.history) {
        if (
          this.history[this.history.length - 2] === 'no-page' &&
          this.history[this.history.length - 1] === 'no-page'
        ) {
          return false
        } else {
          return true
        }
      }
      return false
    },
    modal_view_title: function () {
      return this._('Hello,' + ' ' + this.$store.getters.name)
    },
  },
  data: function () {
    return {
      modal_title: 'Tenacy',
      current_page: null,
      hide_footer_on: [0],
      current_node_page: null,
      has_voted: false,
    }
  },
  components: {
    Feedback,
    AcisoForm,
    Viewer,
    AppButtonNav,
    AppButton,
  },
  methods: {
    preFormHandler() {
      this.has_voted = false
    },
    goPrevious() {
      this.$emit('previousPageNews')
    },
    loadDocumentation() {
      const routeData = this.$router.resolve({ name: 'documentation' })
      window.open(routeData.href, '_blank')
    },
    navigatePage(node) {
      this.$emit('childNav', node)
    },
    gotoPage(link) {
      const route = this.$router.resolve({ path: link })
      window.open(route.href)
    },

    markAllRead(type) {
      this.$emit('markAllRead', type)
    },
    async sendVote(rating) {
      if (!this.current_obj_.identifier) {
        return
      }

      try {
        await sendWikiVoteMixin(this.current_obj_.identifier, rating)
        this.has_voted = true
      } catch (e) {
        this.$toast.alert(e)
      }
    },
  },
}
</script>

<style scoped>
.panel-title {
  margin-left: 1em;
  text-align: left;
  color: #0c5460;
}

.hover-card {
  cursor: pointer;
}

.hover-card:hover {
  background-color: #e2e2e2;
}

.icon-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.color {
  color: #0c5460;
}

.info-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.card-body {
  padding: 0.8rem !important;
}

.card {
  border-radius: 2px !important;
}

.read_icon {
  font-size: 0.8em;
}

.news-btn {
  height: 35px !important;
}
</style>
