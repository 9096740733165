<template>
  <b-button
    @click="emitClick"
    class="tenacy-button-nav"
    :disabled="disabled_condition"
    :variant="variant"
    :class="{
      tenacy_button_nav_disabled: disabled_condition,
      warning_outline: variant === 'warning' && !disabled_condition,
    }"
    :style="{ height: in_array ? '30px' : '40px', 'font-weight': in_array ? '700' : '500' }"
  >
    <span class="tenacy-btn-label">
      <slot></slot>
    </span>
    <i
      :class="fa_type + ' fa-' + custom_icon + ' fa-fw'"
      :title="tooltip"
      class="tenacy-btn-icon"
      v-b-tooltip
    ></i>
  </b-button>
</template>

<script>
export default {
  name: 'app-button-nav',
  mixins: [],
  props: {
    custom_icon: {
      type: String,
      default: 'arrow-right',
    },
    fa_type: {
      type: String,
      default: 'far',
    },
    variant: {
      type: String,
      default: 'outline-primary',
    },
    tooltip: String,
    disabled_condition: {
      type: Boolean,
      default: false,
    },
    in_array: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  data() {
    return {}
  },
  methods: {
    emitClick(ev) {
      if (!this.disabled) {
        this.$emit('click', ev)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.tenacy-btn-icon {
  margin-left: 4px;
}
.tenacy-button-nav {
  vertical-align: middle;
}

.tenacy_button_nav_disabled,
.tenacy_button_nav_disabled:hover {
  background-color: #dfdfdf !important;
  color: #a6a6a6 !important;
  border: #dfdfdf !important;
}
.warning_outline {
  color: white !important;
  border: white !important;
}
</style>
