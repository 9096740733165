<template>
  <div>
    <!--    <vue-loading-->
    <!--      :active="true"-->
    <!--      :can-cancel="false"-->
    <!--      :height="32"-->
    <!--      :is-full-page="true"-->
    <!--      :width="32"-->
    <!--      :z-index="1060"-->
    <!--    ></vue-loading>-->
  </div>
</template>

<script>
// import VueLoading from 'vue-loading-overlay'

export default {
  name: 'unauth-loading',
  components: {
    // VueLoading,
  },
  props: {},
}
</script>
