import { lazyLoadView, redirectHome, _, piloteOnly, isAuthorized } from '@/router/routing'
import { appRoutes } from '@/router/routes/app_routes'
import { errorRoutes } from '@/router/routes/error_routes'
import { navRoutes } from '@/router/routes/nav_routes/nav_routes'
import { adminRoutes } from '@/router/routes/admin_routes'
import { authRoutes } from '@/router/routes/auth_routes'
import { standaloneRoutes } from '@/router/routes/standalone_routes'

export const routes = {
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: [
    // If path match no one, then load error-404
    { path: '*', component: () => lazyLoadView(import('@/components/unauth-pages/error-404.vue')) },

    // when you call root path ("/") redirect to /home.
    {
      path: '/',
      name: 'main_home',
      redirect: () => redirectHome(),
    },

    {
      path: '/share/dashboard/:token',
      name: 'dashboard_share',
      component: () => lazyLoadView(import('@/components/unauth-pages/dashboard-viewer-share.vue')),
      meta: {
        title: _('Shared dashboard'),
        requires_auth: false,
      },
    },
    {
      path: '/campaigns/double-run/:campaign_id',
      name: 'campaign_double_run',
      component: () => lazyLoadView(import('@/components/pages/campaign-double-run.vue')),
      icon: 'people-arrows',
      meta: {
        title: null,
        requires_auth: true,
        requires_role: piloteOnly,
        is_authorized: (route) => isAuthorized(route),
      },
    },

    ...authRoutes,
    ...appRoutes,
    ...errorRoutes,
    ...navRoutes,
    ...adminRoutes,
    ...standaloneRoutes,
  ],
}
