import { load } from 'recaptcha-v3'
import vv_fr from 'vee-validate/dist/locale/fr'
import { isLanguageFrench } from '@/helpers/Language.helpers'

/**
 * This is a mixin used for recaptcha protection on the login-page
 * @public
 */
export default {
  data: function () {
    return {
      recaptca_enabled: true,
      loading: false,
    }
  },
  methods: {
    /**
     * Function that load recaptcha protection on the login-page
     */
    setRecaptcha: function () {
      if (!this.recaptca_enabled) {
        $('.grecaptcha-badge').hide()
        setTimeout(() => {
          $('.grecaptcha-badge').hide()
        }, 500)
        return
      }
      const _this = this
      void load(this.aciso_config.recaptcha_sitekey).then((recaptcha) => {
        recaptcha
          .execute('login')
          .then((token) => {
            _this.recaptcha_token = token
            $('.grecaptcha-badge').show()
          })
          .catch(() => {})
      })
    },
  },
  mounted() {
    $('body').addClass('login-page')
    if (isLanguageFrench()) {
      this.$language.current = 'fr_FR'
      this.$validator.localize('fr', vv_fr)
      const html = document.documentElement
      html.setAttribute('lang', 'fr')
    } else {
      this.$language.current = 'en_US'
      this.$validator.localize('en')
      const html = document.documentElement
      html.setAttribute('lang', 'en')
    }
    if (!this.recaptca_enabled) {
      $('.grecaptcha-badge').hide()
      setTimeout(() => {
        $('.grecaptcha-badge').hide()
      }, 500)
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // access to component instance via `vm`
      vm.setRecaptcha()
      if (vm.step) {
        vm.step = 1
      }
    })
  },
}
