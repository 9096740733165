import Vue from 'vue'

export const sendWikiVoteMixin = async (identifier: string, rate: number): Promise<void> => {
  const $http = Vue.prototype.$http

  const api_endpoint_elements = `/wiki/page/${identifier}/rating?rating=${rate}`

  const response = await $http.put(api_endpoint_elements)

  if (!response.data.ok) {
    throw new Error(response.data.message)
  }
}
