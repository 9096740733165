<template>
  <div
    class="d-inline-flex"
    @click.stop.prevent="emitClick"
  >
    <template>
      <i
        :class="fa_type + ' fa-' + custom_icon + ' fa-fw'"
        :style="stylingIcon"
        v-if="custom_icon"
        :data-testid="'icon-' + custom_icon"
        :title="tooltip"
        v-b-tooltip
      ></i>
      <i
        :class="fa_type + ' fa-' + iconDict[app_icon] + ' fa-fw'"
        :style="stylingIcon"
        v-else-if="iconDict[app_icon]"
        :data-testid="'icon-' + iconDict[app_icon]"
        :title="tooltip"
        v-b-tooltip
      ></i>
      <slot></slot>
    </template>
  </div>
</template>
<script>
import { COLOR_DICT, ICON_DICT } from '@/constants/StyleMixin.constants'

export default {
  name: 'app-icon',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    custom_icon: String,
    app_icon: String,
    fa_type: {
      type: String,
      default: 'far',
    },
    tooltip: String,
    icon_size: String,
    padding: String,
  },
  computed: {
    stylingIcon: function () {
      return {
        'font-size': this.icon_size ? this.icon_size : '1em',
        'font-weight': 400,
        color: COLOR_DICT[this.color],
      }
    },
  },
  data() {
    return {
      iconDict: ICON_DICT,
    }
  },
  methods: {
    emitClick(ev) {
      if (!this.disabled) {
        this.$emit('click', ev)
      }
    },
  },
}
</script>
