import store from '@/store'
import { api_cache } from '@/api_call'
import { sortName } from '@/helpers/sort-helper'
import Vue from 'vue'
import type { ApiV1CommonResponse, ApiV1Response } from '@/interfaces/ApiV1.interfaces'

interface GetOptionsMixinRest extends ApiV1CommonResponse {
  objects: object[]
  tags: object[]
}

interface GetOptionsMixinSuccess {
  options: unknown
  data: unknown
}

type GetOptionsMixinError = string

/**
 * @param endpoint Endpoint of the database (string)
 * @param do_sort Sort alphabetically the array of options (boolean)
 * @param loading Trigger loading/unloading
 * @param cache Enable or disable the cache of the request
 * Main function that return an array of options (values) from a GET on an endpoint in the database
 */
export const getOptionsMixin = async (
  endpoint: string,
  do_sort = true,
  loading = true,
  cache = false
): Promise<GetOptionsMixinSuccess | GetOptionsMixinError> => {
  const $http = Vue.prototype.$http
  const $log = Vue.prototype.$log
  let has_name = false
  return new Promise((resolve, reject) => {
    const options: unknown[] = []
    if (loading) {
      store.commit('loading')
    }

    const url = `/${endpoint}`
    const call = () => $http.get(url)

    const promise: ApiV1Response<GetOptionsMixinRest> = cache ? api_cache.getOrSet(url, call) : call()

    promise
      .then(({ data }) => {
        if (data.ok) {
          data.objects.forEach((e) => {
            if ('name' in e) {
              has_name = true
            }
            if (e !== null) {
              options.push(e)
            }
          })
          if (do_sort && has_name) {
            options.sort(sortName)
          }
          if (loading) {
            store.commit('unloading')
          }
          resolve({ data, options })
        } else {
          if (loading) {
            store.commit('unloading')
          }
          reject(data.message)
        }
      })
      .catch((err: unknown) => {
        if (loading) {
          store.commit('unloading')
        }
        $log.error(err)
        reject(err)
      })
  })
}
