<template>
  <span>
    <template v-if="enable_b_tooltip">
      <b-badge
        :class="{ 'mb-1': mb }"
        :style="styling"
        :title="tooltip"
        @click.stop.prevent="emitClick"
        @mouseleave="hovering = false"
        @mouseover="hovering = true"
        class="no-print no-row-click badge"
        v-b-tooltip
        v-if="placement === 'top'"
        variant="none"
      >
        <i
          class="far fa-exclamation-triangle text-warning mr-1"
          :style="stylingIcon"
          v-if="warning_icon"
          data-testid="last-icon"
        ></i>
        <i
          :class="fa_type + ' fa-' + icon"
          :style="stylingIcon"
          v-if="icon"
          :data-testid="'icon-' + icon"
        ></i>
        <slot></slot>
      </b-badge>
      <b-badge
        :class="{ 'mb-1': mb }"
        :style="styling"
        :title="tooltip"
        @click.stop.prevent="emitClick"
        @mouseleave="hovering = false"
        @mouseover="hovering = true"
        class="no-print no-row-click badge"
        v-b-tooltip
        v-b-tooltip.hover.bottom
        v-if="placement === 'bottom'"
        variant="none"
      >
        <i
          :class="'fa-' + icon + ' ' + matomo"
          :style="stylingIcon"
          class="far"
          v-if="icon"
          :data-testid="'icon-' + icon"
        ></i>
        <slot></slot>
      </b-badge>
      <b-badge
        :class="{ 'mb-1': mb }"
        :style="styling"
        :title="tooltip"
        @click.stop.prevent="emitClick"
        @mouseleave="hovering = false"
        @mouseover="hovering = true"
        class="no-print no-row-click badge"
        v-b-tooltip
        v-b-tooltip.hover.left
        v-if="placement === 'left'"
        variant="none"
      >
        <i
          :class="'fa-' + icon + ' ' + matomo"
          :style="stylingIcon"
          class="far"
          v-if="icon"
          :data-testid="'icon-' + icon"
        ></i>
        <slot></slot>
      </b-badge>
      <b-badge
        :class="{ 'mb-1': mb }"
        :style="styling"
        :title="tooltip"
        @click.stop.prevent="emitClick"
        @mouseleave="hovering = false"
        @mouseover="hovering = true"
        class="no-print no-row-click badge"
        v-b-tooltip
        v-b-tooltip.hover.right
        v-if="placement === 'right'"
        variant="none"
      >
        <i
          :class="'fa-' + icon + ' ' + matomo"
          :style="stylingIcon"
          class="far"
          v-if="icon"
          :data-testid="'icon-' + icon"
        ></i>
        <slot></slot>
      </b-badge>
    </template>
    <template v-else>
      <b-badge
        :class="{ 'mb-1': mb }"
        :style="styling"
        :title="tooltip"
        @click.stop.prevent="emitClick"
        @mouseleave="hovering = false"
        @mouseover="hovering = true"
        class="no-print no-row-click badge"
        variant="none"
      >
        <i
          :class="'fa-' + icon + ' ' + matomo"
          :style="stylingIcon"
          class="far"
          v-if="icon"
          :data-testid="'icon-' + icon"
        ></i>
        <slot></slot>
      </b-badge>
    </template>
  </span>
</template>

<script>
export default {
  name: 'icon-action',
  props: {
    enable_b_tooltip: {
      type: Boolean,
      default: true,
    },
    is_table_action: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'top',
    },
    is_dnd: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary',
    },
    warning_icon: {
      type: Boolean,
      default: false,
    },
    matomo: {
      type: String,
      default: '',
      required: false,
    },
    mb: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: String,
    fa_type: {
      type: String,
      default: 'far',
    },
    width: Number,
    tooltip: String,
    icon_size: String,
    padding: String,
    height: Number,
    has_border: { type: Boolean, default: true },
  },
  computed: {
    styling: function () {
      return {
        color: !this.hovering
          ? this.color_dict[this.color_]
          : this.is_table_action
          ? this.color_dict[this.color_]
          : 'white',
        backgroundColor: this.hovering
          ? this.is_table_action
            ? this.color_dict[this.color_ + '_low']
            : this.color_dict[this.color_]
          : '',
        padding: this.padding,
        width: this.width ? this.width + 'px' : null,
        'font-weight': 400,
        cursor: !this.disabled ? 'pointer' : 'default',
        'border-width': this.is_dnd ? '0px' : '0.5px',
        'border-style': 'solid',
        'border-color': !this.hovering ? this.color_dict[this.color_ + '_low'] : this.color_dict[this.color_],
        height: this.height ? this.height + 'px' : null,
        'align-items': this.height ? 'center' : null,
        display: this.height ? 'flex' : null,
        border: this.has_border ? '1px solid' : 'none',
      }
    },
    stylingIcon: function () {
      return {
        'font-size': this.icon_size,
      }
    },
  },
  data() {
    return {
      hovering: false,
      textDecoration: 'underline',
      textWeight: 'bold',
      color_: '',
      color_dict: {
        danger: '#dc3545',
        danger_low: '#B71F41',
        primary: '#0A4650',
        primary_low: '#2C526663',
        secondary: '#35ABB0',
        secondary_low: '#35ABB066',
        warning: '#ffc107',
        warning_low: '#ffc10766',
        success: '#28a745',
        success_low: '#28a74566',
        disabled: '#aaaaaa',
        disabled_low: '#aaaaaa66',
        gray: '#656565',
        gray_low: '#65656500',
        light: 'white',
      },
    }
  },
  methods: {
    emitClick(ev) {
      if (!this.disabled) {
        this.$emit('click', ev)
      }
    },
  },
  mounted() {
    this.color_ = this.color
    if (this.disabled) {
      this.color_ = 'disabled'
    }
  },
  watch: {
    disabled: function (v) {
      if (v) {
        this.color_ = 'disabled'
      } else {
        this.color_ = this.color
      }
    },
    color: function (v) {
      this.color_ = v
    },
  },
}
</script>
