<template>
  <div style="height: 100vh">
    <keyboard-events v-on:keyup="keyboardEvent"></keyboard-events>
    <loading
      :active.sync="$store.getters.isLoading"
      :can-cancel="false"
      :height="32"
      :is-full-page="true"
      :width="32"
    ></loading>

    <!-- /.login-logo -->
    <b-row
      class="h-100"
      style="background: #f4f6f9"
    >
      <b-col
        md="12"
        lg="4"
        align-self="center"
      >
        <div class="login-div">
          <div class="login-logo mb-3">
            <img
              class="logo-login-img"
              :src="`${publicPath}static/img/Tenacy_couleur_borderless.svg`"
              alt="TENACY"
            />
          </div>

          <viewer
            v-if="policy_password"
            :value="policy_password"
          ></viewer>
          <form
            v-if="valid_token"
            data-vv-scope="form_reset_password"
            v-on:submit.prevent.stop="$emit('dummy')"
          >
            <form-field
              ref="password"
              v-model="password"
              v-validate="'required|password-validator|max:128'"
              :error="errors.first('form_reset_password.password')"
              :placeholder="_('New password')"
              name="password"
              no-check
              type="password"
            ></form-field>

            <form-field
              v-model="password_confirm"
              v-validate="'required|confirmed:password'"
              :error="errors.first('form_reset_password.password_confirm')"
              :placeholder="_('Confirm new password')"
              name="password_confirm"
              no-check
              type="password"
            ></form-field>

            <div class="row">
              <div class="col-12">
                <b-button
                  ref="sign_in"
                  class="w-100 login-button tenacy-btn ripple"
                  variant="primary"
                  @click.stop="setPassword"
                >
                  <translate>Set password</translate>
                </b-button>
              </div>
            </div>
          </form>
          <p
            v-if="!valid_token && processed_token"
            class="mb-2 text-center text-danger"
          >
            <translate>Invalid token</translate>
          </p>
          <b-row class="mt-3">
            <b-col class="text-center">
              <a
                href="#"
                @click="$router.push({ path: '/login' })"
              >
                <translate>Sign in</translate>
              </a>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <auth-col-right class="hide-col"></auth-col-right>
    </b-row>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import FormField from '../ui/form-field.vue'
import EditMixin from '../mixins/edit-mixin'
import UnauthPageMixin from '../mixins/unauth-page-mixin'
import KeyboardEvents from '../ui/keyboard-events.vue'
import AuthColRight from '../ui/auth-col-right.vue'
import { Viewer } from '@toast-ui/vue-editor'

export default {
  name: 'reset-password',
  data: function () {
    return {
      valid_token: false,
      processed_token: false,
      password: '',
      recaptca_enabled: false,
      password_confirm: '',
      policy_password: '',
      publicPath: import.meta.env.BASE_URL,
    }
  },
  mixins: [EditMixin, UnauthPageMixin],
  components: {
    KeyboardEvents,
    AuthColRight,
    FormField,
    Loading,
    Viewer,
  },
  methods: {
    setPassword: function () {
      const _this = this
      this.$validator
        .validateAll('form_reset_password')
        .then((res) => {
          if (res) {
            this.$store.commit('loading')
            const password = this.password
            const password_confirm = this.password_confirm
            this.$http
              .post(this.aciso_config.base_url + '/auth/password/reset', {
                token: this.$route.params.token,
                password: password,
                password_confirm: password_confirm,
              })
              .then((resp) => {
                if (resp.data.ok) {
                  this.$store.commit('unloading')
                  this.$router.push({ path: '/login' })
                  this.$toast.info(resp.data.message)
                } else {
                  throw new Error(resp.data.message)
                }
              })
              .catch((err) => {
                this.$store.commit('unloading')
                this.$log.debug(err)
                this.$toast.alert(err)
              })
          }
        })
        .catch((err) => {
          _this.$log.debug(err)
        })
    },
    keyboardEvent(e) {
      if (e.which === 13) {
        this.setPassword()
      }
    },
  },
  mounted() {
    const _this = this
    this.registerValidator('password-validator', 'user/check_password')
    this.$store.commit('loading')
    this.$http
      .post(this.aciso_config.base_url + '/auth/password/check_reset_token', {
        token: this.$route.params.token,
      })
      .then((resp) => {
        _this.processed_token = true
        if (resp.data.ok) {
          this.$http
            .get(this.aciso_config.base_url + '/user/password_policy?token=' + this.$route.params.token)
            .then((resp) => {
              if (resp.data.ok) {
                _this.policy_password = resp.data.object
              } else {
                throw new Error()
              }
            })
            .catch((err) => {
              _this.$log.debug(err)
            })
          _this.$store.commit('unloading')
          _this.valid_token = true
        } else {
          throw new Error(resp.data.message)
        }
      })
      .catch((err) => {
        _this.processed_token = true
        _this.$store.commit('unloading')
        _this.$toast.alert(err.message)
      })
  },
}
</script>
