import MainContent from '@/components/layout/main-content.vue'
import { _, isAuthorized, lazyLoadView, piloteAndAuditor, setNavL2 } from '@/router/routing'
import { risksRoutes } from '@/contexts/risks/Risks.routes'

export const strategyRoutes = {
  path: '/org',
  name: 'organization',
  component: MainContent,
  meta: {
    title: _('Strategy'),
    dummy: true,
    requires_auth: true,
    requires_tenant: true,
    requires_role: piloteAndAuditor,
    is_authorized: (route) => isAuthorized(route),
  },
  children: [
    {
      path: '/modeling',
      name: 'perimeters',
      component: () => lazyLoadView(import('@/components/pages/tags.vue')),
      icon: 'sitemap',
      meta: {
        title: _('Organization'),
        is_authorized: (route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'organization',
        feature_id: 'SECURITY_BASE',
      },
      children: [
        {
          path: 'filtered/list',
          name: 'filtered_perimeter',
          component: () => lazyLoadView(import('@/components/forms/filter-perimeters-list.vue')),
          icon: 'stream',
          meta: {
            title: _('Advanced Filter'),
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'filtered_perimeter',
          },
        },
      ],
    },
    {
      path: '/security_base',
      name: 'security_base',
      component: () => lazyLoadView(import('@/components/pages/security-base.vue')),
      icon: 'shield-check',
      meta: {
        title: _('Security bases'),
        is_authorized: (route) => isAuthorized(route),
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        doc_id: 'security_bases',
        feature_id: 'SECURITY_BASE',
      },
      children: [
        {
          path: 'diff/:id',
          name: 'measures_diff',
          component: () => lazyLoadView(import('@/components/pages/measures-diff.vue')),
          meta: {
            title: _('Measures differences'),
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            requires_role: piloteAndAuditor,
            doc_id: 'measures_diff',
          },
        },
      ],
    },
    {
      path: '/policies',
      name: 'policies_questionnaires',
      component: MainContent,
      icon: 'books',
      meta: {
        title: _('Policies'),
        alt_title: _('Policies and questionnaires'),
        multi: true,
        dummy: true,
        requires_auth: true,
        requires_tenant: true,
        requires_role: piloteAndAuditor,
        is_authorized: (route) => isAuthorized(route),
        feature_id: 'POLICIES',
      },
      children: [
        {
          path: 'policies',
          name: 'policies',
          component: () => lazyLoadView(import('@/components/pages/policies.vue')),
          beforeEnter: setNavL2,
          icon: 'books',
          meta: {
            title: _('Policies'),
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            hide_in_breadcrumb: true,
            requires_role: piloteAndAuditor,
            doc_id: 'policy',
            feature_id: 'POLICIES',
          },
          children: [
            {
              path: ':id',
              name: 'policy_version',
              component: () => lazyLoadView(import('@/components/pages/policy-version.vue')),
              props: (route) => ({ perimeter: route.query.p, target_score: route.query.ts }),
              meta: {
                title: null,
                requires_auth: true,
                requires_tenant: false,
                sidebar: false,
                is_authorized: (route) => isAuthorized(route),
                requires_role: piloteAndAuditor,
                doc_id: 'policy_details',
                feature_id: 'POLICIES',
              },
            },
          ],
        },
        {
          path: 'questionnaires',
          name: 'questionnaires',
          component: () => lazyLoadView(import('@/components/pages/questionnaires.vue')),
          beforeEnter: setNavL2,
          icon: 'file-alt',
          meta: {
            title: _('Questionnaires'),
            is_authorized: (route) => isAuthorized(route),
            requires_auth: true,
            requires_tenant: true,
            hide_in_breadcrumb: true,
            requires_role: piloteAndAuditor,
            doc_id: 'questionnaires',
            feature_id: 'CAMPAIGNS',
          },
          children: [
            {
              path: ':id/results',
              name: 'questionnaire-results',
              component: () => lazyLoadView(import('@/components/pages/questionnaire-results.vue')),
              meta: {
                title: null,
                is_authorized: (route) => isAuthorized(route),
                requires_auth: true,
                requires_tenant: false,
                requires_role: piloteAndAuditor,
                feature_id: 'CAMPAIGNS',
                doc_id: 'questionnaire-results',
              },
            },
            {
              path: ':id',
              name: 'questionnaire',
              component: () => lazyLoadView(import('@/components/pages/questionnaire.vue')),
              meta: {
                title: null,
                is_authorized: (route) => isAuthorized(route),
                requires_auth: true,
                requires_tenant: false,
                requires_role: piloteAndAuditor,
                doc_id: 'questionnaire',
              },
            },
          ],
        },
      ],
    },
    risksRoutes,
  ],
}
