<template>
  <b-form-group :class="{ 'mb-0': no_mb, 'mb-1': mb2 }">
    <label
      :class="{ 'mb-0': !disabled && error }"
      v-if="label"
      >{{ label }}</label
    >
    <b-badge
      :title="help"
      class="pull-right mt-1"
      v-b-tooltip
      v-if="help && help.length > 0"
      variant="default"
    >
      <i
        class="far fa-info-circle ml-1 fa-lg"
        style="color: #61a0ff"
      ></i>
    </b-badge>
    <div class="error-container">
      <span class="help-label">{{ help_label }}</span>
    </div>
    <div
      class="error-container"
      v-if="!disabled && error"
    >
      <span class="invalid-feedback">{{ error }}</span>
    </div>
    <div
      :class="{ 'mb-3': !no_mb, 'mb-0': no_mb }"
      class="input-group date"
    >
      <div class="input-group-prepend cal_open">
        <span
          class="input-group-text"
          v-b-tooltip
          :title="help"
          ><i class="far fa-calendar"></i
        ></span>
      </div>
      <date-picker
        :class="{
          'is-invalid': !!error && touched && !noCheck,
          'is-valid': !error && !disabled && touched && !noCheck,
        }"
        :config="_options"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="changed"
        class="form-control"
        ref="dp"
        v-model="value_"
      ></date-picker>
      <div
        @click="$emit('now')"
        class="input-group-append link"
        v-if="show_now"
      >
        <span class="input-group-text">{{ now_str }}</span>
      </div>
    </div>
  </b-form-group>
</template>

<script>
// import DatePicker from 'vue-bootstrap-datetimepicker'

import DatePicker from '@/components/ui/date-picker.vue'

const props = {
  name: String,
  /**
   * Label displayed above the input
   */
  label: String,
  /**
   * Text displayed inside the input (as a placeholder)
   */
  placeholder: String,
  /**
   * To have no margin-bottom
   */
  no_mb: Boolean,
  /**
   * To add margin-bottom
   */
  mb2: Boolean,
  'no-check': {
    type: Boolean,
    default: false,
  },
  /**
   * Minimum date picked in the calendar
   */
  minDate: {
    type: [String, Object],
  },
  /**
   * Maximum date picked in the calendar
   */
  maxDate: {
    type: [String, Object],
  },
  /**
   * To disable the input with a certain condition
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * To add an icon above the input with a helper displayed when hovered
   */
  help_label: {
    type: String,
    default: '',
  },
  help: {
    type: String,
    default: '',
  },
  error: {
    type: String,
    required: false,
  },
  /**
   * Value of the input
   */
  format_en: {
    type: String,
    default: 'MM/DD/YYYY',
  },
  format_fr: {
    type: String,
    default: 'DD/MM/YYYY',
  },
  value: String | Object,
  /**
   * To show a button Now in the calendar (set the value of the input at date now)
   */
  now: Boolean,
}
export default {
  name: 'form-field-date',
  props: props,
  components: { DatePicker },
  data: function () {
    return {
      touched: false,
      value_: null,
    }
  },
  computed: {
    _options: function () {
      if (this.options) {
        return this.options
      } else {
        return {
          format: this.$store.getters.lang === 'fr' ? this.format_fr : this.format_en,
          useCurrent: true,
          viewMode: 'months',
          locale: this.$store.getters.lang === 'fr' ? 'fr' : 'en-gb',
          maxDate: this.maxDate,
          minDate: this.minDate,
        }
      }
    },
    show_now: function () {
      if (this.now) {
        if (this.value !== this.$moment().format(this.format)) {
          return true
        }
      }
      return false
    },
    now_str() {
      if (this.$store.getters.lang === 'fr') {
        return 'Auj.'
      } else {
        return 'Now'
      }
    },
  },
  methods: {
    changed: function (value) {
      let toLocalValue = value

      if (this.value) {
        this.touched = true
      }

      if (this.$store.getters.lang === 'en' && value) {
        toLocalValue = this.$moment(value, this.format_en).format(this.format_fr)
      }
      this.$emit('input', toLocalValue)
      if (value !== this.value) {
        this.$emit('change', toLocalValue)
      }
    },
    /**
     * Function that reset the calendar displayed when date is picked to month view
     */
    resetView() {
      this.$log.debug('RESET DATE...')
      this.$refs.dp.dp.viewMode('months')
    },
  },
  mounted() {
    const _this = this
    if (this.$store.getters.lang === 'en' && this.value) {
      this.value_ = this.$moment(this.value, this.format_fr).format(this.format_en)
    } else if (this.$store.getters.lang === 'fr' && this.value) {
      this.value_ = this.$moment(this.value, this.format_fr).format(this.format_fr)
    } else {
      this.value_ = this.value
    }
    this.$root.$on('data:submit', () => {
      _this.touched = false
    })
  },
  watch: {
    value: function (val) {
      if (typeof val != 'string' && typeof val != 'undefined' && val != null) {
        return
      }
      if (this.$store.getters.lang === 'en' && val) {
        this.value_ = this.$moment(val, this.format_fr).format(this.format_en)
      } else if (this.$store.getters.lang === 'fr' && val) {
        this.value_ = this.$moment(val, this.format_fr).format(this.format_fr)
      } else {
        this.value_ = val
      }
      this.resetView()
    },
  },
}
</script>

<style scoped>
.help-label {
  min-height: 15px;
  height: 15px;
  margin-top: 0 !important;
  font-size: 11px;
}
</style>
